import React from 'react';

const Modal = ({ message, onClose, goBack }) => {

  return (
    <div className="tour-modal">
      <div className="tour-modal-content">
        <p>{message}</p>
        <div 
        style=
          {{
            gap: "5px", 
            width: "fit-content",
          }} className='d-flex mx-auto'>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React, {Component} from 'react';

export class Pagination extends Component {
  handlePagination = (direction) => {
    try {
      let {page = 1} = this.props.pageMeta || {};
      let pageNumber = eval(`${page} ${direction} ${1}`);

      this.props.handleChange({page: pageNumber});
    } catch (err) {
      // console.log(err);
    }
  };

  render() {
    let {pageCount = 10, page = 1} = this.props.pageMeta || {};
    if (pageCount <= 1) {
      return '';
    }

    return (
      <div className="d-flex text-center w-100 justify-content-end pr-4 py-3">
        <p className="m-0 d-flex align-items-center">
          <button
            className="btn-effect table-left-arrow cursor-pointer"
            disabled={page === 1}
            onClick={(e) => page !== 1 && this.handlePagination('-')}>
            <span className="icon-page-left pr-3" />
            Previous
          </button>

          <span>
            {page} of {pageCount}
          </span>

          <button
            className="btn-effect table-right-arrow cursor-pointer"
            disabled={page === pageCount}
            onClick={(e) => page !== pageCount && this.handlePagination('+')}>
            Next
            <span className="pl-3 icon-page-right" />
          </button>
        </p>
      </div>
    );
  }
}

import React, { useEffect } from 'react'
import classes from './interactive_map.module.scss'

export default function WarningModal({ data, setData }) {
    const { show, msg } = data;

    useEffect(() => {
        if (show)
            setTimeout(() => {
                setData(prev => ({ ...prev, show: false }));
            }, 2000);
    },[show])

    return (
        <div className={`${classes.warning_modal} ${show ? classes.show_warning_modal : ''}`}>
            {msg}
        </div>
    )
}

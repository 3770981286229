import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import CloseButton from 'assets/svg/closeButton.svg'
import WarningIcon from 'assets/svg/new-warning-icon.svg'

function LateClockInAlert({ toggleOverlay, handleStateChange, disabled = false, }) {

 
    return (
        <>
            <div className="late-clockIn-overlay">
                <div className="late-clockIn-overlay-content">
                    <div className='d-flex justify-content-end mb-2'>
                        <span onClick={toggleOverlay} className="modal-close-btn"><img src={CloseButton} alt='close-button'></img></span>
                    </div>
                    <div className='dialogbox-container'>
                    <div className="plan-paused-header">
                        <img src={WarningIcon} alt='warning'/>
                    </div>
                    <div className='late-clockIn-content'>
                        <p>
                        Clock-in is not allowed any more for this shift as the grace hours exceeded. Report to your agency in case of any change in the shift timings.
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default LateClockInAlert;
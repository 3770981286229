// ID'S
export const TIMESHEET_ID = '602e90fd980439498adf8cb3';
export const ACCEPTED = 'ACCEPTED';
export const NEW_SHIFT_AVAILABLE = 'NEW SHIFT AVAILABLE';
export const YET_TO_START = 'YET TO START';
export const NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM = 'NEW SHIFT ASSIGNED. ACCEPT TO CONFIRM';
export const HOLD_WORK = 'WORK ON HOLD';
export const SHOWN_INTERESTED = 'SHOWN INTEREST';
export const IM_INTERESTED = "SCHEDULE ME!";
export const PENDING_AGENCY_APPROVAL = 'PENDING AGENCY APPROVAL';
export const PENDING_CLIENT_APPROVAL = 'PENDING CLIENT APPROVAL';
export const SHIFT_CONFIRMED_CLOCK_IN = 'SHIFT CONFIRMED. CLOCK-IN';
export const SHIFT_IN_PROGRESS = 'SHIFT IN PROGRESS';
export const PENDING_TIMESHEET_REVIEW = 'PENDING TIMESHEET REVIEW';
export const CLIENT_REVIEW_COMPLETED = 'CLIENT REVIEW COMPLETED';
export const PENDING_STAFF_COMPLETE = 'PENDING TIMESHEET SUBMISSION';
export const AGENCY_REVIEW_COMPLETED = 'AGENCY REVIEW COMPLETED';
export const TIMESHEET_APPROVED_FOR_PAYROLL = 'TIMESHEET APPROVED FOR PAYROLL';
export const REJECTED_BY_STAFF = 'REJECTED BY STAFF';
export const REJECTED_BY_CLIENT = 'REJECTED BY CLIENT';
export const REJECTED_BY_AGENCY = 'REJECTED BY AGENCY';
export const CANCELLED_WORK = 'CANCELED WORK';
export const NOT_FILLED = 'NOT FILLED'

export const DRAFT = 'DRAFT';
export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';
export const APPROVED = 'APPROVED';
export const ALL = 'ALL';

import React, { useState } from 'react';
import { NormalButton, NormalCheckbox, NormalModal } from 'component/common';
import "./style.scss"

export function ConfirmTimeSheetModal({handleAction,isOpen,toggle}) {
    
   const [loadingKey, setLoadingKey] = useState(false)
   const [checked, setChecked] = useState(false)

    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            headerClass="border-0 pt-4 px-4"
            toggle={toggle}
        >
             <>
                  <div className="alert-msg text-center mb-5 px-5">
                     <div className='mb-3'>
                        <p className="mb-0">
                        Your timesheet details of clock-in, clock-out and breaks are required to be accurate. Please inform your manager in case of any discrepancies.
                        </p>
                     </div>
                     <div className='d-flex justify-content-center'>
                        <NormalCheckbox 
                           id= "acknowledgment"
                           onChange={() => {
                              setChecked(!checked)
                           }}
                        />
                        <p className='ml-2'>I read and acknowledge the above</p>
                     </div>
                  </div>
                  <div className="d-flex justify-content-center mb-4">
                     <NormalButton
                        onClick={()=>{
                            handleAction(false)
                        }}
                        id="no"
                        label="No"
                        outline={false}
                        className="mb-2 btn fs-16 btn btn-dark-primary-custom  btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                           setLoadingKey(true)
                            handleAction(true)
                            setTimeout(() => {
                              setLoadingKey(false)
                            })
                        }}
                        id="confirm"
                        label="Confirm"
                        outline={true}
                        loader={loadingKey}
                        loaderColor="white"
                        disabled= {checked ? false : true}
                        className={`mb-2 ml-2 btn fs-16 btn-dark-outline  btn-block ${!checked? "confirm-btn" : "" }`}
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}
import React from 'react';
import Dropzone from 'react-dropzone';
import {Toast} from 'service/toast';
import './style.scss';
import AddPlus from 'assets/svg/plus 1.svg';

export const DragFileUpload = ({
  label = 'Upload',
  children,
  className,
  disabled = false,
  handleFileUpload = '',
  acceptFileFormat = ['png', 'jpeg', 'jfif', 'svg', 'jpg', 'gif', 'webp', 'heif', 'pdf', 'docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls', 'csv', 'bmp', 'txt', 'odt', 'ods', 'odp', 'rtf', 'tiff', 'tif', 'zip', 'zipx', 'rar', 'tar', 'tgz', '7z', 'numbers', 'pages'],
  type = 'image',
  multiButton = false,
  hasImage,
}) => {
  return (
    <Dropzone
      disabled={disabled}
      onDrop={(files, a) => {
        if (files.length) {
          if (files[0].size < 100 * 1024 * 1024) {
            if (!checkFileValidation(files, acceptFileFormat)) {
              Toast({type: 'error', message: `Please upload ${acceptFileFormat.join('/')} File`});

              return '';
            }

            handleFileUpload(files);

            uploadFile(files).then((res) => {
              if (handleFileUpload) {
                handleFileUpload(res.data);
              }
            });
          } else {
            Toast({type: 'error', message: `Please upload ${acceptFileFormat.join('/')} within 100mb`});
          }
        } else {
          Toast({type: 'error', message: `Please upload ${type} within 10mb`});
        }
      }}>
      {({getRootProps, getInputProps}) => (
        <React.Fragment>
          {multiButton ? (
            <div className={`${className} position-relative `}>
              {children}

              {multiButton ? <input {...getInputProps()} /> : <input />}

              {multiButton ? (
                <div className={`overlay-button d-flex position-absolute ${hasImage ? 'hasImage' : ''}`}>
                  <div
                    {...getRootProps()}
                    className="uploader-content d-flex align-items-center justify-content-center mx-2">
                    <img src={AddPlus} alt="AddPlus" />
                    <span>Upload</span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <React.Fragment>
              {label !== '' ? <div className="fs-12 text-black font-Gilroy pb-1 mb-1 line-height-20">{label}</div> : ''}
              <div {...getRootProps()} className={`${className} position-relative `}>
                {children}

                <input {...getInputProps()} />
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Dropzone>
  );
};

const uploadFile = ([file]) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();

    formData.append('key1', file);
  });
};

const checkFileValidation = (files, validationType) => {
  return files.every((file) => {
    let temp = file.name.split('.');

    let type = temp[temp.length - 1].toLowerCase();

    return validationType.indexOf(type) !== -1;
  });
};

import React, {memo, useEffect, useState} from 'react'
import './style.scss'
import Modal from './Modal/Modal';
import ReactJoyride , { ACTIONS, LIFECYCLE, } from 'react-joyride';
import { settingsPageSteps} from './tourGuideSteps/settingsPageSteps';
// import { useHistory } from 'react-router-dom';
// import CustomComponent from './CustomComponent';
// import { store } from 'service/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { workScheduleListSteps, workScheduleCalendarSteps } from './tourGuideSteps/workScheduleSteps';
import { staffProfileSteps, editStaffProfileSteps } from './tourGuideSteps/staffProfileSteps';
import { staffProfiletimeOffSteps } from './tourGuideSteps/staffProfiletimeoffSteps';
import { reportsPageSteps } from './tourGuideSteps/reportsPageSteps';
import { startTourGuide } from 'reducer/tourGuide';

const TourGuide = (props) => {

let dispatch = useDispatch()


  const [tour, setTour] = useState({
    run:false,
    steps:[],
    stepIndex:0,
  })

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("")
  let tourGuideData = useSelector((state) => state.tourGuideReducer.tourGuideData);
  
  let pathName = window.location.pathname

  let productTour = localStorage.getItem("productTour");
  let modifiedlistSteps ;
  let modifiedCalendarSteps;

  useEffect(()=>{
    if(productTour == "false"){
      modifiedlistSteps = workScheduleListSteps.slice(1);
      modifiedCalendarSteps = [...workScheduleListSteps.slice(1,8),...workScheduleCalendarSteps]
    }
  },[tourGuideData,pathName])

  // Checking if the click event's target is not a part of the Joyride tooltip and turning the tour guide off if it is not
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tour.run) {
        const tooltipElement = document.querySelector('.react-joyride__tooltip');
        if (tooltipElement && !tooltipElement.contains(event.target)) {
          dispatch(startTourGuide({ run: false, stepIndex: 0 }));
        }
      }
    };
  
    // click event listener for the document body
    document.body.addEventListener('click', handleOutsideClick, {passive: true});
  
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [tour.run, dispatch]);

 const getTourSteps = () => {
   if(pathName === '/admin/settings'){
     return  settingsPageSteps 
   }
   if (pathName === '/admin/workSchedule/list'){
    if(productTour=="true"){
    return workScheduleListSteps}
    else if(productTour=="false"){
      return modifiedlistSteps
    }
  }
  if(pathName === '/admin/workSchedule/calendar'){
    if(productTour=="true"){
    return [ ...workScheduleListSteps.slice(0,8), ...workScheduleCalendarSteps]}
    else if(productTour=="false"){
      return modifiedCalendarSteps
    }
  }
 
  if(pathName === '/admin/staffProfile' && window.location.hash.includes('timeoff')){
    return staffProfiletimeOffSteps
  }
  if(pathName === '/admin/report'){
    return reportsPageSteps
  }
  if(pathName === '/admin/staffProfile'){
    return staffProfileSteps
  }
  if(pathName === '/admin/editProfile'){
    return editStaffProfileSteps
  }
 }

useEffect(()=>{
  setTour({
            ...tour,
            run:tourGuideData.run,
            steps: getTourSteps(),
            stepIndex:tourGuideData?.stepIndex ? tourGuideData?.stepIndex : 0
           })
},[
  tourGuideData,
   pathName
  ])


  
  const handleJoyrideCallback = (data) => {
    const { index, action, lifecycle, type} = data;
    if(!data.step){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      return;
    } 

    if (action === ACTIONS.SKIP) {
      dispatch(startTourGuide({run:false, stepIndex:index}))
      return;
    }
    if (action === ACTIONS.PREV && lifecycle ===  LIFECYCLE.COMPLETE) {
      setTour((prevTour)=>{
        return{
          ...prevTour,
          stepIndex: prevTour.stepIndex -1
        }
      })
      return;
    }

    if (action === ACTIONS.NEXT && lifecycle ===  LIFECYCLE.COMPLETE) {
      setTour((prevTour)=>{
        return{
          ...prevTour,
          stepIndex: prevTour.stepIndex +1
        }
      })
      return;
    }
    if (type === 'tour:end') {
      dispatch(startTourGuide({run:false, stepIndex:0}))
      if(pathName === "/admin/workSchedule/list"){
        localStorage.setItem('workScheduleTour', 'true')
        return;
      }
      if(pathName === "/admin/staffProfile"){
        localStorage.setItem('staffProfileTour', 'true')
        return;
      }
      if(pathName === "/admin/report"){
        localStorage.setItem('reportsTour', 'true')
        return;
      }
      if(pathName === "/admin/settings"){
        localStorage.setItem('settingsTour', 'true')
        return;
      }
      if(pathName === "/admin/editProfile"){
        localStorage.setItem('staffEditProfileTour', 'true')
        return;
      }

      return;
    }
    if(type === 'error:target_not_found' && (pathName === "/admin/workSchedule/list"  || pathName === "/admin/workSchedule/calendar")){
      dispatch(startTourGuide({run:false, stepIndex:0}))
      setShowModal(true);
      setModalMessage("Oops! No steps ahead as there are no work requests yet!")
    }   
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    handleCloseModal()
  }, [pathName])

  return (
    <>
    {showModal && (
      <Modal
        message={modalMessage}
        onClose={handleCloseModal}
      />
    )}
   <ReactJoyride
   run={tour.run}
   steps={tour.steps}
   stepIndex={tour.stepIndex}
   callback={handleJoyrideCallback}
   scrollToFirstStep
   scrollOffset={100}
   scrollDuration={80}
   spotlightPadding={0}
   disableScrolling={true}
   continuous
   hideCloseButton
   disableOverlayClose
   showProgress
   showSkipButton
  //  tooltipComponent={tour?.steps?.length==13 && tour?.stepIndex==0 ? CustomComponent:""}
   styles={{
    options: {
      arrowColor: 'white',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: "#f682bd",
      textColor: 'black',
      width: 'max-content',
      zIndex: 1000,
    },
    buttonBack: {
      color: "#000",
    },
    buttonNext: {
      color: "#fff",
      backgroundColor: "#f682bd"
    },
    buttonSkip: {
      color: "#000",
    },
  }}
   />
   </>
  )
}

export default memo(TourGuide)

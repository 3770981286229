import React, { useEffect, useState } from 'react'
import './style.scss'
import loadingIcon from "assets/images/loading.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFileFromMDB } from 'action/fileHandler';
import nav_user from '../../../../assets/svg/user.svg';
import noImageIcon from "assets/svg/no-image-icon.svg"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DisplayStaffImage = ({getFileFromMDB, entity, clientId,  imageId, rounded, navbar}) => {
  const [onlineImage, setOnlineImage] = useState('')
  const [loading, setLoading] = useState(false)

    const handleImageDownload = async (logo) => {
      setLoading(true)
        let imageData;
         let fileId = logo.split('/')[1]
         await getFileFromMDB({entity:entity ? entity : '', clientId: clientId ? clientId : ''},fileId)
         .then((response)=>{
          imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
          setLoading(false)
          setOnlineImage(imageData.toString())
          return imageData
         }).catch((error) => {
            setLoading(false);
            setOnlineImage(null);
            console.log('Error fetching image:', error);
          });
      }

      useEffect(()=>{
        if(imageId){
          handleImageDownload(imageId)
        }else{
          setOnlineImage(null)
        }
      },[imageId])

      return (<>
        {onlineImage !== null ? (
          <img
            className={`${
              navbar && 'profile-nav rounded-circle mb-3'
            } ${rounded && 'profile-icon rounded-circle'} ${
              loading ? 'loading-animation' : 'image-view'
            }`}
            src={loading ? loadingIcon : onlineImage}
            alt="User Profile"
          />
        ) : !navbar ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Image not found</Tooltip>}
            >
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <img src={noImageIcon} alt="Dummy Profile" />
              </div>
            </OverlayTrigger>
          </div>
        ) : <img 
          style={{
            width: "25px",
            height: "25px",
            marginBottom: "15px"
          }} src={nav_user}/> }
    </>)
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getFileFromMDB
      },
      dispatch
    );
  };

  export default connect('', mapDispatchToProps)(DisplayStaffImage)


import React from 'react';
import './style.scss';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

export class NormalDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownId: Math.floor(100000 + Math.random() * 900000),
      isOpen: false,
    };
  }
  handleDropdown = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  render() {
    const {
      className = '',
      label = '',
      labelIcon = '',
      onClick,
      dropDownClass,
      disabled = false,
      alinement = '',
      caret = true,
      outline = false,
      color = '',
      type = '',
      maxString = 25,
      optionsList = [
        {icon: 'edit', label: 'Edit', iconClass: 'material-icons'},
        {icon: 'content_copy', label: 'Copy', iconClass: 'material-icons'},
        {icon: 'delete', label: 'Delete', iconClass: 'material-icons'},
      ],
    } = this.props;
    let {isOpen} = this.state;

    return (
      <ButtonDropdown disabled={disabled} isOpen={isOpen} toggle={this.handleDropdown} className={'dropdown'}>
        <DropdownToggle disabled={disabled} outline={outline} color={color} caret={caret} className={`dropdown ${className}`}>
          {label ? label : <i className={labelIcon} />}{' '}
          <i className="fas fa-chevron-down ml-2"></i>
          {className.includes('custom-dropdown') && <span className="icon-down down-icon" />}
        </DropdownToggle>

        <DropdownMenu
          style={{transform: alinement === 'left' ? 'translate3d(-93px, 0px, 0px)' : 'translate3d(0px, 0px, 0px)'}}>
          {optionsList.map((data, index) => (
            <DropdownItem
              key={index}
              className={dropDownClass ? dropDownClass : ''}
              onClick={(e) => {
                let body = {};
                body = {
                  target: {
                    value: data.value,
                    data: data,
                  },
                };
                onClick(body);
              }}>
              {data.boxColorClass && <span className={"custom-color-box "+data.boxColorClass}></span>}{(!data.boxColorClass && data.icon) && <img className="mx-2" src={data.icon} alt="dataIcon"/>}{' '}
              <span>
                {type === 'location' && data.label.length > maxString ? (
                  data.label.substring(0, maxString) + '...'
                ) : (
                  data.label
                )}
              </span>
              {data.checkbox ? <input type="checkbox" className="float-right" /> : null}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

import React from 'react';
import { NormalButton, NormalModal } from 'component/common';
import "./style.scss"

export default function ConfirmModel({handleAction,isOpen,toggle, loadingKey = '', loadingCheckKey = '', message = '',type}) {
    return (
      <>
         <NormalModal
            isOpen={isOpen}
            size="md"
            renderHeader={''}
            headerClass="border-0 pt-4 px-4 confirm-header"
            toggle={toggle}
        >
             <>
                  <div className=" text-center mb-5 px-5">
                     {/* <p className="fs-20 mb-0">
                        Your work is <b>In-progress. </b>
                     </p> */}
                     <p className="h3-m mb-0">
                        {message ? message : 
                        'Are you sure you want to cancel it?'}
                     </p>
                  </div>
                  <div className="d-flex justify-content-center mb-4 ">
                     <NormalButton
                        onClick={()=>{
                            type?handleAction({type:type,value:false}):handleAction(false)
                        }}
                        id="cancel"
                        label="Cancel"
                        outline={false}
                        disabled ={loadingKey === loadingCheckKey}
                        // loader={isFormLoder}
                        className="mb-2 btn fs-13 btn cancel-btn cta-2-m btn-block"
                     />
                     <NormalButton
                        onClick={()=>{
                           type?handleAction({type:type,value:true}):
                            handleAction(true)
                        }}
                        id="confirm"
                        label="Confirm"
                        loader={loadingKey === loadingCheckKey}
                        disabled={loadingKey === loadingCheckKey} 
                        loaderColor='grey'
                        outline={false}
                        className=" ml-2 btn fs-13  cta-2-m  btn-block "
                     />
                  </div>
                     
               </>        
        </NormalModal> 
      </>
    )
}



import React, { useState } from 'react';

export const  DistanceMatrix = (data)=>{

    const [distanceTravelled,setDistanceTravelled] = useState(null)
    const [distanceUnit] = useState(data.distanceUnit);

    const getDistance = async() => {
    const origin1 = { lat: data.origin, lng: data.origin2 };
    const destinationB = { lat: data.destination, lng: data.destination2 };
    var service = new window.google.maps.DistanceMatrixService();
    let request ;
    
    if(distanceUnit===1){
        request = {
            origins: [origin1],
            destinations: [destinationB],
            travelMode: 'DRIVING',
            unitSystem: window.google.maps.UnitSystem.IMPERIAL,
            avoidHighways: false,
            avoidTolls: false
    }
   }
    else if(distanceUnit===2){
          request = {
            origins: [origin1],
            destinations: [destinationB],
            travelMode: 'DRIVING',
            unitSystem: window.google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false
         }
    }
  
    await service.getDistanceMatrix(request).then((response => {
    let setdistance = response
    setDistanceTravelled(setdistance);
    }))

}
 
   if(data && distanceTravelled == null){
    getDistance();
   }

  
    return (
        <>
        {/* { distanceUnit === undefined || distanceUnit === null || distanceUnit === 0 ? "Please Select Distance Unit":
        <p className='mile'>{distanceTravelled!==null && distanceTravelled?.rows[0]?.elements[0]?.status==="OK"? distanceTravelled?.rows[0]?.elements[0]?.distance?.text.split(" ")[0] +" " +` ${distanceUnit===1?"Miles":"KM"} Away from client`:"Road distance is not measurable"}</p>
         } */}
         {/* Fixed String concatenation warning */}
         { distanceUnit === undefined || distanceUnit === null || distanceUnit === 0 ? "Please Select Distance Unit":
        <p className='mile'>{distanceTravelled!==null && distanceTravelled?.rows[0]?.elements[0]?.status==="OK"? `${distanceTravelled?.rows[0]?.elements[0]?.distance?.text.split(" ")[0]} ${distanceUnit===1?"Miles":"KM"} Away from client`:"Road distance is not measurable"}</p>
         }
        </>
    )
}
import {
  USER_DATA,
  AGENCY_DATA,
  GET_TASK_DATA,
  UPDATE_TASK_DATA,
  UPDATE_TASKS_DATA,
  ADD_TASKS_DATA,
  ADD_WORKS_DATA,
  UPDATE_WORKS_DATA,
} from 'service/actionType';

const initialState = {
  data: '',
  task: {},
  works: [],
  workList: [],
  userData:[],
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case USER_DATA:
      return{
        ...state,
        userData:payload
      }
    case GET_TASK_DATA:
      return {
        ...state,
      };
    case UPDATE_TASK_DATA:
      return {
        ...state,
        task: payload,
      };
    case UPDATE_TASKS_DATA:
      return {
        ...state,
        works: [],
      };
    case UPDATE_WORKS_DATA:
      return {
        ...state,
        workList: [],
      };
    case ADD_TASKS_DATA: {
      let works = [...state.works];
      if (!JSON.stringify(works).includes(JSON.stringify(payload))) {
        works.push(payload);
      }
      return {
        ...state,
        works,
      };
    }
    case ADD_WORKS_DATA: {
      let workList = [...state.workList];
      if (!JSON.stringify(workList).includes(JSON.stringify(payload))) {
        workList.push(payload);
      }
      return {
        ...state,
        workList,
      };
    }
    case AGENCY_DATA:{
      return{
        ...state,
        agencyData : payload,
      }
    }
    default:
      return state;
  }
};

import React, {useState, useEffect, useRef} from "react";
import {
  NormalButton,
  NormalModal,
  NormalInput,
  NormalSelect,
  NormalDate,
  NormalTextarea,
  NormalRadio,
  CommonPageLoader,
} from "component/common";
import "./style.scss";
import calendarIcon from "assets/svg/calendar-icon.svg";
// Redux
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
  addTimesheet,
  getTimesheet,
  updateTimesheet,
  editTimesheet,
  editTimesheetBreak,
  updateTaskData,
} from "../../../action/TimesheetAct";
import moment from "moment";

import SimpleReactValidator from "simple-react-validator";
import {getStaffById} from "action/StaffAct";
import {useCustomReducer} from "service/helpers/hooks";

function ManualTimeSheet({
  action,
  view,
  setVisible,
  setVisibleEdit,
  editTimesheet,
  editTimesheetBreak,
  getStaffById,
  taskData,
  updateTaskData,
  handleReload,
}) {
  const [initState] = useState({
    timeSheetType: "true",
    time: {
      checkIn: new Date(),
      checkOut: new Date(),
      hours: "7:30",
    },
    workRequest: "",
    jobAssigned: "yes",
    notes: "",
    timeZone: "",
    name: "",
    staffId: "",
    status: 1,
    id: "602e90fd980439498adf8cb3",
    agencyId: "",
    breakType: "",
  });
  // profile data.
  const [reducerState, dispatch, actionType] = useCustomReducer(initState);
  const [loading, setLoading] = useState(false);
  const [submitloading, setSubmitLoading] = useState(false);
  const [updateloading, setUpdateLoading] = useState(false);
  const [, setError] = useState("");
  const [pauseTimes, setPauseTimes] = useState([]);
  const [, forceUpdate] = useState(false);
  const [, setUserDetails] = useState({});
  const [clockIn, setClockIn] = useState("");
  const [clockOut, setClockOut] = useState("");
  const [defaultPauseTimes, setDefaultPauseTimes] = useState([
    {
      type: "pause",
      time: "",
    },
    {
      type: "resume",
      time: "",
    },
  ]);
  const times = [{value: "AM", label: "AM"}, {value: "PM", label: "PM"}];

  const handleInput = ({target: {name, value}}) => {
    if (name === "clockIn") {
      setClockIn(value);
    } else if (name === "clockOut") {
      setClockOut(value);
    }
  };
  const handleInput2 = (event, index) => {
    let pauseResumeDatas = [];
    if (
      taskData?.workStaffAssignment?.staffWorkData?.length > 0 &&
      taskData.workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      ).length > 0 &&
      taskData.workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      )[0].pauseResumeData !== undefined
    ) {
      pauseResumeDatas = [
        ...(
          taskData?.workStaffAssignment?.staffWorkData?.length > 0 &&
          taskData.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          ).length > 0 &&
          taskData.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          )[0].pauseResumeData),
      ];
    } else {
      pauseResumeDatas = [...defaultPauseTimes];
    }
    pauseResumeDatas[index].time = moment(new Date(event.target.value)).format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
    if (
      taskData?.workStaffAssignment?.staffWorkData?.length > 0 &&
      taskData.workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      ).length > 0 &&
      taskData.workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      )[0].pauseResumeData !== undefined
    ) {
      setPauseTimes(pauseResumeDatas);
    } else {
      setDefaultPauseTimes(pauseResumeDatas);
    }
  };
  const handleInputs = ({target: {name, value}}) => {
    dispatch({type: actionType.updateStateWithKey, payload: {name, value}});
  };

  useEffect(
    () => {
      if (action !== "Add") {
        fetchUserData(action);
        fetchTimesheet(action);
      }
    },
    [action],
  );

  const fetchUserData = async (action) => {
    setLoading(true);
    let id = localStorage.getItem("userData") !== null ? JSON.parse(localStorage.getItem("userData")).id : null;

    const query = {
      id,
    };

    await getStaffById(query)
      .then((data) => {
        setUserDetails(data);
      })
      .catch((e) => console.log(e));
    setLoading(false);
  };
  const fetchTimesheet = async (action) => {
    setLoading(true);
  };

  // Update profile
  const submitProfile = async (e) => {
    let id = localStorage.getItem("userData") !== null ? JSON.parse(localStorage.getItem("userData")).id : null;
    e.preventDefault();
    setSubmitLoading(true);
    let validate = validator.current.allValid();
    let ErrorFound = 0;
    if (validate) {
      let requestBody = {
        staffId: id,
        workId: taskData._id,
        clockInTime:
          clockIn !== "" &&
          clockIn !==
            taskData.workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            ).length >
              0 &&
          taskData.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          )[0].clockIn
            ? moment(new Date(clockIn)).format("YYYY-MM-DD[T]HH:mm:ss.SSSS")
            : taskData.workStaffAssignment.staffWorkData.filter(
                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
              ).length > 0 &&
              taskData.workStaffAssignment.staffWorkData.filter(
                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
              )[0].clockIn,
        clockOutTime:
          clockOut !== "" &&
          clockOut !==
            taskData.workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            ).length >
              0 &&
          taskData.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          )[0].clockOut
            ? moment(new Date(clockOut)).format("YYYY-MM-DD[T]HH:mm:ss.SSSS")
            : taskData.workStaffAssignment.staffWorkData.filter(
                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
              ).length > 0 &&
              taskData.workStaffAssignment.staffWorkData.filter(
                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
              )[0].clockOut,
      };
      let updatedTask = {
        ...taskData,
        staffWorkData: [
          ...taskData.workStaffAssignment.staffWorkData,
          {clockIn: requestBody.clockInTime, clockOut: requestBody.clockOutTime},
        ],
      };
      await updateTaskData(updatedTask);
      await editTimesheet(requestBody)
        .then((data) => {
          if (
            !workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            )[0].pauseResumeData
          ) {
            setSubmitLoading(false);
            if (
              workStaffAssignment !== undefined &&
              !workStaffAssignment.staffWorkData.filter(
                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
              )[0].pauseResumeData
            ) {
              setVisible(false);
              setVisibleEdit(true);
              handleReload(taskData._id);
              setError("");
              ErrorFound = 0;
            }
          }
        })
        .catch((err) => {
          ErrorFound = 1;
          setError("error");
          console.log("error", err);
          setSubmitLoading(false);
        });
      if (
        workStaffAssignment?.staffWorkData?.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        )[0].pauseResumeData &&
        workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        )[0].pauseResumeData.length > 0
      ) {
        breakSubmitProfile(ErrorFound);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(true);
      setSubmitLoading(false);
    }
  };
  const breakSubmitProfile = async (ErrorFound) => {
    setUpdateLoading(true);
    let id = localStorage.getItem("userData") !== null ? JSON.parse(localStorage.getItem("userData")).id : null;
    let validate = validator.current.allValid();
    if (validate) {
      let pauseResumeDatas = pauseTimes.map((pause, index) => {
        pause.type = index % 2 === 1 ? "resume" : "pause";
        pause.time = moment(new Date(pause.time)).format("YYYY-MM-DD[T]HH:mm:ss.SSSS");
        return pause;
      });
      let requestBody = {
        staffId: id,
        workId: taskData._id,
        pauseResumeData:
          pauseTimes.length > 0
            ? pauseResumeDatas
            : taskData.workStaffAssignment.staffWorkData.filter(
                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
              ).length > 0 &&
              taskData.workStaffAssignment.staffWorkData.filter(
                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
              )[0].pauseResumeData !== undefined
              ? taskData.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                ).length > 0 &&
                taskData.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].pauseResumeData
              : defaultPauseTimes,
      };
      let updatedTask = {
        ...taskData,
        staffWorkData: [...taskData.workStaffAssignment.staffWorkData, {pauseResumeData: requestBody.pauseResumeData}],
      };
      await updateTaskData(updatedTask);
      await editTimesheetBreak(requestBody)
        .then((data) => {
          if (ErrorFound !== 1) {
            setUpdateLoading(false);
            setVisible(false);
            setVisibleEdit(true);
            handleReload(taskData._id);
          }
        })
        .catch((err) => {
          console.log(err);
          setUpdateLoading(false);
        });
    } else {
      validator.current.showMessages();
      forceUpdate(true);
      setUpdateLoading(false);
    }
  };

  const validator = useRef(
    new SimpleReactValidator({
      validators: {
        email: {
          message: "must be a valid mobile number or Email.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
              validator.helpers.testRegex(val, /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/)
            );
          },
          messageReplace: (message, params) => message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        element: (message) => <span className="error-message font-md">{message}</span>,
      },
    }),
  );
  const {timeSheetType, time: {
    // checkIn, checkOut
  }} = reducerState;

  const {workRequest, staffAssignmentId, notes, location, workStaffAssignment} = taskData;
  return (
    <NormalModal
      className={"modal-fill"}
      isOpen={view}
      size="lg"
      toggle={() => {
        setVisible(false);
        setVisibleEdit(true);
      }}
      renderHeader={(e) => (
        <div className=" d-flex justify-content-between mb-0">
          <h6 className="font-weight-bold ">Manual Timesheet Entry</h6>
        </div>
      )}
      bodyClass="add-staff-box rounded-0"
      headerClass="border-0 bg-violet pt-4 px-4 rounded-0">
      {!loading ? (
        <div className="time-off">
          <div className="row align-items-center mb-0">
            <div className="col-7">
              <p className="gray-span fs-16">Type</p>
              <div className="d-flex justify-content-between">
                <NormalRadio
                  name="timeSheetType"
                  className=" border-0 rounded-0"
                  value={"true"}
                  onChange={handleInputs}
                  checked={timeSheetType === "true"}
                  label={"Clock IN / OUT"}
                />
                {
                workStaffAssignment?.staffWorkData?.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].pauseResumeData &&
                workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].pauseResumeData.length > 0 && (
                  <NormalRadio
                    name="timeSheetType"
                    className=" ml-4 border-0 rounded-0"
                    value={"false"}
                    onChange={handleInputs}
                    checked={timeSheetType === "false"}
                    label={"Break"}
                  />
                )}
              </div>
            </div>
          </div>
          {timeSheetType === "true" && (
            <div className="row align-items-start mb-3 ">
              <div className="col-9 mt-4">
                <div className="row align-items-center mb-3 justify-content-center">
                  <div className="col-3">
                    <div className="form-group mb-0">
                      <label>Clock In</label>
                    </div>
                  </div>
                  <div className="col-9 d-flex align-items-end">
                    <div className="form-group mb-0 mr-1">
                      <NormalDate
                        placeholder="MM/DD/YYY"
                        name="clockIn"
                        value={
                          clockIn !== "" ? (
                            clockIn
                          ) : workStaffAssignment !== undefined &&
                          Object.keys(workStaffAssignment).length > 0 ? workStaffAssignment.staffWorkData &&
                          workStaffAssignment.staffWorkData.length > 0 ? (
                            new Date(
                              workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                                workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].clockIn,
                            )
                          ) : (
                            ""
                          ) : (
                            ""
                          )
                        }
                        className="form-control rounded-0 "
                        onChange={handleInput}
                        label={""}
                        icon={false}
                        // mindate="true"
                        parentId={"clockIn"}
                      />
                      <img
                        src={calendarIcon}
                        onClick={() => document.getElementById("clockIn").click()}
                        className="timesheetCalendar"
                        alt="calendarIcon"
                      />
                    </div>
                    <div className="form-group mb-0 d-flex ml-1">
                      <NormalInput
                        placeholder="HH:MM"
                        name="username"
                        value={
                          clockIn !== "" ? (
                            moment(clockIn).format("hh:mm")
                          ) : workStaffAssignment !== undefined &&
                          Object.keys(workStaffAssignment).length > 0 ? workStaffAssignment.staffWorkData &&
                          workStaffAssignment.staffWorkData.length > 0 ? (
                            moment(
                              workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                                workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].clockIn,
                            ).format("hh:mm")
                          ) : (
                            ""
                          ) : (
                            ""
                          )
                        }
                        className="form-control rounded-0 p-0 justify-content-center min-width"
                        label={""}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group mb-0 d-flex ml-1">
                      <NormalSelect
                        placeholder=""
                        name="clentName"
                        value={
                          clockIn !== "" ? (
                            moment(clockIn).format("A")
                          ) : workStaffAssignment !== undefined &&
                          Object.keys(workStaffAssignment).length > 0 ? workStaffAssignment.staffWorkData &&
                          workStaffAssignment.staffWorkData.length > 0 ? (
                            moment(
                              workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                                workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].clockIn,
                            ).format("A")
                          ) : (
                            "AM"
                          ) : (
                            "AM"
                          )
                        }
                        className="form-control rounded-0 p-0 justify-content-center min-width"
                        label={""}
                        options={times}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mb-3 justify-content-center">
                  <div className="col-3">
                    <div className="form-group mb-0">
                      <label>Clock Out</label>
                    </div>
                  </div>
                  <div className="col-9 d-flex align-items-end">
                    <div className="form-group mb-0 mr-1">
                      <NormalDate
                        placeholder="MM/DD/YYY"
                        name="clockOut"
                        value={
                          clockOut !== "" ? (
                            clockOut
                          ) : workStaffAssignment !== undefined &&
                          Object.keys(workStaffAssignment).length > 0 ? workStaffAssignment.staffWorkData &&
                          workStaffAssignment.staffWorkData.length > 0 ?  (workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          )[0].clockOut ? (
                            new Date(
                              workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 && 
                                workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].clockOut,
                            )
                          ) : "" ) : (
                            ""
                          ) : (
                            ""
                          )
                        }
                        className="form-control rounded-0 "
                        onChange={handleInput}
                        label={""}
                        icon={false}
                        mindate="true"
                        parentId={"clockOut"}
                      />
                      <img
                        src={calendarIcon}
                        onClick={() => document.getElementById("clockOut").click()}
                        className="timesheetCalendar"
                        alt="calendarIcon"
                      />
                    </div>
                    <div className="form-group mb-0 d-flex ml-1">
                      <NormalInput
                        placeholder="HH:MM"
                        value={
                          clockOut !== "" ? (
                            moment(clockOut).format("hh:mm")
                          ) : workStaffAssignment !== undefined &&
                          Object.keys(workStaffAssignment).length > 0 ? workStaffAssignment.staffWorkData &&
                          workStaffAssignment.staffWorkData.length > 0 ? (
                            moment(
                              workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                                workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].clockOut,
                            ).format("hh:mm")
                          ) : (
                            ""
                          ) : (
                            ""
                          )
                        }
                        className="form-control rounded-0 p-0 justify-content-center min-width"
                        label={""}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group mb-0 d-flex ml-1">
                      <NormalSelect
                        placeholder=""
                        name="clentName"
                        value={
                          clockOut !== "" ? (
                            moment(clockOut).format("A")
                          ) : workStaffAssignment !== undefined &&
                          Object.keys(workStaffAssignment).length > 0 ? workStaffAssignment.staffWorkData &&
                          workStaffAssignment.staffWorkData.length > 0 ? (
                            moment(
                              workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                                workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].clockOut,
                            ).format("A")
                          ) : (
                            "AM"
                          ) : (
                            "AM"
                          )
                        }
                        className="form-control rounded-0 p-0 justify-content-center min-width"
                        label={""}
                        options={times}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {workStaffAssignment.staffWorkData.length > 0 && (
                <div className="col-3 text-center">
                  <label className="mb-0 text-center">Total Hours</label>
                  <div className="border d-flex align-items-center justify-content-center h-100 p-5 ">
                    {moment(
                      clockOut !== "" &&
                      clockOut !==
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        ).length >
                          0 &&
                      workStaffAssignment.staffWorkData.filter(
                        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                      )[0].clockOut
                        ? clockOut
                        : workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length > 0 &&
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          )[0].clockOut,
                    ).diff(
                      clockIn !== "" &&
                      clockIn !==
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        ).length >
                          0 &&
                      workStaffAssignment.staffWorkData.filter(
                        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                      )[0].clockIn
                        ? clockIn
                        : workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length > 0 &&
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          )[0].clockIn,
                      "hours",
                    ) < 10 ? (
                      "0" +
                      moment(
                        clockOut !== "" &&
                        clockOut !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockOut
                          ? clockOut
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockOut,
                      ).diff(
                        clockIn !== "" &&
                        clockIn !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockIn
                          ? clockIn
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockIn,
                        "hours",
                      )
                    ) : (
                      moment(
                        clockOut !== "" &&
                        clockOut !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockOut
                          ? clockOut
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockOut,
                      ).diff(
                        clockIn !== "" &&
                        clockIn !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockIn
                          ? clockIn
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockIn,
                        "hours",
                      )
                    )}
                    :
                    {moment(
                      clockOut !== "" &&
                      clockOut !==
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        ).length >
                          0 &&
                      workStaffAssignment.staffWorkData.filter(
                        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                      )[0].clockOut
                        ? clockOut
                        : workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length > 0 &&
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          )[0].clockOut,
                    ).diff(
                      clockIn !== "" &&
                      clockIn !==
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        ).length >
                          0 &&
                      workStaffAssignment.staffWorkData.filter(
                        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                      )[0].clockIn
                        ? clockIn
                        : workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length > 0 &&
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          )[0].clockIn,
                      "minutes",
                    ) %
                      60 <
                    10 ? (
                      "0" +
                      moment(
                        clockOut !== "" &&
                        clockOut !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockOut
                          ? clockOut
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockOut,
                      ).diff(
                        clockIn !== "" &&
                        clockIn !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockIn
                          ? clockIn
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockIn,
                        "minutes",
                      ) %
                        60
                    ) : (
                      moment(
                        clockOut !== "" &&
                        clockOut !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockOut
                          ? clockOut
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockOut,
                      ).diff(
                        clockIn !== "" &&
                        clockIn !==
                          workStaffAssignment.staffWorkData.filter(
                            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                          ).length >
                            0 &&
                        workStaffAssignment.staffWorkData.filter(
                          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                        )[0].clockIn
                          ? clockIn
                          : workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].clockIn,
                        "minutes",
                      ) % 60
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {timeSheetType === "false" && (
            <div className="row align-items-start mb-3 ">
              <div className="col-9 mt-4">
                {workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                ).length > 0 &&
                workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].pauseResumeData &&
                workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].pauseResumeData.length > 0 ? (
                  workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  workStaffAssignment.staffWorkData
                    .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
                    .pauseResumeData.map((pause, index) => (
                      <div className="row align-items-center mb-3 justify-content-center">
                        <div className="col-3">
                          <div className="form-group mb-0">
                            <label>{index % 2 === 1 ? "Resumed at" : "Break out"}</label>
                          </div>
                        </div>
                        <div className="col-9 d-flex align-items-end">
                          <div className="form-group mb-0 mr-1">
                            <NormalDate
                              placeholder="MM/DD/YYY"
                              name="clockIn"
                              value={new Date(pause.time)}
                              className="form-control rounded-0 "
                              onChange={(date) => handleInput2(date, index)}
                              label={""}
                              icon={false}
                              // mindate="true"
                              parentId={index % 2 === 1 ? "Resume" : "Break"}
                            />
                            {index % 2 === 1 ? (
                              <img
                                src={calendarIcon}
                                onClick={() => document.getElementById("Resume").click()}
                                className="timesheetCalendar"
                                alt="calendarIcon"
                              />
                            ) : (
                              <img
                                src={calendarIcon}
                                onClick={() => document.getElementById("Break").click()}
                                className="timesheetCalendar"
                                alt="calendarIcon"
                              />
                            )}
                          </div>
                          <div className="form-group mb-0 d-flex ml-1">
                            <NormalInput
                              placeholder="HH:MM"
                              name="username"
                              value={moment(pause.time).format("hh:mm")}
                              className="form-control rounded-0 p-0 justify-content-center min-width"
                              label={""}
                              disabled={true}
                            />
                          </div>
                          <div className="form-group mb-0 d-flex ml-1">
                            <NormalSelect
                              placeholder=""
                              name="clentName"
                              value={moment(pause.time).format("A")}
                              className="form-control rounded-0 p-0 justify-content-center min-width"
                              label={""}
                              options={times}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  defaultPauseTimes.map((pause, index) => (
                    <div className="row align-items-center mb-3 justify-content-center">
                      <div className="col-3">
                        <div className="form-group mb-0">
                          <label>{index % 2 === 1 ? "Resumed at" : "Break out"}</label>
                        </div>
                      </div>
                      <div className="col-9 d-flex align-items-end">
                        <div className="form-group mb-0 mr-1">
                          <NormalDate
                            placeholder="MM/DD/YYY"
                            name="clockIn"
                            value={pause.time ? new Date(pause.time) : ""}
                            className="form-control rounded-0 "
                            onChange={(date) => handleInput2(date, index)}
                            label={""}
                            icon={false}
                            mindate="true"
                          />
                        </div>
                        <div className="form-group mb-0 d-flex ml-1">
                          <NormalInput
                            placeholder="HH:MM"
                            name="username"
                            value={pause.time ? moment(pause.time).format("HH:mm") : ""}
                            className="form-control rounded-0 p-0 justify-content-center min-width"
                            label={""}
                            disabled={true}
                          />
                        </div>
                        <div className="form-group mb-0 d-flex ml-1">
                          <NormalSelect
                            placeholder=""
                            name="clentName"
                            value={pause.time ? moment(pause.time).format("A") : moment(new Date()).format("A")}
                            className="form-control rounded-0 p-0 justify-content-center min-width"
                            label={""}
                            options={times}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}

          <div className="row align-items-center mb-3">
            <div className="col-7">
              <div className="form-group  d-flex">
                <NormalInput
                  placeholder="Select customer code"
                  name="workRequest"
                  value={workRequest}
                  className="form-control rounded-0 "
                  onChange={handleInput}
                  label={"Customer/work request"}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-7">
              <div className="form-group">
                <NormalInput
                  placeholder="(none)"
                  name="jobAssigned"
                  value={staffAssignmentId ? "Yes" : "No"}
                  className="form-control rounded-0 "
                  handleChange={handleInput}
                  label={"job assigned"}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-7">
              <div className="form-group">
                <NormalTextarea
                  placeholder=" Click here"
                  name="notes"
                  rows={2}
                  value={notes}
                  className="form-control rounded-0"
                  onChange={handleInput}
                  label={"Notes"}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-7">
              <div className="form-group">
                <NormalInput
                  placeholder={location}
                  name="timeZone"
                  value={location}
                  className="form-control rounded-0 "
                  handleChange={handleInput}
                  label={"Time Zone"}
                  disabled={true}
                />
              </div>
              <i className="gray-span fs-12 italic">Currently {location} ( 0530 UTC)</i>
            </div>
          </div>

          <div className="row align-items-center mt-3 justify-content-end px-2">
            <NormalButton
              onClick={() => {
                setVisible(false);
                setVisibleEdit(true);
              }}
              id="Confirm"
              label="Cancel"
              outline={false}
              className="mb-2 btn fs-14 font-weight-bold bg-violet-border btn-block h38 w152 m-2"
            />
            {!submitloading || !updateloading ? (
              <NormalButton
                onClick={submitProfile}
                id="Confirm"
                label="Save"
                outline={false}
                className="mb-2 btn fs-14 btn-dark-primary btn-block h38 w152 m-2"
              />
            ) : (
              <CommonPageLoader />
            )}
          </div>
        </div>
      ) : (
        <CommonPageLoader />
      )}
    </NormalModal>
  );
}
const mapStateToProps = (state) => {
  return {
    taskData: state.home.task,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTimesheet,
      getStaffById,
      getTimesheet,
      updateTimesheet,
      editTimesheetBreak,
      editTimesheet,
      updateTaskData,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualTimeSheet);

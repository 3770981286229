import React from 'react';
import './NormalTimeInput.scss';
export const NormalTimeInput = ({
  className = 'form-control',
  placeholder = '',
  label = '',
  onChange,
  value = '',
  name,
  disabled = false,
  type = 'text',
}) => {
  return (
    <div className="normal-time-input">
      {label !== '' ? <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">{label}</div> : ''}
      <input
        className={className}
        name={name}
        type="text"
        disabled={disabled}
        value={value}
        min={0}
        maxLength="2"
        placeholder={placeholder}
        onChange={(e) => {
          let body = {};

          body = {
            target: {
              name: e.target.name,
              value: e.target.value,
            },
          };

          onChange(body);
        }}
      />
    </div>
  );
};

import React, {Component} from "react";
import DatePicker from "react-datepicker";
import "./date.scss";
import calendarIcon from "assets/svg/Vector (13).svg";
import calendarIconSolid from "assets/svg/calendar solid.svg";
import moment from "moment";

export class NormalDate extends Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
  }
  render() {
    let {
      className = "form-control w-100",
      placeholder = `${localStorage.getItem('dateFormat')}`,
      onChange,
      value = "",
      name,
      disabled = false,
      isReadOnly = false,
      showYearPicker = false,
      showFullMonthYearPickerStatus = false,
      showMonthYearPickerStatus = false,
      showYearDropdown = false,
      showMonthDropdown = false,
      maxdate = "false",
      mindate = "false",
      timeInput = "false",
      icon = "true",
      label = "",
      labelClass = "",
      iconleft = false,
      parentId = "",
      showTimeSelect=false,
      timeIntervals=30,
      open,
      readOnly,
    } = this.props;

    return (
      <>
      {!showTimeSelect ? 
        <div className="normal-date-input">
        {label !== "" ? (
          <div className={`fs-17 text-black ${labelClass} font-Gilroy pb-2 mb-1 line-height-20`}>{label}</div>
        ) : (
          ""
        )}
        {!isReadOnly ? (
          <React.Fragment>
            <div>
            <div className="d-flex align-items-center">
              {iconleft && <img fetchPriority="high" src={calendarIconSolid} className="calendar-icon-left ml-2" alt="calendarIcon" width={16} height={16} />}
              <DatePicker
                id={parentId}
                popperPlacement="bottom-start"
                open={open}
                readOnly={readOnly}
                timeInputLabel={timeInput && "Time:"}
                showTimeInput={timeInput}
                className={className}
                disabled={disabled}
                placeholderText={placeholder}
                selected={value}
                showYearPicker={showYearPicker}
                showMonthYearPicker={showMonthYearPickerStatus}
                showFullMonthYearPicker={showFullMonthYearPickerStatus}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dateFormat={`${localStorage.getItem('dateFormat')==="DD/MM/YYYY"?"dd/MM/yyyy":"MM/dd/yyyy"}`}
                maxDate={maxdate === "true" && new Date()}
                minDate={mindate === "true" && new Date()}
                autoComplete="off"
                onChange={(date, e) => {
                  if (!readOnly) {
                  let body = {};
                    body = {
                    target: {
                      name: name,
                      value: date,
                    },
                  };
                  if(e?.target.value) { 
                    const isValid = moment(e.target.value,localStorage.getItem('dateFormat'),true).isValid() 
                    isValid && onChange(body);
                  } 
                  else {
                    onChange(body);
                  }
                 }
                }}
                name={name}
              />
            </div>
            <div>
            {icon && <img fetchPriority="high" src={calendarIcon} className="calendar-icon" alt="calendarIcon" width={24} height={24} />}
            </div>
          </div>
          </React.Fragment>
        ) : (
          <p>{value}</p>
        )}
      </div>
      :
      <div className="normal-date-input">
        {label !== '' ?
          <div className="fs-12 text-black font-JosefinSans pb-2 mb-1 line-height-20 defaultfont">
            {label}
          </div>
          : ''}
        {!isReadOnly ?
          <DatePicker
            filterTime={this.filterPassedTime}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            showTimeSelectOnly
            showPopperArrow={false}
            // timeInputLabel={timeInput && "Time:"}
            // showTimeInput={timeInput}
            className={className}
            disabled={disabled}
            placeholderText={placeholder}
            selected={value}
            // showYearPicker={showYearPicker}
            // showMonthYearPicker={showMonthYearPickerStatus}
            // showFullMonthYearPicker={showFullMonthYearPickerStatus}
            // showMonthDropdown={showMonthDropdown}
            // showYearDropdown={showYearDropdown}
            dateFormat="h:mm aa"
            // maxDate={maxdate && new Date()}
            minDate={mindate}
            autoComplete="off"
            onChange={date => {
              let body = {};

              body = {
                target: {
                  name: name,
                  value: date

                },
              };

              onChange(body);

            }}
            name={name}
          />
          :
          <p>{value}</p>
        }

      </div>
      }
      </>
    );
  }
}

import React, {Component} from 'react';
import CustomLoader from "component/common/CustomLoader";

export class NormalButton extends Component {
  render() {
    const {
      className = '',
      label = '',
      leftIcon = '',
      onClick,
      id,
      disabled = false,
      outline = false,
      mainbg = false,
      link = false,
      normal = false,
      danger = false,
      rightIcon = '',
      loader = false,
      loaderColor = '',
      ...rest
    } = this.props;

    return (
      <div className="">
        <button
          id={id}
          className={`btn cursor-pointer ${rightIcon ? 'd-flex justify-content-between' : ''}  ${outline
            ? 'outline-btn'
            : ''} ${mainbg ? 'mainbg-btn' : ''} ${normal ? 'normal-btn' : ''} ${danger ? 'danger-btn' : ''} ${link
            ? 'delete-btn'
            : ''} ${className}`}
          onClick={onClick}
          disabled={disabled}
          loader = {loader}
          {...rest}>
          {leftIcon !== '' ? <img className="pl-2" src={leftIcon} alt="leftIcon" /> : ''}
          {label} {loader ? <CustomLoader color={loaderColor}/>: null}
          {rightIcon !== '' ? <img className="pl-2" src={rightIcon} alt="rightIcon" /> : ''}
          {rightIcon !== '' ? <span className={`btn-right-icon  ${rightIcon}`} /> : ''}
        </button>
      </div>
    );
  }
}

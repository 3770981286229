import React from 'react';
import {Modal} from 'react-bootstrap';
import "./style.scss";
import refreshIcon from "../../../assets/svg/mdi_refresh_white.svg";
import WaringIcon from "assets/svg/warning.svg"

export class NormalModal extends React.Component {
  render() {
    var {
      children,
      isOpen,
      toggle,
      className = '',
      verticalCenter = true,
      footerContent = '',
      headerClass = 'modal-header',
      bodyClass = '',
      footerClass = '',
      //toggleKey,
      loading = false,
      renderHeader = '',
      size = 'lg',
      centered = false,
      backdrop = true,
      close = '',
      onRefresh=false,
    } = this.props;

    return (
      <Modal
        show={isOpen}
        onHide={toggle}
        backdrop={backdrop}
        className={`${verticalCenter && 'modal-dialog-centered'} ${className}`}
        size={size}
        centered={centered}>
        <Modal.Header className={headerClass} onHide={toggle} closeButton={close !== 'no'}>
          <Modal.Title className={renderHeader ? '' : 'waning-dialogue-container-title'} id="contained-modal-title-vcenter">
            {renderHeader ? renderHeader() : <p className='warning-title'><img src={WaringIcon} style={{marginBottom:"8px"}} /> Alert!</p>}
          </Modal.Title>

          {/* <span className="icon-close font-sm" onClick={e => toggle(toggleKey)} /> */}
          {onRefresh && <img style={{minWidth: "23px"}} className='refreshIconInModel ml-3' src={refreshIcon} onClick={onRefresh} alt="refreshIcon"/>}
        </Modal.Header>
        <div className="position-relative">
          <Modal.Body className={` bg-transparent border-0 ${bodyClass}`}>{children}</Modal.Body>

          {footerContent ? <Modal.Footer className={footerClass}>{footerContent()}</Modal.Footer> : ''}

          {loading ? (
            <div className="upload-loader">
              <div className="position-relative w-100 h-100">
                <div className="loader-circle" />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal>
    );
  }
}

import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { store } from './helpers';

export const stompClients = [];

 function connectToWebSocket(){
  const wsUrls = JSON.parse(process.env.REACT_APP_WEBSOCKET);
  const token = localStorage.getItem('AuthToken')
  wsUrls.forEach((url) => {
    const ws = new SockJS(url);
    const stompClient = Stomp.over(ws);
    stompClient.debug = null
    stompClients.push(stompClient)
    stompClient.connect({ Authorization: "Bearer " + token }, function(frame) {
      const portString = url.match(/:(\d+)/)[1];
      const portNumber = parseInt(portString.slice(-2));
      
      if (portNumber) {
        if(portNumber === 99){
          stompClient.subscribe("/users/topic/notify/count",function(data) {
            let NFCData = JSON.parse(data.body)
               store.dispatch({type:'UPDATE_NOTIFICATION_COUNT', payload:NFCData.message})
          });
        }else if(portNumber === 95 || portNumber === 90){
          stompClient.subscribe("/users/topic/staff/workrequest",function(data) {
            let wrData = JSON.parse(data.body)
               store.dispatch({type:'UPDATE_WORK_REQUEST', payload:wrData.message})
              
          });
        }
      }

      store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:true})
    })
    ws.onclose = function() {
      store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:false})
     };
    window.addEventListener('offline', ()=>{
      ws.close()
    }, {passive: true})
  })
}
  
export default connectToWebSocket
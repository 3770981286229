import { Settings, settingsApi } from '../service/apiVariables';
import { addQuery } from '../service/helperFunctions';

export const getStaffById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, Settings.getWorkById);
        api({ ...Settings.getWorkById })
            .then(({ data }) => {
                resolve(data);
                dispatch({type:'USER_DATA', payload:data})
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getTimeZoneList = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, Settings.getTimeZoneList);
        api({ ...Settings.getTimeZoneList })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const getSettingsById = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, settingsApi.getSettingsById);
        api({ ...settingsApi.getSettingsById })
            .then(({ data }) => {
                resolve(data);
                dispatch({type:'AGENCY_DATA', payload:data})
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const updateStaff = (body) => (dispatch, getState, { api, Toast }) => {
    
    return new Promise((resolve, reject) => {
       api({ ...Settings.updateStaff, body })
          .then(({ data }) => {
             resolve(data);
          })
          .catch(({ message }) => {
             reject(Toast({ type: 'error', message }));
          });
    });
 };
const { UPDATE_CONNECTION_STATUS, UPDATE_NOTIFICATION_COUNT, UPDATE_WORK_REQUEST } = require("service/actionType");



const initialState = {
    isConnected:false,
    notificationCount:false,
    workRequest:null,
  };

  export function updateNewRecord(data){
    return {
        type: UPDATE_WORK_REQUEST,
        payload: data,
    }
}

  export default  (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CONNECTION_STATUS:
        return {
          ...state,
          isConnected: action.payload,
        };
        case UPDATE_NOTIFICATION_COUNT:
          return {
            ...state,
            notificationCount: action.payload,
          };
      case UPDATE_WORK_REQUEST:
        return {
          ...state,
          workRequest: action.payload,
        };
      default:
        return state;
    }
  };
  
import React, { memo } from 'react'
import "./style.scss";
import moment from "moment";
import notifi_clock from "assets/svg/noti_clock.svg"
import atom_white from 'assets/svg/atom_white.svg';
import flightTransparent from 'assets/svg/flight transparent.svg';
import settingsTransparent from "assets/svg/settings transparent.svg";
import profileTransparent from "assets/svg/periodic_table_white.svg";
import periodic_table_white from "assets/svg/periodic_table_white.svg";
import { useHistory } from 'react-router-dom';
import { startTourGuide } from 'reducer/tourGuide';
import { useDispatch } from 'react-redux';
import No_results_found from "assets/svg/no_data_found_illus.svg";

const NotificationMessages = ({data,setWorkAssign}) => {
    let notificationData = [...data]
    const history = useHistory()
    const dispatch = useDispatch();
    let OrientationCount = localStorage.getItem('OrientationCount');
    const handleTour = ({target:{value}}) => {
      dispatch(startTourGuide({run: true}))
    }
  return (
    <div className="down">
          {notificationData.length ? (
            notificationData.map(({date, notifications}, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="ml-3 mr-3" >
                    <h6 className="num-sb notification-date">{moment(date).format(`${localStorage.getItem('dateFormat')}`)}</h6>
                    {notifications.map((item) => (
                      <div
                        key={item.id}
                        className="container notification-bar cursor-pointer"
                        onClick={() => {
                          if (item.path.split('/')[1]==="workSchedule"){
                            setWorkAssign({
                                            value: true, 
                                            id: item.path.split('/')[2]
                                          });
                          } else if (item.path.split('/')[1] === "editTimeSheet"){
                            setWorkAssign({ value: true, 
                                            id: item.path.split('/')[2],
                                          });
                          }
                          else if(item.path.split('/')[2] === "staffProfile"){
                            history.push('/admin/staffProfile')
                          }
                          else if (item.path.split('/')[1] === "jobMaster"){
                            history.push('/admin/staffProfile')
                          }
                          else if (item.path.split('/')[1] === "settings"){
                            history.push('/admin/settings')
                          }
                          // else{
                          //   alert("NOT IMPLEMENTED YET.")
                          // }
                        }}>
                        <div className="row align-items-start p-3 align-items-stretch">
                          <div className="col-9 pl-0 d-flex justify-content-start align-items-center">
                            <div style={{
                              background: item.status === 1 ? "#FB6873" :
                                          item.status === 2 ? "#FB6873" :
                                          item.status === 3 ? "#FB6873" :
                                          item.status === 4 ? "#F3993E" :
                                          item.status === 5 ? "#FDF851" : 
                                          item.status === 6 ? "#FB6873" : 
                                          item.status === 7 ? "#9C4EF7" :  
                                          item.status === (8 || 9) ? "#73F340":
                                          ""
                            }} className="checkBox col-1 ">
                            <img className='notif-icon' style={{width: "20px"}} src={
                              item.path.split('/')[1] === "timeOff" ? flightTransparent : 
                              item.path.split('/')[1] === "workSchedule" ? atom_white : 
                              item.path.split('/')[1] === "editTimeSheet" ? atom_white : 
                              item.path.split('/')[1] === "getById" ? settingsTransparent : 
                              item.path.split('/')[1] === "jobMaster" ? profileTransparent : 
                              item.path.split('/')[2] === "staffProfile" ? periodic_table_white :
                              item.path.split('/')[1] === "settings" ? settingsTransparent :
                              ""
                            } alt="" />
                            </div>
                            <div className="col-11">
                              {/* <p className=" pl-3 fs-12 font-weight-bold mb-1 text-grey-42">{item.status}</p> */}
                              <p className="h3-m pl-3 wordwrap fixed-height mb-0 text-grey-6c notification-msg-container">{item.message}</p>
                            </div>
                          </div>
                          <div className="col-2 text-right pr-40 d-flex flex-column justify-content-between cursor-pointer text-center">
                               <div>
                              <p className="text-grey-6c mb-0 cta-2-m">View</p>
                              </div>
                              <div class="frame-4430">
                           <img class="notifi_clock" src={notifi_clock} />
                           <div class="_notifi-time">{moment(item.createdAt).format('hh:mm a')}</div>
                           </div>
                          </div> 
                        </div>
                      </div>
                    ))}
                  </div>
                 
                </React.Fragment>
              );
            })
          ) : (
            <>
            <div className='d-flex justify-content-center align-items-center' style={{width:"100%", flexDirection:"column"}}>
            <div>
            <img className="preload-image no_result_found_notifi" src={No_results_found}
                        onLoad={() => {
                          document.querySelector('.preload-image').style.display = 'block';
                        }}
            />
            </div>
            <div>
            {OrientationCount ==="true" ? <p style={{fontSize:"13px", margin:"auto", textAlign:"center"}}>Not Sure Where To Start? <u style={{color: "#3FB1FB",cursor:"pointer"}} onClick={handleTour}>Click Product Tour</u> to know more</p> :""}
            </div>
            </div>
            </>
          )}
           </div>
  )
}

export default memo(NotificationMessages)

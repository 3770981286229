import React from 'react'
import './style.scss'


const CustomLoader = ({ color }) => {

  const spinnerStyle = {
    borderColor: `${color} transparent transparent transparent`
  };
  
  return (
    <div className="lds-ring">
      <div style={spinnerStyle}></div>
      <div style={spinnerStyle}></div>
      <div style={spinnerStyle}></div>
      <div style={spinnerStyle}></div>
    </div>
  );
};

export default CustomLoader
import { stompClients } from "service/webSocket";
import { getCurrentLocation } from "./getCurrentLocation";
import moment from "moment";

const getWebsocketClient = () =>{
    return stompClients ? stompClients[0] : null 
}

let continueRunning = false

const sendLocationToBackend = ({
    client,
    id= '',
    type= 5,
    staffId= '',
    agencyId= '',
    workRoutePoints={
        coordinates: [0, 0], 
        timeStamp: new Date().toISOString() 
       },
    timeZone=''

     }) => {

// console.log('send location to backend', client)

  if (client && client.connected && continueRunning) {

    var message = {
        id: id,
        type: type,
            staffId: staffId,
            agencyId: agencyId,
            message: [workRoutePoints],
        timeZone:timeZone
        };
        
        let jsonString = JSON.stringify(message);
        
    client.send('/app/updateWorkRoutePoints', {}, jsonString);
    // console.log('send to backend',message)
  }
};

const startLocationTracking = async ({
    id = '',
    type = 5,
    staffId= '',
    agencyId = '',
    timeZone =''
    }, 
    interval = 60000) => {

// console.log('start location tracking function called')

    const captureLocation = () =>{
    
    function getLocation(client) {
        return new Promise((resolve, reject) => {
            getCurrentLocation()
            .then((res)=>{
                if(res.status){
                    const {latitude, longitude} = res
                        sendLocationToBackend({
                            client,
                            id,
                            type,
                            staffId,
                            agencyId,
                            workRoutePoints:{
                             coordinates:[longitude,latitude],
                             timeStamp:moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSS'),
                            },
                            timeZone
                            });
                    
                    resolve();
                }else{
                    reject('Location not captured')
                }
            })
            .catch((err)=>{
                console.log(err)
                reject(err);
            })
        });
    }
    
    function getLocationWithRetry(client) {
        // console.log('get location . . .', ' running :', continueRunning);
        getLocation(client)
            .then(() => {
                if(continueRunning){
                    setTimeout(()=>getLocationWithRetry(client), interval);
                }
            })
            .catch(err => {
                console.error('Error occurred:', err);
                if(continueRunning){
                    setTimeout(()=>getLocationWithRetry(client), interval);
                }
            });
    }
    

    function attemptConnection() {
        let stompClient = getWebsocketClient();
        // console.log('checking . . . ')
        if (stompClient && stompClient?.connected) {
            // console.log(stompClient.connected)
            getLocationWithRetry(stompClient);
            // continueRunning = false
        } else {
            setTimeout(attemptConnection, 5000); 
        }
    }


    // check the websocket connection & start the location tracking.
       attemptConnection()

    }


    // console.log('startRunning_invoke')
    continueRunning = true;
    captureLocation()



// console.log('function end', continueRunning)
};

export function stopLocationTracking() {
    continueRunning = false;
  }

export default startLocationTracking;

import React, {Component} from 'react';
import './table.scss';
import sortIcon from 'assets/svg/sort.svg';
import PropTypes from 'prop-types';
import Pagination from "react-js-pagination";

export class TableWrapper extends Component {
  state = {
    currentSortKeyDetails: null,
    paginationactive: 1,
    options: [
            {value:10,label:'10'},
            {value:50,label:'50'},
            {value:100,label:'100'},
            {value:500,label:'500'},
            {value:1000,label:'1000'},
      ],
    keyName: 'label',
    valueName: 'value',
    placeholder: 'Select',
  };

  getPlaceHolder = () => {
    // getPlaceholder logic goes here
  };
  handlePageChange(pageNumber) {
    this.setState({paginationactive: pageNumber});
    this.props.paginationChange(pageNumber);
  }

  render() {
    let {handleSort, pagination=false , page ,paginationConfig,pageCountChange, headerDetails, children, isEmpty = false, className = '', overFlow = true} = this.props;
    let { options, keyName, valueName, placeholder } =
    this.state; 
  
    return (
      <div className="maintable table-container">
          {pagination && <div className="div-pagination">
          <Pagination
            activePage={page}
            itemsCountPerPage={paginationConfig ? paginationConfig.pageCount : 0}
            totalItemsCount={paginationConfig ? paginationConfig.TotalCount : 0}
            pageRangeDisplayed={paginationConfig ? paginationConfig.btnDisplay : 0}
            itemClass="page-item"
            linkClass="page-link"
            innerClass=" custom-pagination pagination"
            nextPageText="Next"
            prevPageText="Previous"
            hideFirstLastPages={true}
            linkClassPrev="custom-prev"
            linkClassNext="custom-next"
            disabledClass="custom-disable"
            onChange={this.handlePageChange.bind(this)}
          />
          <div className="total-entries">
            <span>Show</span>
            <select
              className="custom-hide-option mx-2"
              value={paginationConfig ? paginationConfig.pageCount : 0}
              onChange={(e) => {
                pageCountChange(e.target.value);
                this.setState({paginationactive : 1});
                this.props.paginationChange(1)
              }}>
              <option value="" disabled defaultValue>
                {placeholder}
              </option>
              {options.length !== 0 && paginationConfig ? (
                options.map((option, index) => (
                  <option
                    value={option[valueName]}
                    disabled={option.value > (paginationConfig?.TotalCount) ? true : false}
                    key={index}>
                    {option[keyName]}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No Options
                </option>
              )}
            </select>
            <span>Entries</span>
          </div>
        </div> }
        <div className={`maintable-content px-2 ${overFlow ? 'table-responsive' : ''} ${className}`}>
          <table className="table">
            <thead>
              <tr>
                {headerDetails?.map(
                  ({label, className, divClass = '', sortKey = '', element, isSort, type, isAsc, key}, index) => {
                    return (
                      <th className={className} key={index} id={`set${index}`}>
                        <div
                          className={`d-flex align-items-center justify-content-start line-height-19 text-center  ${sortKey &&
                            'cursor-pointer'} ${divClass}`}>
                          {label}
                          {element && element()}
                          {isSort ? (
                            <div
                              className={`d-flex table-filter align-items-center ml-2 cursor-pointer
                                                        'active-filter'}`}
                              onClick={() => handleSort(type, isAsc, key, index)}>
                              <img src={sortIcon} alt="sortIcon" width={11} height={11} />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </th>
                    );
                  },
                )}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
          {isEmpty ? this.getPlaceHolder() : ''}
        </div>
      </div>
    );
  }
}

TableWrapper.propTypes = {
  placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isEmpty: PropTypes.bool,
  headerDetails: PropTypes.array.isRequired,
  pageMeta: PropTypes.bool,
};

import React,{ useState } from "react";
import {
    REJECTED_BY_STAFF,
  } from "service/constants";
import downArrowPink from "assets/svg/down-arrow-pink.svg"
import downArrowGray from "assets/svg/down-arrow-gray.svg"
import CustomLoader from "component/common/CustomLoader";
import "./RejectAccordian.scss"


const RejectAccordian = (props)=>{

    const { submitLoading } = props

    // const [show, setShow] = useState(false);
    const [newchecked,setnewChecked] = useState([]);
    const [texarea,setTextarea] = useState("");
    const [data] = useState(props.data);
    const [inputone,setInputone] = useState(false);
    const [inputtwo,setInputtwo] = useState(false);
    const [inputthree,setInputthree] = useState(false);
    const [inputfour,setInputfour] = useState(false);
    const [inputfive,setInputfive] = useState(false);
    const [inputzero,setInputzero] = useState(false);
    const [contact,setContact] = useState(false);
    const [open,setOpeninput] = useState(false);
    
    const data1 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Lack of Assistance"){
            return true
        }
    })
    

    const data2 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Not meeting state guidelines*"){
            return true
        }
    })
    
    const data3 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unsafe work environment*"){
            return true
        }
    })

    const data4 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Unavailable"){
            return true
        }
    })

    const data5 = data?.rejectReasons?.filter((elem)=>{
        if(elem=="Work loads are too large"){
            return true
        }
    })

    const data0 = data?.rejectReasons?.filter((elem)=>{
        if(elem== "Other"){
            return true
        }
    })

    const handleCheckChange = (e)=>{

        const {value,checked}=e.target;

        if(checked){
            setnewChecked(pre => [...pre,value])
        }
        else{
            setnewChecked(pre =>{
                return [...pre.filter(elem => elem!==value)]
            })
        }
    }
    
    const handleContactChange = (e) =>{
        if(contact == false){
            setContact(true)
        }
        else{
            setContact(false);
        }
    }
    
    const handletextarea = (e) =>{
        setTextarea(e.target.value)
    }
    


   
    const handleinputchange = (e)=>{

        const {value,checked}=e.target;
       
        if(value=="Lack of Assistance"){
            if(checked==true){
                setInputone(true)
            }
            else{
                setInputone(false)
            }
        }
        if(value=="Not meeting state guidelines*"){
            if(checked==true){
                setInputtwo(true)
            }
            else{
                setInputtwo(false)
            }
        }
        if(value=="Unsafe work environment*"){
            if(checked==true){
                setInputthree(true)
            }
            else{
                setInputthree(false)
            }
        }
        if(value=="Unavailable"){
            if(checked==true){
                setInputfour(true)
            }
            else{
                setInputfour(false)
            }
        }
        if(value=="Work loads are too large"){
            if(checked==true){
                setInputfive(true)
            }
            else{
                setInputfive(false)
            }
        }
        if(value=="Other"){
            if(checked==true){
                setInputzero(true)
                setOpeninput(true)
            }
            else{
                setInputzero(false)
                setOpeninput(false)
            }
        }
 
    }

    // useEffect(()=>{
    //     if(data0?.length>0){
    //         setOpeninput(true)
    //     }
    //     else if(data0?.length==0){
    //         setOpeninput(false)
    //     }
    // })

    const handleSubmit = (e)=>{
        if(submitLoading) {
            e.preventDefault()
            return;
        }
        let id= props.id
         e.preventDefault()
         props.onSubmit({newchecked,texarea,id,contact})
    }
    
    return (
        <>
        {/* <div className={show==true?"outerdiv":""}>
        {show &&  */}

<div className="setrejectfields">
    <div className="setheading">
    <h3 className="set_heading" ></h3>
    </div>
    <form onSubmit={handleSubmit}>
        
    <div className="setinputfields">
    <div className="setheadingborder" style={{background: props.statuss === REJECTED_BY_STAFF && "#f5f5f5"}}> <h3 className="header" >Select reason(s) for rejecting Work Request:</h3></div>
      <div className="innderdiv1" style={{borderColor: props.statuss === REJECTED_BY_STAFF && "#f5f5f5"}}>
      <div className="innerdiv" style={{background: props.statuss === REJECTED_BY_STAFF && "#f5f5f5"}}>
        <input disabled={props.statuss === REJECTED_BY_STAFF} style={{accentColor: props.statuss === REJECTED_BY_STAFF && "gray"}} onChange={(e)=>handleCheckChange(e)}  onClick={(e)=>  handleinputchange(e)} className="setinput1" type="checkbox" checked={data1?.length>0?true:inputone} name="Lack of Assistance" value="Lack of Assistance" /> <label >Lack of Assistance </label> 
        <br></br>
        <input disabled={props.statuss === REJECTED_BY_STAFF} style={{accentColor: props.statuss === REJECTED_BY_STAFF && "gray"}} onChange={(e)=>handleCheckChange(e)}  onClick={(e)=>  handleinputchange(e)}  className="setinput2" type="checkbox"  checked={data2?.length>0?true:inputtwo} name="Not meeting state guidelines*" value="Not meeting state guidelines*" /> <label> Not meeting state guidelines*</label> 
        <br></br>
        <input  disabled={props.statuss === REJECTED_BY_STAFF} style={{accentColor: props.statuss === REJECTED_BY_STAFF && "gray"}} onChange={(e)=>handleCheckChange(e)}  onClick={(e)=>  handleinputchange(e)} className="setinput2" type="checkbox"  checked={data3?.length>0?true:inputthree} name="Unsafe work environment*" value="Unsafe work environment*" /> <label> Unsafe work environment* </label> 
        <br></br>
        <input  disabled={props.statuss === REJECTED_BY_STAFF} style={{accentColor: props.statuss === REJECTED_BY_STAFF && "gray"}} onChange={(e)=>handleCheckChange(e)}  onClick={(e)=>  handleinputchange(e)}  className="setinput2" type="checkbox"  checked={data4?.length>0?true:inputfour} name="Unavailable" value="Unavailable" /> <label> Unavailable</label> 
        <br></br>
        <div className="setinput5">
        <input  disabled={props.statuss === REJECTED_BY_STAFF} style={{accentColor: props.statuss === REJECTED_BY_STAFF && "gray"}} onChange={(e)=>handleCheckChange(e)}  onClick={(e)=>  handleinputchange(e)}  className="setinput" type="checkbox"  checked={data5?.length>0?true:inputfive} name="Work loads are too large" value="Work loads are too large" />  <label> Work loads are too large</label> 
        </div>
        <div className="setother">
        <input disabled={props.statuss === REJECTED_BY_STAFF} style={{accentColor: props.statuss === REJECTED_BY_STAFF && "gray"}} onChange={(e)=>handleCheckChange(e)}  onClick={(e)=>  handleinputchange(e)}  className="setinput" type="checkbox"  checked={data0?.length>0?true:inputzero} name="Other" value="Other" />  <label> Other</label> 
        </div>
       
    { open ?  <input key={open} disabled={props.statuss === REJECTED_BY_STAFF} id="othercomments" onChange={handletextarea} className="settextarea" rows="3" cols="30" placeholder={data?.additionalComments} /> : null}
    <div className="setsubmitbutton"><button id="submit" style={{position: submitLoading && "relative", right: submitLoading && "5px", opacity: submitLoading ? 0.5 : 1, cursor: submitLoading ? "not-allowed" : "pointer"}} className={props.statuss === REJECTED_BY_STAFF?"btn-muted setSubmit":"setsubmit d-flex"} disabled={props.statuss === REJECTED_BY_STAFF}><span>Submit</span> {submitLoading ? <CustomLoader color="white"/> : null}</button></div>
    <div className="setcontactdiv"><input id="discuss" disabled={props.statuss === REJECTED_BY_STAFF} onChange={(e)=>  handleContactChange(e)} className="setcontact" type="checkbox" checked={data?.contactEnabled == true?true:contact} /> <label className="setcontactlabel">Please Contact to Discuss</label></div>
    </div>
    </div>
    <div className="sethr" style={{background: props.statuss === REJECTED_BY_STAFF && "#f5f5f5"}}></div>
    </div>
    </form>
</div>
<img className="down-arrow-reject-acc" src={props.statuss === REJECTED_BY_STAFF ? downArrowGray :downArrowPink} alt="down-arrow" />

{/* <br></br>
<br></br>
<br></br> */}
           
           {/* <div className="rejectedborder" id="RejectWork">
            <p className={show==true?"setReject":"setnotReject"} id="rejectwork" onClick={()=> setShow(!show)}>{data?"REJECTED":"REJECT WORK"}</p>
           </div> */}
{/*           
           
           <br></br>
           <br></br> */}
        </>
    )
}

export default RejectAccordian;
import React, {useEffect, useState} from 'react';
import './navbar.scss';
import {pageNavigationByName} from 'service/helpers';
import {getNotificationsCout} from '../../../action/notification';
import {bindActionCreators} from 'redux';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getSettingsById, getStaffById} from 'action/SettingsAct';
import {logout} from 'service/utilities';
import DisplayImage from '../DisplayImage';
import nav_bell from '../../../assets/svg/mdi_notifications.svg';
import nav_bell_grey from '../../../assets/svg/notificationGrey.svg';
import nav_user from '../../../assets/svg/user.svg';
import logoutt from '../../../assets/svg/logout.svg';
import menu from '../../../assets/svg/menu.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import logo from '../../../assets/svg/Staff IQ.svg';

// let notificationCount = localStorage.getItem('notifications') !== null ? localStorage.getItem('notifications') : null;
function NavbarMobile(props) {

  const dispatch = useDispatch();
  const [count, setCount] = useState(null);
  const [, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [agencyInfo, setAgencyInfo] = useState(null);

  let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount);

  useEffect(()=>{
    setCount(notificationCount)
  },[notificationCount]);

  useEffect(() => {
    getNotifyCount();

    if ('userData' in localStorage) {
      let userData = JSON.parse(localStorage.getItem('userData'));
      let id = userData.id ? userData.id : userData.userId;
      let agencyId = userData.agencyId ? userData.agencyId : userData.id;

      let query = {
        id,
      };
      setLoading(true);
      dispatch(getStaffById(query))
        .then((res) => {
          if (res) {
            setUserInfo(res);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
        setLoading(true);
      
      dispatch(getSettingsById({id:agencyId}))
        .then((res) => {
          if (res) {
            setAgencyInfo(res);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }else logout();
  }, []);

  const getNotifyCount = async () => {
    setLoading(true);
    await props
      .getNotificationsCout({})
      .then((data) => {
        setCount(data.count);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  let isConnected = useSelector((state)=> state.webSocketReducer.isConnected)

  return (
    <React.Fragment>
      <div className="navbar fixed-top p-3">
        <div className="d-flex justify-content-end w-100 align-items-center">
          <div className="mr-4 d-flex align-items-center pr-3 nav-logo">
            <img src={menu} onClick={props.handleNav} className="mb-3 mr-3" alt="menu" width={21} height={15}/>
            {/* <img src={agencyInfo?.companyLogo} className="mb-3 logo-pic" style={{width:'auto', height:'35px'}} alt="logo"  /> */}
            {agencyInfo?.companyLogo ? <DisplayImage navbar={true} hideNA={true} imageId={agencyInfo?.companyLogo}/> : null}
            <p className="ml-3 text-violet  d-none d-lg-block d-xl-none">powered by Staff IQ</p>
          </div>
          <div className="mr-4 pr-3 position-relative  d-block d-lg-none">
            <img
              src={`${props.sideBarClickStatus?nav_bell:nav_bell_grey}`}
              height={20}
              width={20}
              alt="navBell"
              className="mb-3 cursor-pointer"
              onClick={() => {
                // pageNavigationByName({name: 'notification'});
                setCount(0);
                // localStorage.setItem('notifications', 0);
                props.handleSideBar();
              }}
            />
            <span className="badge my-badge">{count ? count : ''}</span>
          </div>
          <div className="mr-2 d-flex align-items-center position-relative pr-3">

            <OverlayTrigger placement="bottom" overlay={<Tooltip >{isConnected ? 'You are now connected to get updates instantly': 'You are now disconnected to get updates instantly, Please refresh'}</Tooltip>}>
              <div className='d-flex align-items-center  position-absolute' style={{bottom:'16px', left:'-7px'}}>
                <div className={`${isConnected ? 'connected': 'bg-danger'} cursor-pointer`} style={{borderRadius:'50%', minWidth:'15px', minHeight:'15px', border:'2px solid white'}}></div>
              </div>
            </OverlayTrigger>
            {/* <img
            alt="userInfo"
              src={
                userInfo ? userInfo.profileLink === 'http://localhost' ||
                userInfo.profileLink === '' ||
                userInfo.profileLink === null ? (
                  nav_user
                ) : (
                  userInfo.profileLink.split("|").length>1 &&
                  userInfo.profileLink.split("|")[1]
                ) : (
                  nav_user
                )
              }
              className="profile-nav rounded-circle mb-3"
            /> */}
            {userInfo?.profileLink ? userInfo.profileLink.includes('data:image/') ? <img
              alt="userLogo"
              src={
                userInfo ? userInfo?.profileLink === 'http://localhost' ||
                userInfo?.profileLink === '' || !userInfo.profileLink ||
                userInfo?.profileLink === null ? (
                  nav_user
                ) : (
                  userInfo?.profileLink.split("|").length>1 && userInfo?.profileLink.split("|")[1]
                ) : (
                  nav_user
                )
              }
              className="profile-nav rounded-circle mb-3"
            />  : <DisplayImage navbar={true} imageId={userInfo?.profileLink}/> : 
              <img
                alt="userLogo"
                src={nav_user}
                className="profile-nav rounded-circle mb-3"
            />
            }
            <p className="mx-2">{userInfo ? userInfo.firstname : ''}</p>
          </div>
          <div className="d-flex align-items-start mr-4 cursor-pointer" onClick={() => logout(true)}>
            <div className="vert-line mr-3" />
            <img className="" fetchPriority='high' src={logoutt} alt="user" height={17} width={17} />
            <p className="ml-3 mb-1 text-light-violet d-none d-lg-block d-xl-none">Logout</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotificationsCout,
    },
    dispatch,
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(NavbarMobile);

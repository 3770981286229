import React from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";

import closeButton from "assets/svg/recurence-close-btn.svg";
import recurrenceIcon from "assets/svg/recurrenceIcon.svg";
import radiobutton from "../../../assets/svg/radioButtonBg.svg"
import moment from "moment";

const dropdownOptions = [
  { label: "Day", value: 1 },
  { label: "Week", value: 2 },
  { label: "Month", value: 3 },
  { label: "Year", value: 4 },
];
const weekList = [
  { label: "S", value: 7 },
  { label: "M", value: 1 },
  { label: "T", value: 2 },
  { label: "W", value: 3 },
  { label: "T", value: 4 },
  { label: "F", value: 5 },
  { label: "S", value: 6 },
];
const RecurringModel = ({show,handleClose,repeatData}) => {


  return ( 
    <Modal
      show={show}
      onHide={handleClose}
      className="recurring-global-modal w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <ModalBody className="personalized-recurrence">
        <div className="rm-header">
          <div className="rm-header-info">
            <img src={recurrenceIcon} alt="Recurrence" />
            <span className="shift-recurrence-pattern">
              
              Shift Recurrence Pattern
            </span>
          </div>
          <div
            onClick={handleClose}
            aria-label="close-button"
            className="cursor-pointer"
          >
            <img   src={closeButton} alt="close-buton" />
          </div>
        </div>

        <div class="rm-body">
    <div class="rm-body-main">
      <div class="rm-repeat">
        <div class="rm-repeat-head">
          <div class="repeat">Repeat</div>
          <div class="rm-repeat-body">
            <div class="rm-repeat-body-info">
              <div class="every">Every</div>
              <div class="rm-repeatfrequency-label">
                <div class="week">{dropdownOptions[repeatData?.repeatFrequency -1]?.label}</div>
              </div>
            </div>
            {repeatData?.repeatFrequency === 3 && <div class="rm-repeat-body-info">
              <div class="every">Repeat On :</div>
              <div>
                <div class="week">{repeatData.repeatOn}</div>
              </div>
            </div>}
          </div>
        </div>  

        {repeatData?.repeatFrequency === 2 ?<div class="days">
        {
          weekList.map( (day , index) => { return (
            <div key={index} class = {`day-{${index}}`}> 
            {
              repeatData?.days?.includes(day.value)?  <div class="selected-day "> <div class={day.label.toLowerCase()}>{day.label} </div> </div> : <div class="normal-day "><div class ={`days-unactive ${day.label.toLowerCase()}`} >{day.label} </div></div>  
            }
            </div>)
          })
        }
        </div> : null}
      </div>


      <div class="rm-recurrence">
        <div class="recurrence-ends-on">Recurrence Ends On</div> 
        <div class="rm-endson-body"> 
        <div className="d-flex align-items-center pl-3">
          <img src={radiobutton}/>
          </div>
          <div class="rm-recurrence-info">
            {repeatData?.endsOn ? <div class="endsOn"> <div class="dateOn"> {moment(repeatData?.endsOn).format(`${localStorage.getItem('dateFormat')}`)} </div></div> : 
            <>
             <div class="after">After</div> 
            <div class="rm-recurrence-occurence">
              <div class="_14">{repeatData?.noOfOccurrences}</div>
            </div>
            <div class="occurrences">Occurrences</div></> } 
          </div>
        </div>
      </div>
    </div>
  </div>

      </ModalBody>
    </Modal> 
  );
};

export default RecurringModel;

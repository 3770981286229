import React from 'react';

export class PlainLayout extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row m-0">
          <div className="col-md-12 auth-layout d-flex align-items-center text-center">{this.props.children}</div>
        </div>
      </React.Fragment>
    );
  }
}

import React, {Component} from 'react';
import 'assets/scss/layouts/Auth.scss';
import logo from '../assets/images/Staff IQ.png';

export class AuthLayout extends Component {
  render() {
    let {children} = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6 auth-layout d-flex align-items-center text-center">
            <img src={logo} alt="Logo" />
          </div>
          <div className="col-md-6 ">
            {children}
            <div className="text-center login-footer-bottom ">
              <p>
                By continuing to use Staffatom you agree to our<br />
                <span className="font-weight-bold">Terms of use and Privacy policy</span>
              </p>
            </div>
          </div>
        </div>
        <style jsx>
          {`
            html {
              overflow-x: hidden;
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}

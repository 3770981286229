import React, { useEffect, useState, useRef } from "react";
import { NormalModal, CommonPageLoader } from "component/common";
import "./style.scss";
// import bagIcon from "assets/svg/bag.svg";
// import markerIcon from "assets/svg/Vector.svg";
import moment from "moment";
import {
  getWorkById,
  rejectWorkByStaff,
  acceptWorkByStaff,
  staffClockIn,
  holdWorkStaff,
  workInterestedStaff,
} from "action/workRequest";
import { updateTaskData } from "action/TimesheetAct";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useSelector, useDispatch } from "react-redux";
//import AcceptWorkModel from "../acceptWorkModel";
import TimeSheetMark from "../timeSheetMark";
import RecurringModel from "../RecurringModel";
import { getWorkStatus, getClassNameForBackground } from "service/utilities";
import {
  //SHIFT_IN_PROGRESS,
  //AGENCY_REVIEW_COMPLETED,
  HOLD_WORK,
  NEW_SHIFT_AVAILABLE,
  SHIFT_CONFIRMED_CLOCK_IN,
  //REJECTED_BY_CLIENT,
  NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM,
  REJECTED_BY_STAFF,
} from "service/constants";
import GoogleMaps from "../googleMaps";
import { getCurrentLocation } from "service/utilities/getCurrentLocation";
import InteractiveMap from "component/common/interactiveMap";
import { getServiceUrl } from "service/api";
import { documentDownloadFromMDB } from "service/apiVariables";
import {getStaffById} from 'action/SettingsAct';
import axios from "axios";
import RejectAccordian from "component/common/RejectAccordian/RejectAccordian";
import DisplayClientImage from "./DisplayClientImage/index.js"
import locationGreen from "assets/svg/location-green.svg"
import uilCalendarIcon from "assets/svg/uilCalendarIcon.svg"
import wrClockIcon from "assets/svg/wrClockIcon.svg"
import redFlagIcon from "assets/svg/redFlagIcon.svg"
// import locationBlueIcon from "assets/svg/location-blue.svg"
import claritymapmarker from "../../../assets/svg/clarity-map-marker.svg"
// import upArrowIcon from "assets/svg/up-arrow-pink.svg"
import locationGrayIcon from "assets/svg/location-gray.svg"
import downloadIcon from "assets/svg/download-icon.svg"
// import upArrowIconGray from "assets/svg/up-arrow-icon.svg"
// import downArrowIconBlue from "assets/svg/down-arrow-pink-new.svg"
import upArrowShrink from "assets/svg/up-arrow-shrink-pink.svg"
import downArrowShrink from "assets/svg/down-arrow-shrink-pink.svg"
import noImageIcon from "assets/svg/no-image-icon.svg"
import arrowDownScroll from "assets/svg/arrow-down-scroll.svg"
import mapclosebutton from "../../../assets/svg/map-close-button.svg"
import CustomLoader from "component/common/CustomLoader";
import { getFileFromMDB } from 'action/fileHandler';
import recurrenceIcon from "../../../assets/svg/recurrenceIcon.svg"
import ConfirmModel from "../ConfirmModel";
import LateClockInAlert from 'component/common/LateClockInAlert'
import startLocationTracking from "service/utilities/startLocationTracking";
// import { head } from "lodash";
// import { client } from "stompjs";
import { Toast } from "service/toast";
function WorkAssignment({
  workAssign,
  setWorkAssign,
  getWorkById,
  acceptWorkByStaff,
  rejectWorkByStaff,
  staffClockIn,
  // holdWorkStaff,
  updateTaskData,
  workInterestedStaff,
  handleReload,
  getFileFromMDB
}) {
  const [isLoading, setLoading] = useState(false);
  const [, setFormLoder] = useState(false);
  const [data, setEdit] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [mapActive, setMapActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [activePointsFromStaffDetails, setActivePointsFromStaffDetails] = useState('');
  const [staffId, setStaffId] = useState(
    localStorage.getItem("userData") != null
      ? JSON.parse(localStorage.getItem("userData")).id
      : ""
  );
  const [staffLongLat,setStaffLongLat]=useState();
  const [, setReject] = useState(false);
  const [rejectreason,setRejectreason] = useState({});
  const [rejectt,setRejectt] = useState([]);
  const [shrink, setShrink] = useState(true)
  const [shrinkMap, setShrinkMap] = useState(false)
  const [rejectAccShow, setRejectAccShow] = useState(null)
  const [distanceUnit,setDistanceUnit] = useState(null);
  const [acceptWorkLoading, setAcceptWorkLoading] = useState(false);
  const [clockInLoading, setClockInLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false)
  const [scheduleMeLoading, setScheduleMeLoading] = useState(false)
  const [onlineImage, setOnlineImage] = useState(null)
  const [recurring,setRecurring] = useState(false)
  const [scroll, setScroll] = useState(true)
  const [openConfirmModal,setOpenConfirmModal] = useState({visible:false,type:''})
  const [loadingKey, setLoadingKey] = useState('')
  const [openAlert, setOpenAlert] = useState(false);

  const dispatch = useDispatch();

  const bottomRef = useRef(null); 
  const topRef = useRef(null)

  const scrollToBottom = () => {
    if(scroll){ 
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });} 
    else {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    } 
    setScroll(!scroll)
  };

  const divRef = useRef(null);

    useEffect(() => {
      const handleKeyDown = (event) => {

        if (divRef.current && divRef.current !== document.activeElement && document.activeElement.tagName !== "INPUT") {
          divRef.current.focus();
        }

        if (divRef.current && event.keyCode === 38) {
          divRef.current.scrollTop -= 40;
        } else if (divRef.current && event.keyCode === 40) {
          divRef.current.scrollTop += 40;
        }
      };
      document.addEventListener('keydown', handleKeyDown, {passive: true});
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);


     // websocket implementation

 let workRequestData = useSelector((state)=> state.webSocketReducer.workRequest)

 useEffect(()=>{
  if(workRequestData){
    if (workAssign.id) {
      let workRequestId =  workRequestData?._id ? workRequestData?._id : workRequestData?.id
      if(workAssign.id === workRequestId){
        handleUpdateWorkRequest()
      }
    }
  }
  
 },[workRequestData])

 const handleUpdateWorkRequest = () => {
  setEdit(workRequestData)
 }

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let id = localStorage.getItem("userData") != null ? userData.id : null;
    setStaffId(id);
    if (workAssign.id) {
      fetchWorkById(workAssign.id);
    }

    // Disabling map click event when changing work
    setMapActive(false);
      dispatch(getStaffById({id}))
        .then((res) => {
      setStaffLongLat(res.longLat)
          setDistanceUnit(res.distanceUnit);
        })
        .catch((e) => console.log(e));
  }, [workAssign]);

  const fetchFileDownloadURLById = async (id, filename='none.png', type="image/png") => {
    setLoading(true);
    
    if(filename.split(".").length>1){
        filename = filename.split(".")[0];
    }

    const link = document.createElement("a");
    link.target = "_blank";
    link.download = filename


    // await
    // fileDownloadFromMDB({ 'id': id })
    await axios
        .get(`${getServiceUrl(documentDownloadFromMDB.create.baseURL)}${documentDownloadFromMDB.create.api}${id}/`, {
            responseType: "blob",
            headers : {
                Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
            }
        })
        .then((res) => {

                link.href = URL.createObjectURL(
                new Blob([res.data], { type: type })
                );
                link.click();
            
            setLoading(false);
        })
        .catch((e) => setLoading(false));
    };

  const handleAction = (action) => {
    if(action.type === 'accept-work'){
    if(action.value) {setAcceptWorkLoading(true)
     handleAcceptWork();} 
    else {setOpenConfirmModal({...openConfirmModal,visible:false})}
  } 
  if(action.type === 'pick-work'){ 
    if(action.value){handleInterest()} 
    else {setOpenConfirmModal({...openConfirmModal,visible:false})}
  }
  if(action.type === 'clockin'){ 
    if(action.value){handleCheckIn()} 
    else {setOpenConfirmModal({type:'',visible:false})}
  } 
  if(action === true){
    {
      setAcceptWorkLoading(true)
       handleAcceptWork();}   
  }
 }

  const fetchWorkById = async (id) => {
    setLoading(true);
    const query = { id: id };
    await getWorkById(query)
      .then((data) => {
        setEdit(data);
        updateTaskData(data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  const handleAcceptWork = async (id) => {
    if(acceptWorkLoading) {
      return;
    } 
    setLoadingKey('accept-work')
    let body = {
      workId: workAssign.id,
      staffId:staffId,
      clientId:data.clientId
    };
    await acceptWorkByStaff(body)
      .then((data) => {
        setOpenModal(!openModal);
        // setWorkAssign({value: true, id: id});
        setDefault();
        setLoadingKey('')
      })
      .catch(() => {
        setOpenModal(!openModal);
        setDefault();
        setLoadingKey('')

      }).finally(() => {
        setOpenConfirmModal({visible:false,type:''})
        setAcceptWorkLoading(false);
      });
  };

  const handleRejectStaff = async (id) => {
    setSubmitLoading(true)
    let body = {
      workId: workAssign.id,
      staffId: staffId,
      rejectReasons: rejectreason[0].newchecked,
      additionalComments: rejectreason[0].texarea,
      contactEnabled: rejectreason[0].contact,
    };
    await rejectWorkByStaff(body)
      .then((data) => {
        setReject(true);
        setDefault();
      })
      .catch(() => {
        setFormLoder(false);
        setDefault();
      }).finally(() => {
        setSubmitLoading(false)
      });
  };
  const handleInterest = async (id, clientId) => {
    setLoadingKey('pick-work')
    setScheduleMeLoading(true)
    let body = {
      workId: workAssign.id,
      staffId: staffId,
      clientId: data.clientId
    };
    await workInterestedStaff(body)
      .then((data) => {
        setDefault();
        setLoadingKey('')
      })
      .catch(() => {
        setFormLoder(false);
        setDefault();
        setLoadingKey('')
      }).finally(() => {
        setOpenConfirmModal({visible:false,type:''})
        setScheduleMeLoading(false)
      });
  };

  /* this feature is temporarily put on hold and needs to be uncommented when hold work feature is planned again */

  /*const handleHoldStaff = async (id) => {
    let body = {
      workId: workAssign.id,
      staffId: staffId,
    };
    await holdWorkStaff(body)
      .then((data) => {
        setDefault();
      })
      .catch(() => {
        setFormLoder(false);
        setDefault();
      });
  };*/

  const setDefault = () => {
    setWorkAssign({ value: false });
    setRejectAccShow(false)
    // handleReload();
  };

  const getrejectAccordiandata = (data)=>{
    setRejectreason([data]);    
}
  
useEffect(()=>{
    
  if(rejectreason.length>0){
  handleRejectStaff(rejectreason[0].id)}
},[rejectreason])


  const handleCheckIn = async () => {
    let startDateTime = moment(data.startDate)
    let currentDateTime = moment();
    let differenceTime = currentDateTime.diff(startDateTime,'minutes')
    if(differenceTime >= process.env.REACT_APP_LATE_CLOCKIN_TIMELIMIT){
      setOpenAlert(!openAlert)
    }
    else{
    setClockInLoading(true)
    setLoadingKey('clockin');
    let currrentLocation = await getCurrentLocation();

    let clockInLongLat;
    if (currrentLocation.status) { 
      clockInLongLat = [currrentLocation.longitude,currrentLocation.latitude]
    } else {
      // let staffId = JSON.parse(localStorage.getItem("userData"))?.id;
      // let staff_data = data?.workStaffAssignment?.staffWorkData?.find(el => el?.staffId?.toString() === staffId?.toString());
      clockInLongLat = (data?.longLat?.length) ? [...data.longLat] : [];
    }

    let body = {
      workId: workAssign.id,
      clockInTime: moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"),
      clockInLongLat,
      clientSiteTimeZone: data.clientSiteTimeZone,
      clientId:data.clientId,
      geoHistogram:{
        longLat:clockInLongLat,
        timeStamp:moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"),
        type:1
    },
    }

    await staffClockIn(body)
      .then((res) => {
        if(res){
          let user = JSON.parse(localStorage.getItem('userData'))
          let geoUpdateInterval = user?.geoUpdateInterval
          let enableGeoTracking = user?.enableGeoTracking
          if(enableGeoTracking){
            startLocationTracking({
              id:res,
              staffId:user?.id,
              type:5,
              agencyId:data?.agencyId,
              timeZone:data?.clientSiteTimeZone
            },
            Number(geoUpdateInterval) > 0 ? geoUpdateInterval * 1000 : 60000
            )
          }
        }
        setWorkAssign({ value: false });
        // handleReload();
        setWorkAssign({ value: true, id: workAssign.id });
        setLoadingKey('');
      })
      .catch(() => {
        setOpenModal(false);
        setDefault();
        setLoadingKey('');
      }).finally(() => {
        setOpenConfirmModal({visible:false,type:''});
        setClockInLoading(false);
      });
    }
 
    // let body = {
    //   workId: workAssign.id,
    //   clockInTime: moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"),
    //   latLong: [coordinates().LONGITUDE, coordinates().LATITUDE],
    // };

    // await staffClockIn(body)
    //   .then((data) => {
    //     // setOpenModal(false);
    //     // setDefault();
    //     setWorkAssign({ value: false });
    //     handleReload();
    //     setWorkAssign({ value: true, id: workAssign.id });
    //   })
    //   .catch(() => {
    //     setOpenModal(false);
    //     setDefault();
    //   });
  };
  let statuss = getWorkStatus(
    data.status,
      data?.workStaffAssignment?.staffWorkData?.length > 0 &&
      data.workStaffAssignment.staffWorkData.filter(
          (staff) =>
            staff.staffId === JSON.parse(localStorage.getItem("userData")).id
        ).length > 0 ?
          data.workStaffAssignment.staffWorkData.filter(
            (staff) =>
              staff.staffId === JSON.parse(localStorage.getItem("userData")).id
          )[0].status
      : -1,
      data?.workStaffAssignment?.staffWorkData?.length > 0
      ? data.workStaffAssignment.staffWorkData.filter(
          (staff) =>
            staff.staffId === JSON.parse(localStorage.getItem("userData")).id
        ).length > 0 &&
          data.workStaffAssignment.staffWorkData.filter(
            (staff) =>
              staff.staffId === JSON.parse(localStorage.getItem("userData")).id
          )[0].clockIn
      : undefined,
      data?.workStaffAssignment?.staffWorkData?.length > 0
      ? data.workStaffAssignment.staffWorkData.filter(
          (staff) =>
            staff.staffId === JSON.parse(localStorage.getItem("userData")).id
        ).length > 0 &&
          data.workStaffAssignment.staffWorkData.filter(
            (staff) =>
              staff.staffId === JSON.parse(localStorage.getItem("userData")).id
          )[0].clockOut
      : undefined,
      data?.workStaffAssignment?.interestedStaff?.length > 0 &&
      data.workStaffAssignment.interestedStaff.includes(
        localStorage.getItem("userData") !== null
          ? JSON.parse(localStorage.getItem("userData")).id
          : null
      ),
      data?.workStaffAssignment?.staffWorkData?.length > 0
      ? data.workStaffAssignment.staffWorkData.filter(
          (staff) =>
            staff.staffId === JSON.parse(localStorage.getItem("userData")).id
        ).length > 0 &&
          data.workStaffAssignment.staffWorkData.filter(
            (staff) =>
              staff.staffId === JSON.parse(localStorage.getItem("userData")).id
          )[0].holdWork
      : false,
      data?.workStaffAssignment?.rejectedStaff?.length > 0 &&
      data.workStaffAssignment.rejectedStaff.filter(
        (rejected) =>
          rejected.staffId === JSON.parse(localStorage.getItem("userData")).id
      ).length > 0,
      data?.workStaffAssignment?.rejectedStaff?.length > 0 &&
      data.workStaffAssignment.rejectedStaff.filter(
        (rejected) =>
          rejected.staffId === JSON.parse(localStorage.getItem("userData")).id
      ).length > 0 &&
      data.workStaffAssignment.rejectedStaff.filter(
        (rejected) =>
          rejected.staffId === JSON.parse(localStorage.getItem("userData")).id
      )[data.workStaffAssignment.rejectedStaff.filter(
        (rejected) =>
          rejected.staffId === JSON.parse(localStorage.getItem("userData")).id
      ).length - 1]?.userType
  );


  useEffect(()=>{
    let rejectdata=[];
    let count=0;
    let rejectedreason=[];
    for(let i=0;i<data?.workStaffAssignment?.rejectedStaff?.length;i++){
      if(staffId== data?.workStaffAssignment?.rejectedStaff[i].staffId && data?.workStaffAssignment?.rejectedStaff[i].userType == 6){
        rejectedreason.push(data.workStaffAssignment.rejectedStaff[i])
        count++;
      }
    }
    if(count>0){
      rejectdata.push({value:true, reject: rejectedreason[rejectedreason.length-1]})
    }
    else{
      rejectdata.push({value:false})
    }

    setRejectt(rejectdata)
  },[data]);

  useEffect(() => {
    if(data?.status >= 4) {
      setShrinkMap(true)
      setShrink(true)
    } else {
      setShrinkMap(false)
    }
  }, [data?.status])

  const handleImageDownload = async (sitePicture) => {
    setLoading(true)
      let imageData;
       let fileId = sitePicture.split('/')[1]
       await getFileFromMDB({entity: 'client', clientId: data.clientId}, fileId)
       .then((response)=>{
        imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
        setLoading(false)
        setOnlineImage(imageData.toString())
        return imageData
       }).catch((error) => {
          setLoading(false);
          setOnlineImage(null);
          console.log('Error fetching image:', error);
        });
    }

    useEffect(()=>{
      if(data.sitePicture){
        handleImageDownload(data.sitePicture)
      }else{
        setOnlineImage(null)
      }
    },[data.sitePicture])

    const clockInCheck = () => {
      let currentDate = moment();
      let wrStartDate = moment(data.startDate)
      if(wrStartDate.diff(currentDate,'minutes') > 0 && wrStartDate.diff(currentDate,'minutes') <= 60) {
        setOpenConfirmModal({visible:true,type:'clockin'})
      }
      else if(wrStartDate.diff(currentDate,'minutes') <= 0) {
        handleCheckIn()
      }
      else {
        Toast({ type: "error", message: "Sorry! You are too early to clock-in. Please try later or get your shift time changed." });
      }
    }

  return (
    <React.Fragment>
      <InteractiveMap 
          open={open} setOpen={setOpen} 
          activePointsFromStaffDetails={activePointsFromStaffDetails} 
          data={data?.workStaffAssignment?.staffWorkData} 
          sitePicture={onlineImage ? onlineImage : ""} 
          clientLocLatLong={data?.longLat}
      />
      <NormalModal
        className={"modal-fill"}
        isOpen={workAssign.value}
        size="lg"
        onRefresh={()=>{
          let userData = JSON.parse(localStorage.getItem("userData"));
          let id = localStorage.getItem("userData") != null ? userData.id : null;
          setStaffId(id);
          if (workAssign.id) {
            fetchWorkById(workAssign.id);
            }
          }}
        toggle={setDefault}
        renderHeader={(e) => (
          <div className=" d-flex justify-content-between">
            <h6 className="h1-sb header-title">Work Schedule Details</h6>
          </div>
        )}
        bodyClass="add-staff-box scrollable-content"
        headerClass="border-0 wr-header pt-3 px-4"
      >
        {!isLoading ? (
          // <React.Fragment>
          //   <div className="wr-box-container" ref={divRef} tabIndex={0} style={{ outline: 'none' }}>
          //         <div className="wr-id-cont sticky-header">
          //             <div className="wr-id">
          //               WR ID - {`${!!data._id && data._id.substr(data._id.length - 6)}`}{" "}
          //             </div>
          //             <div className="wr-status">
          //                 <div style={{width: "15px", height: "15px"}} className={"color-box "+getClassNameForBackground(statuss)}></div>
          //                 <div className={"color-text"}>{statuss}</div>
          //             </div>
          //           </div>
          //         {/* Sticky Scroll to Bottom Button */}
          //         <img  className="sticky-scroll-btn cursor-pointer" src={arrowDownScroll} onClick={scrollToBottom} alt="arrow-down" />
          //         {/* Work Request Start and End Time*/}
          //           <div className="frame-1833">
          //           <div className="frame-1831">
          //             <div className="frame-1089">
          //               <div className="ellipse-74"></div>

          //                 <img className="material-symbols-location-on-1" src={locationGreen} alt="location" />
          //             </div>

          //             <div className="frame-1829">
          //               <div className="starts">Starts</div>

          //               <div className="frame-1828">
          //                 <div className="frame-1827">
          //                   <img className="uil-calender" src={uilCalendarIcon} alt="calendar" />
          //                   <div className="_27-02-2023">{moment(data.startDate).format(`${localStorage.getItem('dateFormat')}`)}</div>
          //                 </div>

          //                 <div className="frame-1826">
          //                     <img className="ic-sharp-access-time" src={wrClockIcon} alt="clock" />
          //                   <div className="_03-15-00-pm">{moment(data.startDate).format("hh:mm:ss a")}</div>
          //                 </div>
          //               </div>
          //             </div>
          //           </div>

          //           <div className="frame-18332">
          //             <div className="frame-1090">
          //               <div className="ellipse-742"></div>
          //               <img className="frame-2439" src={redFlagIcon} alt="refd-flag" />
          //             </div>

          //             <div className="frame-1829">
          //               <div className="ends">Ends</div>

          //               <div className="frame-1828">
          //                 <div className="frame-1827">
          //                   <img className="uil-calender" src={uilCalendarIcon} alt="calendar" /> 
          //                   <div className="_27-02-2023">{moment(data.endDate).format(`${localStorage.getItem('dateFormat')}`)}</div>
          //                 </div>

          //                 <div className="frame-1826">
          //                   <img className="ic-sharp-access-time" src={wrClockIcon} alt="clock" />
          //                   <div className="_03-15-00-pm">{moment(data.endDate).format("hh:mm:ss a")}</div>
          //                 </div>
          //               </div>
          //             </div>
          //           </div>
          //           <div className="line-77"></div>
          //         </div>
                  
          //         {/* Client Name and Site Map */}
          //         <div className="frame-2468">
          //           <div className="frame-2326">
          //             <div className="frame-2200">
          //               <div className="client-name">Client Name</div>
          //             </div>

          //             <div className="frame-2185">
          //               <div
          //                 className="national-institute-of-health-and-care-excellence-nice-hospital"
          //               >
          //                 {data.clientName}
          //               </div>
          //             </div>
          //           </div>

          //           <div className="frame-2327">
          //           {/* <img className="image-22" alt="site-picture" src={data.sitePicture ? data.sitePicture.split('|')[1] : noImageIcon} /> */}
          //           {
          //             data.sitePicture ? 
          //               data.sitePicture.includes('data:image/') ?
          //               <img className="image-22" alt="site-picture" src={data.sitePicture ? data.sitePicture.split('|')[1] : noImageIcon} />
          //               : <DisplayClientImage entity="client" clientId={data.clientId} imageId={data.sitePicture ? data.sitePicture : ''}/>
          //             : 
          //             <img className="image-22" alt="site-picture" src={noImageIcon} />
          //           }
          //           </div>

          //           <div className="frame-2466">
          //             <div className="rectangle-400"></div>
          //             <img className="vector-1" src={locationBlueIcon} alt="location" />
          //             <div className="pa-site-2" data-info={data.location}>{data.location ? data.location.split(',')[0].trim().substring(0, 7) + '...' : ''}
          //             </div>
          //             {!shrinkMap ? 
          //               <img onClick={() => setShrinkMap(!shrinkMap)} className="bxs-up-arrow cursor-pointer" src={upArrowIcon} alt="up-arrow" /> : 
          //               <img onClick={() => setShrinkMap(!shrinkMap)} className="bxs-up-arrow cursor-pointer" src={downArrowIconBlue} alt="down-arrow" />
          //             }
          //           </div>
          //         </div>
          //         {/* <div className="col-11">
          //           <div className="form-group">
          //             <p className="sub-heading fs-14">Job Function - {data.jobCategory} - {data.jobName} </p>
          //           </div>
          //           <div className="form-group">
          //             <p className="sub-heading fs-14"> Work Request Priority - {data.priority}</p>
          //           </div>
          //         </div> */}

          //       {/* Map */}
          //       {!shrinkMap ?
          //       <>
          //         <img className="polygon-16" src={upArrowIconGray} alt="up-arrow" />
          //         <div className="map-main">
          //           {!!data.longLat && (
          //             <div onClick={() => setMapActive(true)}>
          //               <div style={{ pointerEvents: mapActive ? "auto" : "none" }}>
          //                 <GoogleMaps
          //                   lat1={data.longLat[1]}
          //                   lan1={data.longLat[0]}
          //                   sitePicture={onlineImage ? onlineImage : ""}
          //                   unit={distanceUnit}
          //                   //first approach
          //                   // lat2={localStorage.getItem("isPick") ? JSON.parse(localStorage.getItem("userData")).longLat[1] :
          //                   // data.workStaffAssignment.staffWorkData &&
          //                   //   data.workStaffAssignment.staffWorkData.length > 0 &&
          //                   //   data.workStaffAssignment.staffWorkData.filter(
          //                   //     (staff) =>
          //                   //       staff.staffId ===
          //                   //       JSON.parse(localStorage.getItem("userData")).id
          //                   //   ).length > 0 &&
          //                   //   data.workStaffAssignment.staffWorkData &&
          //                   //   data.workStaffAssignment.staffWorkData.length > 0 &&
          //                   //   data.workStaffAssignment.staffWorkData.filter(
          //                   //     (staff) =>
          //                   //       staff.staffId ===
          //                   //       JSON.parse(localStorage.getItem("userData")).id
          //                   //   )[0].longLat[0]}
          //                   // lan2={localStorage.getItem("isPick") ? JSON.parse(localStorage.getItem("userData")).longLat[0] : 
          //                   // data.workStaffAssignment.staffWorkData &&
          //                   // data.workStaffAssignment.staffWorkData.length > 0 &&
          //                   // data.workStaffAssignment.staffWorkData.filter(
          //                   //   (staff) =>
          //                   //     staff.staffId ===
          //                   //     JSON.parse(localStorage.getItem("userData")).id
          //                   // ).length > 0 &&
          //                   // data.workStaffAssignment.staffWorkData &&
          //                   // data.workStaffAssignment.staffWorkData.length > 0 &&
          //                   // data.workStaffAssignment.staffWorkData.filter(
          //                   //   (staff) =>
          //                   //     staff.staffId ===
          //                   //     JSON.parse(localStorage.getItem("userData")).id
          //                   // )[0].longLat[1]}
          //                   //2nd approach localstorage
          //                   // lat2={JSON.parse(localStorage.getItem("userData")).longLat[1] }
          //                   // lan2={JSON.parse(localStorage.getItem("userData")).longLat[0] }
          //                   // 3rd approach redux approach
          //                   lat2={staffLongLat?.[1]}
          //                   lan2={staffLongLat?.[0]}
          //                 />
          //               </div>
          //             </div>
          //           )}
          //         </div>
          //         <div className="frame-2333">
          //           <img className="material-symbols-location-on" src={locationGrayIcon} alt="location-gray" />
          //           <div
          //             className="willow-staffing-agency-old-route-119-highway-north-indiana-pa-usa"
          //           >
          //             {data.location}
          //           </div>
          //       </div>
          //       </>
          //       : null}
                
          //     {/* Job Details */}
          //     <div className="d-flex flex-row job-details-cont">
          //       <div className="frame-2177">
          //           <div className="frame-1080">
          //             <div className="category">Category</div>

          //             <div className="frame-2171">
          //               <div className="nurse">{data.jobCategory}</div>
          //             </div>
          //           </div>

          //           <div className="frame-1079">
          //             <div className="job-name">Job Name</div>

          //             <div className="frame-2171">
          //               <div className="certified-nursing-assistant-cna">
          //                 {data.jobName}
          //               </div>
          //             </div>
          //           </div>
          //         </div>

          //         <div className="frame-2176">
          //           <div className="frame-1081">
          //             <div className="engagement">Engagement</div>

          //             <div className="frame-2171-2">
          //               <div className="contract">{data.engagementType == "1" ? "Contract" : data.engagementType == "2" ? "Regular" : ""}</div>
          //             </div>
          //           </div>

          //           <div className="frame-1079">
          //             <div className="priority">Priority</div>

          //             <div className="frame-2172">
          //               <div className="normal">{data.priority}</div>
          //             </div>
          //           </div>

          //           <div className="frame-1080">
          //             <div className="type">Type</div>

          //             <div className="frame-2171-2">
          //               <div className="weekday">{data.wrType == "1" ? "Weedkay" : data.wrType == "2" ? "Weekend" : data.wrType == "3" ? "Holiday" : ""}</div>
          //             </div>
          //           </div>
          //         </div>
          //     </div>

          //           {/* New Addtional Details */}

          //           {/* Client Notes */}
          //           <div className="frame-2344">
          //             <div className="frame-2343">
          //               <div className="frame-2342">
          //                 <div className="frame-2340">
          //                   <div className="frame-2339">
          //                     <div className="client-notes">Client Notes</div>
          //                   </div>
          //                 </div>

          //                 <div className="frame-2341">
          //                   <div className="frame-23403">
          //                     <div className="please-refer-the-staff-with-proper-experience">
          //                       {data.notes ? data.notes : "N/A"}
          //                       </div>
          //                     </div>
          //                   </div>
          //                 </div>
                        

          //           {/* Attached Documents */}
          //           { !shrink && <>
          //           <div className="frame-2342">
          //           <div className="frame-2340">
          //             <div className="frame-2339">
          //               <div className="client-notes">Attached Documents</div>
          //             </div>
          //           </div>

          //           <div className="frame-2341">
          //             <div className="frame-23402">
          //               <div className="please-refer-the-staff-with-proper-experience">
          //               <div className="_6665111-image-png">
          //                 {!data.documents || data.documents.length === 0 ? (
          //                 <span style={{color: !data.documents || data.documents.length === 0 && "black"}}>No Attached Documents</span>
          //                 ) : (
          //                   data.documents.map((doc, i) => (
          //                   <a 
          //                   key={i}
          //                   type="button"
          //                   className="fs-13 document-links"
          //                   onClick={() => {
          //                     const docData = doc.split("|");
          //                     if (docData.length > 1) 
          //                     {
          //                       fetchFileDownloadURLById(docData[0], docData[1], docData[2]);
          //                     }
          //                   }}>
          //                     {doc.split("|").length > 1 && doc.split("|")[1].length > 35 ? (
          //                     <>{'...' + doc.split("|")[1].substring(doc.split("|")[1].length - 35)}</>
          //                     ) : (
          //                     <>{doc.split("|")[1]}</>
          //                     )}
          //                     ({i + 1})
          //                     {data.documents && data.documents.length > 0 && (
          //                       <img className="download ml-2" src={downloadIcon} alt="download" />
          //                       )}
          //                     </a>
          //                     ))
          //                     )}
                              
          //                 </div>
          //               </div>
          //             </div>
          //           </div>
          //           </div>

          //           {/* Work Request Title */}
          //           <div className="frame-2342">
          //           <div className="frame-2340">
          //             <div className="frame-2339">
          //               <div className="client-notes">Work Request Title</div>
          //             </div>
          //           </div>

          //         <div className="frame-2341">
          //           <div className="frame-23402">
          //             <div className="please-refer-the-staff-with-proper-experience">
          //               {data.workRequest?data.workRequest:"N/A"}
          //             </div>
          //           </div>
          //         </div>
          //       </div>

          //       {/* Created At */}
          //       <div className="frame-2342">
          //           <div className="frame-2340">
          //             <div className="frame-2339">
          //               <div className="client-notes">Created Date</div>
          //             </div>
          //           </div>

          //         <div className="frame-2341">
          //           <div className="frame-23402">
          //             <div className="please-refer-the-staff-with-proper-experience">
          //               {data.createdAt?(moment(data.createdAt).format(`${localStorage.getItem('dateFormat')}`))+' - '+ (moment(data.createdAt).format("hh:mm:ss a")):"N/A"}
          //             </div>
          //           </div>
          //         </div>
          //       </div>

          //           </>}
          //             </div>
          //           </div>

          //             <div className="rectangle-325 d-flex justify-content-between">
          //               <span style={{    position: "relative",
          //                 top: "60px",
          //                 left: "5px",
          //                 color: "white"}}>Additional Details</span>
          //               <span className="cursor-pointer" onClick={() => setShrink(!shrink)} style={{    position: "relative",
          //                 top: "60px",
          //                 right: "5px",
          //                 color: "white"}}>{shrink ? "More" : "Shrink"}
          //                 <img src={shrink ? downArrowShrink : upArrowShrink} alt="arrow" />
          //                 </span>
          //             </div>

          //       {data.status >= 2 || (data.status === 0 && data.workStaffAssignment?.staffWorkData[0]?.clockIn
          //       )? (
          //         <TimeSheetMark
          //           data={data}
          //           setOpen={setOpen}
          //           setWorkAssign={setWorkAssign}
          //           setActivePointsFromStaffDetails={setActivePointsFromStaffDetails}
          //           handleReload={() => {
          //             setDefault();
          //             handleReload();
          //           }}
          //         />
          //       ) : (
          //         ""
          //       )}
          //         {data && data.status >= 4 && data.clientCompletedDate?
          //             (
          //           <div className="row align-items-center justify-content-end">
          //             <div className="col-5">
          //               <div className="form-group">
          //                 <p className="sub-text fs-13">Time Sheet Approval By Client</p>
          //               </div>
          //             </div>      
          //             <div className="col-6">
          //               <div className="form-group">
          //                 <p className="text-black fs-11 ">
          //                   {" "}
          //                   {data && data.status >= 4 && data.clientCompletedDate
          //                     ? "Approved on " +
          //                       moment(data.clientCompletedDate).format(
          //                         `${localStorage.getItem('dateFormat')} hh:mm:ss a`
          //                       )
          //                     : "-"}{" "}
          //                 </p>
          //               </div>
          //             </div>
          //           </div>
          //         ):""
          //         }
          //       {statuss === NEW_SHIFT_AVAILABLE ? (
          //         // <div className="d-flex align-items-center justify-content-end mt-3">
          //         //     <NormalButton
          //         //       onClick={() => {
          //         //         handleInterest(data.id, data.clientId);
          //         //       }}
          //         //       label="SCHEDULE ME!"
          //         //       outline={false}
          //         //       className="mb-2 btn fs-10 btn-block btn-dark-primary schedule-btn"
          //         //     />
          //         // </div>
          //         <div className="sticky-footer schedule-me-btn">
          //           <div style={{opacity: scheduleMeLoading ? 0.5 : 1, cursor: scheduleMeLoading ? "not-allowed" : "pointer"}} className="frame-2274-2 cursor-pointer" id="scheduleMe" onClick={() => {
          //                 if(!scheduleMeLoading) {
          //                   handleInterest(data.id, data.clientId);
          //                 }
          //               }}>
          //             <div className="schedule-me d-flex align-items-center">SCHEDULE ME {scheduleMeLoading ? <CustomLoader color="white"/> : null}</div>
          //           </div>
          //         </div>
          //       ) : (
          //         ""
          //       )}
          //       {statuss === NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM || statuss === HOLD_WORK || statuss === REJECTED_BY_STAFF ? (
          //         <React.Fragment>
          //               {/* <NormalButton
          //                 // onClick={() => {
          //                 //   handleRejectStaff(data.id);
          //                 // }}
          //                 label="Reject Work"
          //                 id="rejectWork"
          //                 outline={false}
          //                 className="btn fs-10 btn-block btn-danger mt-1"
          //                 disabled={statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK}
          //               /> */}
                          

          //             {statuss === NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && <div className="d-flex align-items-end mt-3 justify-content-end ">
          //               {/* <NormalButton
          //                 onClick={() => {
          //                   handleHoldStaff(data.id);
          //                 }}
          //                 label="Hold work"
          //                 id="holdWork"
          //                 outline={false}
          //                 disabled={statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM}
          //                 className="mb-2 btn fs-14 btn-block  btn-dark-primary "
          //               /> */}
          //             </div>}
          //             {/* Old Accept Work Button */}
          //             {/* <div className="d-flex align-items-center mt-3 justify-content-between">
          //               <NormalButton
          //                 onClick={() => {
          //                   //setOpenModal(true);
          //                   handleAction(true);
          //                 }}
          //                 label="Accept work"
          //                 id="acceptWork"
          //                 outline={false}
          //                 disabled={statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK}
          //                 className="btn fs-10 btn-block btn-success mb-2 setacceptbtn"
          //               />
          //             </div> */}
          //             {rejectAccShow === true ? <RejectAccordian id={data.id} submitLoading={submitLoading} onSubmit={getrejectAccordiandata} statuss={statuss} data={rejectt.length>0 && rejectt[0].value==true?rejectt[0].reject:""} /> : null}
          //             <div className="d-flex flex-row sticky-footer accept-reject-cont" id="rejectWork">
          //               <div className="frame-2273 cursor-pointer" onClick={() => {
          //                     setRejectAccShow(!rejectAccShow);
          //                     setTimeout(() => {
          //                       scrollToBottom();
          //                     }, 100); 
          //                   }}>
          //                 <div className="reject-work cursor-pointer">{statuss === REJECTED_BY_STAFF ? "Rejected" : "Reject Work"}</div>
          //               </div>
          //               <div className="frame-2274 cursor-pointer" id="acceptWork"  onClick={() => {
          //                   //setOpenModal(true);
          //                   if(statuss === NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM) {
          //                     handleAction(true);
          //                   }
          //                 }}
          //                 style={{background: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK && "#e4e4e4", cursor: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK || acceptWorkLoading && "not-allowed", opacity: acceptWorkLoading ? 0.5 : 1, width: acceptWorkLoading && "173px", 
          //                 }}>
          //                 <div className="accept-work d-flex align-items-center" style={{color: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK && "#808080", cursor: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK && "not-allowed", width: acceptWorkLoading && "100%"}}>Accept Work {acceptWorkLoading ? <CustomLoader color="white"/> : null}</div>
          //               </div>
          //             </div>
          //           {/* <br></br>
          //       <br></br>
          //       <br></br>
          //       <br></br> */}
          //         </React.Fragment>
                  
          //       ) : (
          //         ""
          //       )}
          //       {statuss === SHIFT_CONFIRMED_CLOCK_IN ? (
          //         // <div className="d-flex align-items-center justify-content-end py-3">
          //         //   <NormalButton
          //         //     onClick={() => {
          //         //       handleCheckIn();
          //         //     }}
          //         //     label="clock - In"
          //         //     id="clockIn"
          //         //     outline={false}
          //         //     className=" btn fs-10 text-white bg-c19 font-weight-bold"
          //         //   />
          //         // </div>
          //             <div className="sticky-footer">
          //               <div style={{
          //                 opacity: clockInLoading ? 0.5 : 1,
          //                 cursor: clockInLoading && "not-allowed"
          //               }} 
          //               className="frame-2286 cursor-pointer" id="clockIn" onClick={() => {
          //                 if(!clockInLoading) {
          //                   handleCheckIn();
          //                 }
          //                 }}>
          //                   <div style={{width: clockInLoading && "140px"}} className="frame-2285">
          //                     <div className="clock-in d-flex align-items-center">Clock-In {clockInLoading ? <CustomLoader color="white" /> : null}</div>
          //                 </div>
          //               </div>
          //             </div>
          //       ) : (
          //         ""
          //       )}
          //    </div>
          // </React.Fragment> 
          <React.Fragment>
          <div className="wr-box-container" ref={divRef} tabIndex={0} style={{ outline: 'none' }}> 
          <div id="top" ref={topRef}>
          </div>
            <div className="wr-id-cont sticky-header">
              <div className="wrid">
                <div className="wrid2">
                  <div className="wrm h2-sb">WR</div> 
                </div>
                 
                <div className="id num-sb">{`${!!data._id && data._id.substr(data._id.length - 6)}`}{" "}</div>
              </div> 
              <div className="wrstatus-wr-box"> 
              <div className="wrstatus2"> 
              <div className={"rectangle-304 "+getClassNameForBackground(statuss)}></div>
      <div class=" pending-staff-to-submit-timesheet">
      {statuss}
      </div>
      </div> 
      </div>
            </div> 
              {/* Sticky Scroll to Bottom Button */}
              <img  className="sticky-scroll-btn cursor-pointer" src={arrowDownScroll}  style={{ transform: `rotate(${!scroll ? 180 : 0}deg)` }}onClick={scrollToBottom} alt="arrow-down" /> 
          <div className=""> 
          <div className="clienttime">
            <div className="client-new">
              <div className="client1">
                <div className="client1details"> 
                <div className="client1details1"> 
                <div className="clientlogo">
                {
                    data.sitePicture ? 
                      data.sitePicture.includes('data:image/') ?
                      <img className="image-22" alt="site-picture" src={data.sitePicture ? data.sitePicture.split('|')[1] : noImageIcon} />
                      : <DisplayClientImage entity="client" clientId={data.clientId} imageId={data.sitePicture ? data.sitePicture : ''}/>
                    : 
                    <img className="image-22" alt="site-picture" src={noImageIcon} />
                  }

                </div> 
                <div className="clientinfo">
                  <div className="clientinfo1">
                  <div class="clientname">Client Name</div>
                  </div> 
                  <div className="clientinfo2">
                  <div
              className="nationalinstituteofhealthandcareexcellencenicehospital h2-sb"
            >
              {data.clientName}
            </div>
                  </div>
                </div>

                  </div> 

                </div> 
                <div className= {`client1location ${shrinkMap?"client1locationactive":"client1locationunactive"} ` }  onClick={() => setShrinkMap(!shrinkMap)}> 
                     <div className="pasite-2 s3-m" data-info={data.location}>{data.location ? data.location.split(',')[0].trim().substring(0, 7) + '...' : ''}
                     <img  style={{width:"14px", height:"15px"}}className="vector" src={claritymapmarker} alt="location" />
                   </div>  
                 
                </div>

              </div>
              <div className="client2">
                <div className="client2info1">
                  <div className="catinfo">
                    <div className="categorydetails jos-12px-sb ">
                      Category 

                    </div>
                  </div>  
                  <div className="catvalue">
                    <div className="catnurse s3-m">
                    {data.jobCategory}
                    </div>
                  </div> 

                </div> 
                <div class="client2info2">
      <div class="job-info">
        <div class="jobdetails jos-12px-sb ">Job Name</div>
      </div>
      <div class="jobvalue">
        <div class="certifiednursingassistantcna s3-m">
        {data.jobName}
        </div>
      </div>
    </div>


              </div>
            </div> 

            {/* <div className="time"> 
            <div class="time-main">
  <div class="time1">
    <div class="time2">
      <div class="time3">
        <div class="time4">
          <div class="time5">
            <div class="startlabel">Starts</div>
          </div>
        </div>
      </div>
      <div class="time6">
        <div class="_ellipse74"></div>
        <img className="material-symbols-locationon" src={locationGreen} alt="location" />
      </div>
    </div>
    <div class="timecal">
      <div class="frame-1828">
        <div class="frame-1827">
        <img className="uilcalender" src={uilCalendarIcon} alt="calendar" />
      <div className="_27-02-2023_">{moment(data.startDate).format(`${localStorage.getItem('dateFormat')}`)}</div>
        </div>
        <div class="timeclock">
        <img className="ic-sharpaccesstime" src={wrClockIcon} alt="clock" />
     <div className="_03-15-00-pm_">{moment(data.startDate).format("hh:mm:ss a")}</div>
        </div>
      </div>
    </div>
    <div class="line-77_"></div>
  </div>
  <div class="end1">
    <div class="end2">
      <div class="frame-1830">
        <div class="end3">
          <div class="end4">
            <div class="ends0">Ends</div>
          </div>
        </div>
      </div>
      <div class="endicon">
        <div class="ellipse-742_"></div>
        <img className="endicon1" src={redFlagIcon} alt="refd-flag" />
      </div>
    </div>
    <div class="endcal">
      <div class="frame-1828">
        <div class="frame-1827">
        <img className="uil-calender2" src={uilCalendarIcon} alt="calendar" /> 
      <div className="_27-02-2023">{moment(data.endDate).format(`${localStorage.getItem('dateFormat')}`)}</div>
        </div>
        <div class="frame-1826">
        <img className="icsharp-access-time2" src={wrClockIcon} alt="clock" />
        <div className="_03-15-00-pm_2">{moment(data.endDate).format("hh:mm:ss a")}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tp1">
  <div class="normal1">Normal</div>
</div>
<div class="tp2">
  <div class="weekday1">Weekday</div>
</div>
<div class="tp3">
  <div class="recurrenceicon">Recurrence</div>
  <div class="framer">
   <img src={recurrenceIcon} alt='Recurrence'  onClick={()=> setRecurring((prev)=>{ console.log("umn")
              return{
                ...prev,
                status:true,
              }
            })} width={30}/> 
  

    
  </div>
</div> 


<div class="frame-2487 ">
              <div className="line-75"></div>
    <div class="engagement-type">Engagement Type:</div>
    <div class="frame-2486">
      <div class="contract">Contract</div>
    </div>
  </div>
  


            </div> */}
            <div class="frame-2420">
  <div class="frame-2489">
    <div class="frame-1831">
      <div class="frame-1089">
        <div class="frame-1830">
          <div class="frame-1828">
            <div class="frame-1827">
              <div class="starts sr-r-8px">Starts</div>
            </div>
          </div>
        </div>
        <div class="frame-10892">
          <div class="ellipse-74"></div>
          <img className="material-symbols-location-on-1" src={locationGreen} alt="location" width={19} height={20}/>
       
        </div>
      </div>
      <div class="frame-1829">
        <div class="frame-1828">
          <div class="frame-1827">
          <img className="uil-calender" src={uilCalendarIcon} alt="calendar" width={20} height={20}/>
          <div className="_27-02-2023 s3-r">{moment(data.startDate).format(`${localStorage.getItem('dateFormat')}`)}</div>
          </div>
          <div class="frame-1826">
          <img className="ic-sharp-access-time" src={wrClockIcon} alt="clock" width={20} height={20}/>
          <div className="_03-15-00-pm s3-r">{moment(data.startDate).format("hh:mm:ss a")}</div>
          </div>
        </div>
      </div>
      <div class="line-77"></div>
    </div>
    <div class="frame-1881">
      <div class="frame-10893">
        <div class="frame-1830">
          <div class="frame-18282">
            <div class="frame-18272">
              <div class="ends sr-r-8px">Ends</div>
            </div>
          </div>
        </div>
        <div class="frame-1090">
          <div class="ellipse-742"></div>
          <img className="frame-2439" src={redFlagIcon} alt="refd-flag" width={14} height={14}/>
        </div>
      </div>
      <div class="frame-18292">
        <div class="frame-1828" style={{marginLeft:"5px"}}>
          <div class="frame-1827">
          <img className="uil-calender" src={uilCalendarIcon} alt="calendar" width={20} height={20}/> 
                      <div className="_27-02-2023 s3-r">{moment(data.endDate).format(`${localStorage.getItem('dateFormat')}`)}</div>

            
          </div>
          <div class="frame-1826">
          <img className="ic-sharp-access-time" src={wrClockIcon} alt="clock" width={20} height={20}/>
                  <div className="_03-15-00-pm s3-r">{moment(data.endDate).format("hh:mm:ss a")}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="frame-1879">
    <div class="normal sr-r-8px">{data.priority=="normal"?"Normal":data.priority}</div>
  </div>
  <div class="frame-1880">
    <div class="weekday sr-r-8px">
    {
                            data.wrType == 1 ? "Weekday" :
                            data.wrType == 2 ? "Weekend" :
                            data.wrType == 3 ? "Holiday" :
                            data.wrType == 4 ? "Contract" : "-"
                        }
    </div>
  </div>
  {data.repeat?  
  <div class={`frame-1156  ${data.repeat?`cursor-pointer`:""}`} onClick={()=> setRecurring((prev)=>{
              return{
                ...prev,
                status:true,
              }
            })}>
    <div class="recurrence sr-r-8px">Recurrence</div>
    <div class="frame" >
               <img  class="recuricon" src={recurrenceIcon} alt='Recurrence' width={25} height={20}/>
    </div>
  </div>  : null }
</div>
          </div>
          </div>   




          <div class="engtypeliner" >
              <div className="line-75"></div>
              {/* <div><hr style={{width:"50%"}}/></div> */}
    <div class="engagement-type sr-r-8px">Engagement Type</div>
    <div class="frame-2486">
      <div class="contract sr-r-8px">{
           data.engagementType == 1 ? "Contract":
            data.engagementType == 2 ? "Regular": "-"
           }</div>
    </div>
  </div>



                 {/* Map */}
               {!shrinkMap ?
              <>
                {/* <img className="polygon-16" src={upArrowIconGray} alt="up-arrow" /> */}
                <div className="map-main">
                <div className="frame-2333">
                  <img className="material-symbols-location-on" src={locationGrayIcon} alt="location-gray" width={15} height={16}/>
                  <div
                    className="location-info s3-r"
                  >
                    {data.location}
                  </div> 
                  <div className="map-close"   onClick={() => setShrinkMap(!shrinkMap)} > 

                  <img src={mapclosebutton} alt="map-close-button" />
                  <div class="close-label cta-3-m"> 
                    Close
                  </div>

                  </div>
                  
              </div>
                  {!!data.longLat && (
                    <div onClick={() => setMapActive(true)}>
                      <div style={{ pointerEvents: mapActive ? "auto" : "none" }}>
                        <GoogleMaps
                          lat1={data.longLat[1]}
                          lan1={data.longLat[0]}
                          // sitePicture={!!data?.sitePicture && data?.sitePicture.split("|").length>1 ? data?.sitePicture.split("|")[1] : ""}
                          sitePicture={onlineImage ? onlineImage : ""}
                          unit={distanceUnit}
                          //first approach
                          // lat2={localStorage.getItem("isPick") ? JSON.parse(localStorage.getItem("userData")).longLat[1] :
                          // data.workStaffAssignment.staffWorkData &&
                          //   data.workStaffAssignment.staffWorkData.length > 0 &&
                          //   data.workStaffAssignment.staffWorkData.filter(
                          //     (staff) =>
                          //       staff.staffId ===
                          //       JSON.parse(localStorage.getItem("userData")).id
                          //   ).length > 0 &&
                          //   data.workStaffAssignment.staffWorkData &&
                          //   data.workStaffAssignment.staffWorkData.length > 0 &&
                          //   data.workStaffAssignment.staffWorkData.filter(
                          //     (staff) =>
                          //       staff.staffId ===
                          //       JSON.parse(localStorage.getItem("userData")).id
                          //   )[0].longLat[0]}
                          // lan2={localStorage.getItem("isPick") ? JSON.parse(localStorage.getItem("userData")).longLat[0] : 
                          // data.workStaffAssignment.staffWorkData &&
                          // data.workStaffAssignment.staffWorkData.length > 0 &&
                          // data.workStaffAssignment.staffWorkData.filter(
                          //   (staff) =>
                          //     staff.staffId ===
                          //     JSON.parse(localStorage.getItem("userData")).id
                          // ).length > 0 &&
                          // data.workStaffAssignment.staffWorkData &&
                          // data.workStaffAssignment.staffWorkData.length > 0 &&
                          // data.workStaffAssignment.staffWorkData.filter(
                          //   (staff) =>
                          //     staff.staffId ===
                          //     JSON.parse(localStorage.getItem("userData")).id
                          // )[0].longLat[1]}
                          //2nd approach localstorage
                          // lat2={JSON.parse(localStorage.getItem("userData")).longLat[1] }
                          // lan2={JSON.parse(localStorage.getItem("userData")).longLat[0] }
                          // 3rd approach redux approach
                          lat2={staffLongLat?.[1]}
                          lan2={staffLongLat?.[0]}
                        />
                      </div>
                    </div>
                  )} 
                </div>
            
              </>
              : null}



    



          <div className="frame-2344">
                    <div className="frame-2343"> 
                    <div class="frame-2333">
                    <img className="material-symbols-location-on" src={locationGrayIcon} alt="location-gray" width={15} height={16}/>

    <div
      class="willow-staffing-agency-old-route-119-highway-north-indiana-pa-usa s3-r"
    >
      {data.location}
    </div>
  </div>
                      

                  {/* Attached Documents */}
                  { !shrink && <>  
                  <div className="frame-2342">
                  <div className="frame-2340">
                    <div className="frame-2339">
                      <div className="client-notes s1-r">Client Notes</div>
                    </div>
                  </div>

                <div className="frame-2341">
                  <div className="frame-23402">
                    <div className="please-refer-the-staff-with-proper-experience s2-m">
                      {data.notes?data.notes:"N/A"}
                    </div>
                  </div>
                </div>
              </div>



                  <div className="frame-2342">
                  <div className="frame-2340">
                    <div className="frame-2339">
                      <div className="client-notes s1-r">Attached Documents</div>
                    </div>
                  </div>

                  <div className="frame-2341">
                    <div className="frame-23402">
                      <div className="please-refer-the-staff-with-proper-experience ">
                      <div className="_6665111-image-png s2-m">
                        {!data.documents || data.documents.length === 0 ? (
                        <span style={{color: !data.documents || data.documents.length === 0 && "black"}}>No Attached Documents</span>
                        ) : (
                          data.documents.map((doc, i) => (
                          <a 
                          key={i}
                          type="button"
                          className="ele-m document-links"
                          onClick={() => {
                            const docData = doc.split("|");
                            if (docData.length > 1) 
                            {
                              fetchFileDownloadURLById(docData[0], docData[1], docData[2]);
                            }
                          }}>
                            {doc.split("|").length > 1 && doc.split("|")[1].length > 35 ? (
                            <>{'...' + doc.split("|")[1].substring(doc.split("|")[1].length - 35)}</>
                            ) : (
                            <>{doc.split("|")[1]}</>
                            )}
                            ({i + 1})
                            {data.documents && data.documents.length > 0 && (
                              <img className="download ml-2" src={downloadIcon} alt="download" width={14} height={15}/>
                              )}
                            </a>
                            ))
                            )}
                            
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  {/* Work Request Title */}
                  <div className="frame-2342">
                  <div className="frame-2340">
                    <div className="frame-2339">
                      <div className="client-notes s1-r">Work Request Title</div>
                    </div>
                  </div>

                <div className="frame-2341">
                  <div className="frame-23402">
                    <div className="please-refer-the-staff-with-proper-experience s2-m">
                      {data.workRequest?data.workRequest:"N/A"}
                    </div>
                  </div>
                </div>
              </div>

              {/* Created At */}
              <div className="frame-2342">
                  <div className="frame-2340">
                    <div className="frame-2339">
                      <div className="client-notes s1-r">Created On</div>
                    </div>
                  </div> 

                <div className="frame-2341">
                  <div className="frame-23402">
                    <div className="please-refer-the-staff-with-proper-experience s2-m">
                      {data.createdAt?(moment(data.createdAt).format(`${localStorage.getItem('dateFormat')}`))+ "   "+ (moment(data.createdAt).format("hh:mm:ss a")):"N/A"}
                    </div>
                  </div>
                </div>

                

              </div>  

          





                  </>}
                    </div>
                  </div>

                    <div className="rectangle-325 d-flex justify-content-between cta-3-m cursor-pointer" onClick={() => setShrink(!shrink)}>
                      <span style={{    position: "relative",
                        top: "64px",
                        left: "10px",
                        color: "#e5288b"}}>Additional WR  Details</span>
                      <span style={{ position: "relative",
                        top: "64px",
                        right: "5px",
                        color: "#e5288b"}}>{shrink ? "More" : "Shrink"}
                        <img  src={shrink ? downArrowShrink : upArrowShrink} alt="arrow" />
                        </span>
                    </div>

              {data.status >= 2 || (data.status === 0 && data.workStaffAssignment?.staffWorkData[0]?.clockIn
              )? (
                <TimeSheetMark
                  data={data}
                  setOpen={setOpen}
                  setWorkAssign={setWorkAssign}
                  setActivePointsFromStaffDetails={setActivePointsFromStaffDetails}
                  handleReload={() => {
                    setDefault();
                    handleReload();
                  }}
                />
              ) : (
                ""
              )}
                {data && data.status >= 4 && data.clientCompletedDate?
                    (
                  <div className="row align-items-center justify-content-end">
                    <div className="col-5">
                      <div className="form-group">
                        <p className="sub-text fs-13">Time Sheet Approval By Client</p>
                      </div>
                    </div>      
                    <div className="col-6">
                      <div className="form-group">
                        <p className="text-black fs-11 ">
                          {" "}
                          {data && data.status >= 4 && data.clientCompletedDate
                            ? "Approved on " +
                              moment(data.clientCompletedDate).format(
                                `${localStorage.getItem('dateFormat')} hh:mm:ss a`
                              )
                            : "-"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ):""
                }
              {statuss === NEW_SHIFT_AVAILABLE ? (
                // <div className="d-flex align-items-center justify-content-end mt-3">
                //     <NormalButton
                //       onClick={() => {
                //         handleInterest(data.id, data.clientId);
                //       }}
                //       label="SCHEDULE ME!"
                //       outline={false}
                //       className="mb-2 btn fs-10 btn-block btn-dark-primary schedule-btn"
                //     />
                // </div>
                <div className="sticky-footer schedule-me-btn">
                  { 
                  <div style={{opacity: scheduleMeLoading ? 0.5 : 1, cursor: scheduleMeLoading ? "not-allowed" : "pointer"}} className="frame-2274-2 cursor-pointer" id="scheduleMe" onClick={() => { 
                        if(!scheduleMeLoading) {
                          data.repeat?setOpenConfirmModal({visible:true,type:'pick-work'}):handleInterest(data.id, data.clientId);
                        }
                      }}>
                    <div className="schedule-me d-flex align-items-center ">SCHEDULE ME {!data.repeat && scheduleMeLoading ? <CustomLoader color="white"/> : null}</div>
                  </div>}
                </div>
              ) : (
                ""
              )}
              {statuss === NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM || statuss === HOLD_WORK || statuss === REJECTED_BY_STAFF ? (
                <React.Fragment>
                      {/* <NormalButton
                        // onClick={() => {
                        //   handleRejectStaff(data.id);
                        // }}
                        label="Reject Work"
                        id="rejectWork"
                        outline={false}
                        className="btn fs-10 btn-block btn-danger mt-1"
                        disabled={statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK}
                      /> */}
                        

                    {statuss === NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && <div className="d-flex align-items-end mt-3 justify-content-end ">
                      {/* <NormalButton
                        onClick={() => {
                          handleHoldStaff(data.id);
                        }}
                        label="Hold work"
                        id="holdWork"
                        outline={false}
                        disabled={statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM}
                        className="mb-2 btn fs-14 btn-block  btn-dark-primary "
                      /> */}
                    </div>}
                    {/* Old Accept Work Button */}
                    {/* <div className="d-flex align-items-center mt-3 justify-content-between">
                      <NormalButton
                        onClick={() => {
                          //setOpenModal(true);
                          handleAction(true);
                        }}
                        label="Accept work"
                        id="acceptWork"
                        outline={false}
                        disabled={statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK}
                        className="btn fs-10 btn-block btn-success mb-2 setacceptbtn"
                      />
                    </div> */}
                    {rejectAccShow === true ? <RejectAccordian id={data.id} submitLoading={submitLoading} onSubmit={getrejectAccordiandata} statuss={statuss} data={rejectt.length>0 && rejectt[0].value==true?rejectt[0].reject:""} /> : null}
                    <div className="d-flex flex-row sticky-footer accept-reject-cont" id="rejectWork">
                      <div className="frame-2273 cursor-pointer" onClick={() => {
                            setRejectAccShow(!rejectAccShow);
                            setTimeout(() => {
                              scrollToBottom();
                            }, 100); 
                          }}>
                        <div className="reject-work cursor-pointer cta-2-r">{statuss === REJECTED_BY_STAFF ? "Rejected" : "Reject Work"}</div>
                      </div>
                      <div className="frame-2274 cursor-pointer" id="acceptWork"  onClick={() => {
                          //setOpenModal(true);
                          if(statuss === NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM) {
                            data.repeat?setOpenConfirmModal({visible:true,type:'accept-work'}):handleAction(true);
                          }
                        }}
                        style={{background: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK && "#e4e4e4", cursor: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK || acceptWorkLoading && "not-allowed", opacity: acceptWorkLoading ? 0.5 : 1, width: acceptWorkLoading && "173px", 
                        }}>
                        <div className="accept-work d-flex align-items-center" style={{color: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK && "#808080", cursor: statuss !== NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM && statuss !== HOLD_WORK && "not-allowed", width: acceptWorkLoading && "100%"}}>Accept Work {!data.repeat && acceptWorkLoading ? <CustomLoader color="white"/> : null}</div>
                      </div>
                    </div>
                  {/* <br></br>
              <br></br>
              <br></br>
              <br></br> */}
                </React.Fragment>
                
              ) : (
                ""
              )}
              {statuss === SHIFT_CONFIRMED_CLOCK_IN ? (
                // <div className="d-flex align-items-center justify-content-end py-3">
                //   <NormalButton
                //     onClick={() => {
                //       handleCheckIn();
                //     }}
                //     label="clock - In"
                //     id="clockIn"
                //     outline={false}
                //     className=" btn fs-10 text-white bg-c19 font-weight-bold"
                //   />
                // </div>
                    <div className="sticky-footer">
                      <div style={{
                        opacity: clockInLoading ? 0.5 : 1,
                        cursor: clockInLoading && "not-allowed"
                      }} 
                      className="frame-2286 cursor-pointer" id="clockIn" onClick={() => {
                        if(!clockInLoading) {
                          clockInCheck();
                        }
                        }}>
                          <div style={{width: clockInLoading && "140px"}} className="frame-2285">
                            <div className="clock-in d-flex align-items-center ">Clock-In {clockInLoading ? <CustomLoader color="white" /> : null}</div>
                        </div>
                      </div>
                    </div>
              ) : (
                ""
              )}

          </div>  
          { openConfirmModal.visible? <ConfirmModel
          toggle={() => setOpenConfirmModal({...openConfirmModal,visible:!openConfirmModal.visible})}
          isOpen={openConfirmModal}
          handleAction={(e) => handleAction(e)}
          loadingCheckKey = {openConfirmModal.type}
          loadingKey={loadingKey}
          message={openConfirmModal.type === 'clockin' ? 'Are you sure you want to clock-in early?' : 'This is a recurring work request. Are you sure to accept?'}
          type={openConfirmModal.type}
        /> : null }
          {data.repeat?
          <RecurringModel show={recurring.status}  handleClose={()=>setRecurring((prev) => {return { ...prev,status:false}})}  repeat ={data.repeat} repeatData={data.repeatData}/> : null }
        </React.Fragment>
        ) : (
          <CommonPageLoader />
        )}
        {/* Dummy div for scrollToBottom */}
        <div id="bottom" ref={bottomRef}>
        </div>
      </NormalModal>

      {/*<AcceptWorkModel
        toggle={() => setOpenModal(!openModal)}
        isOpen={openModal}
        handleAction={(e) => {
          handleAction(e);
          setDefault();
        }}
      />*/}
      {openAlert && <LateClockInAlert
        toggleOverlay = {() => setOpenAlert(!openAlert)}
      /> }
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getWorkById,
      rejectWorkByStaff,
      acceptWorkByStaff,
      staffClockIn,
      holdWorkStaff,
      updateTaskData,
      workInterestedStaff,
      getFileFromMDB
    },
    dispatch
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(withRouter(WorkAssignment));

import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./sidebar.scss";
import atom_blue from "assets/svg/atom_pink.svg";
import atom_white from "assets/svg/atom_white.svg";
//import clockSolid from "assets/svg/clock solid.svg";
//import clockTransparent from "assets/svg/clock transparent.svg";
//import groupSolid from "assets/svg/group solid.svg";
//import groupTransparent from "assets/svg/group transparent.svg";
import periodic_table_blue from "assets/svg/periodic_table_pink.svg";
import periodic_table_white from "assets/svg/periodic_table_white.svg";
import statSolid from "assets/svg/stat solid pink.svg";
import statTransparent from "assets/svg/stat transparent.svg";
import settingsSolid from "assets/svg/settings solid pink.svg";
import settingsTransparent from "assets/svg/settings transparent.svg";
import {history} from "../../../service/helpers";
import { startTourGuide } from "reducer/tourGuide";
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.activeTab = this.activeTab.bind(this);
    this.state = {
      navLinks: [
        {
          to: "/something",
          label: "",
          iconName: "",
          iconTransparent: "",
          classNames:"",
        },
        {
          to: "/admin/workSchedule/list",
          label: "Schedule",
          iconName: atom_blue,
          iconTransparent: atom_white,
          classNames:"WSSizePlus",
        },

        //{
        //  to: "/admin/timeSheet",
         // label: "Time Sheet",
          //iconName: clockSolid,
         // iconTransparent: clockTransparent,
       // },
        {
          to: "/admin/staffProfile",
          label: "Profile",
          iconName: periodic_table_blue,
          iconTransparent: periodic_table_white,
          classNames:"sideBarIcon",
        },
        {
          to: "/admin/report",
          label: "Reports",
          iconName: statSolid,
          iconTransparent: statTransparent,
          classNames:"sideBarIcon",
        },
        // {
        //   to: "/admin/notification",
        //   label: "Notification",
        //   iconName: groupSolid,
        //   iconTransparent: groupTransparent,
        // },
        {
          to: "/admin/settings",
          label: "Settings",
          iconName: settingsSolid,
          iconTransparent: settingsTransparent,
          classNames:"sideBarIcon",
        },
        {
          to: "/something",
          label: "",
          iconName: "",
          iconTransparent: "",
          classNames:"",
        },
      ],
      prev: "",
      next: "",
      active: "",
    };
  }

  activeTab = (index) => {
    this.setState(
      {
        next: index + 1,
        prev: index - 1,
        active: index,
      },
      () => {
        // callback function logic goes here
      },
    );
  };

  UNSAFE_componentWillMount() {
    let {location} = history;
    let {navLinks} = this.state;
    navLinks.forEach((item, index) => {
      if (item.to === location.pathname) {
        this.setState({
          next: index + 1,
          prev: index - 1,
          active: index,
        });
      }
    });
  }
  
  render() {
    let {navLinks, prev, next, active} = this.state;
    let {menuOpen, handleSidenav} = this.props;
    let {location} = history;
    
    

    return (
      <React.Fragment>
        <div className={`left-menu bg-site-primary  ${menuOpen ? "open" : ""}`}>
          <div className="sidebar-logo" />
          <PerfectScrollbar>
            <ul>
              {navLinks.map(({to, label, iconName, iconTransparent, classNames}, index) => (
                <li key={index} onClick={this.props.handleTour}>
                  <NavLink
                    onClick={()=>(to !== "/something" ? this.activeTab(index) : "")}
                    tabIndex={to === "/something" ? "-1" : ""}
                    to={to}
                    className={`nav-link ${(to === "/admin/staffProfile" && location.pathname == '/admin/editProfile') || (location.pathname == '/admin/workSchedule/calendar' && index === 1) ? 'active': ''} ${to === "/something" ? "disabled" : ""}  ${prev === index &&
                    location.pathname.indexOf(to) > -1
                      ? "prev-li"
                      : ""} ${next === index && location.pathname.indexOf(to) > -1 ? "next-li" : ""}`}>
                    <div className="sidebar-menu">
                      <div className="menu-icon text-left">
                        {/* Old */}
                        {/* {(active === index && location.pathname.indexOf(to) > -1) ||
                        (active !== undefined && location.pathname.indexOf(to) > -1) ? (
                          <img src={iconName} className={classNames} alt="iconname" />
                        ) : (
                          <img src={iconTransparent} className={classNames} alt=""/>
                        )} */}
                        {
                          !menuOpen ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip id={`tooltip-${index}`}>{label}</Tooltip>}
                            >
                              <img
                                src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                                alt=""
                                className={classNames}
                              />
                            </OverlayTrigger>
                          ) : (
                            <img
                              src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                              alt=""
                              className={classNames}
                            />
                          )
                        }
                      </div>
                      {menuOpen && <span className="sidebar-menu-desc fs-16">{label}</span>}
                      {/* {menuOpen ? <span className="sidebar-menu-desc fs-16">{label}</span>:<div className='hoverTitle'><span>{label}</span></div>} */}
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </PerfectScrollbar>
        </div>
        <div className={`sidemenu-overlay ${menuOpen ? "open" : ""}`} onClick={() => handleSidenav()} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleTour: () => dispatch(startTourGuide({run:false,stepIndex:0}))
  }
};
export default connect(null, mapDispatchToProps)(Sidebar)
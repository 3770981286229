import {generateQuery, addQuery} from "./helperFunctions";

export const authApi = {
  adminLogin: {
    api: "v1/login",
    method: "post",
    baseURL: "auth",
  },
  adminLogOut: {
    url: 'v1/logOut',
    method: 'put',
    baseURL: 'authentication',
    query: {
      auditId:'',
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
  otpLogin: {
    api: "v1/otpLogin",
    method: "post",
    baseURL: "auth",
  },
  forgetPassword: {
    api: "v1/sendOtp",
    method: "put",
    baseURL: "auth",
  },
  resetPassword: {
    api: "v1/resetPassword",
    method: "post",
    baseURL: "auth",
  },
  updatePassword: {
    api: "v1/resetPassword",
    method: "post",
    baseURL: "authentication",
  },
  changePassword: {
    url: "v1/sendOtp",
    method: "put",
    baseURL: "authentication",
    query: {
      emailId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  OTPVerify: {
    url: "v1/otpVerify",
    method: "put",
    baseURL: "authentication",
    query: {
      emailId: null,
      otp: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getUserLoginCount: {
    url: "v1/getLoggedUserCount",
    method: "get",
    baseURL: "job",
    query: {
       userMail:null
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
};

export const workRequest = {
  getClient: {
    api: "v1/workStatus/getAllClients",
    method: "get",
    baseURL: "wstatus",
  },
  getAllWork: {
    api: "v1/workStatus/getAllWorkByStaff",
    method: "post",
    baseURL: "wstatus",
  },
  getAllWorkLocations: {
    api: "v1/workStatus/getAllWorkLocation",
    method: "get",
    baseURL: "wstatus",
  },
  // getAllWork: {
  // 	url: "client/getAllWorkByClient",
  // 	method: "get",
  // 	baseURL: "workRequest",
  // 	query: {
  // 		startDate: null,
  // 		endDate: null,
  // 		sortBy: null,
  // 		clientId: null,
  // 		searchKey: null
  // 	},
  // 	get api() {
  // 		return this.url + generateQuery(this.query);
  // 	},
  // 	set addQuery({ key, payload }) {
  // 		this.query[key] = payload;
  // 	},
  // },
  getWorkById: {
    url: "client/getWorkRequestById",
    method: "get",
    baseURL: "workRequest",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  acceptWorkStaff: {
    api: "v1/workStatus/acceptWorkByStaff",
    method: "put",
    baseURL: "wstatus",
  },
  staffClockIn: {
    api: "v1/workStatus/staffClockIn",
    method: "put",
    baseURL: "wstatus",
  },
  rejectWorkStaff: {
    api: "v1/workStatus/rejectWorkByStaff",
    method: "put",
    baseURL: "wstatus",
  },
  interestedWorkStaff: {
    api: "v1/workStatus/iamInterested",
    method: "put",
    baseURL: "wstatus",
  },
  holdWorkStaff: {
    url: "v1/workStatus/holdWorkByStaff",
    method: "put",
    baseURL: "wstatus",
    query: {
      workId: null,
      staffId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  resumeWorkStaff: {
    api: "v1/workStatus/resumeWorkByStaff",
    method: "put",
    baseURL: "wstatus",
  },
  pauseWorkByStaff: {
    api: "v1/workStatus/pauseWorkByStaff",
    method: "put",
    baseURL: "wstatus",
  },
  staffClockOut: {
    api: "v1/workStatus/staffClockOut",
    method: "put",
    baseURL: "wstatus",
  },
  completeWorkByStaff: {
    api: "v1/workStatus/completeWorkByStaff",
    method: "put",
    baseURL: "wstatus",
  },
};
export const Settings = {
  getWorkById: {
    url: "staff/getById",
    method: "get",
    baseURL: "staff",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  updateStaff: {
    api: "staff/update",
    method: "put",
    baseURL: "staff",
  },
  getTimeZoneList: {
    url: "subscription/getTimeZoneList",
    method: "get",
    baseURL: "subscription",
    query: {
       timezone: ["America", "Asia", "US", "Pacific"],
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
};
export const documentUpload = {
  create: {
    api: "v1/storage/aws/uploadFile",
    method: "post",
    baseURL: "file",
  },
};

export const documentUploadOnMDB = {
  create: {
     api: 'v1/storage/mdb/uploadFile',
     method: 'post',
     baseURL: 'file',
  },
};

export const documentDownloadFromMDB = {
  create: {
     api: 'v1/storage/mdb/download/file/',
     method: 'get',
     baseURL: 'file',
     query: {
        'id': null,
     },
  },
};

export const notification = {
  get: {
    url: "notification/get",
    method: "get",
    baseURL: "notification",
    get api() {
      return this.url;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  count: {
    url: "notification/notificationCount",
    method: "get",
    baseURL: "notification",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
};

export const courseManagementApi = {
  getCategoryList: {
    api: "academy/category/list",
    method: "get",
    baseURL: "Course",
  },
  getCategoryDetail: {
    url: "academy/course/list/",
    method: "get",
    baseURL: "Course",
    courseId: null,
    get api() {
      return this.url + this.courseId;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  createCourse: {
    api: "academy/course/create",
    method: "post",
    baseURL: "Course",
  },
  updateCourse: {
    api: "academy/course/update",
    method: "put",
    baseURL: "Course",
  },
  addSections: {
    api: "academy/course/sections/add",
    method: "post",
    baseURL: "Course",
  },
  updateSections: {
    api: "academy/course/sections/update",
    method: "put",
    baseURL: "Course",
  },
  addPractices: {
    api: "academy/course/practice/file/add",
    method: "post",
    baseURL: "Course",
  },
  updatePractices: {
    api: "academy/course/practice/file/update",
    method: "put",
    baseURL: "Course",
  },
  addQuiz: {
    api: "academy/course/quiz/add",
    method: "post",
    baseURL: "Course",
  },
  updateQuiz: {
    api: "academy/course/quiz/update",
    method: "put",
    baseURL: "Course",
  },
  addTrendingVideo: {
    api: "academy/trendingvideo/add",
    method: "post",
    baseURL: "Course",
  },
  getVideoDetail: {
    url: "academy/trendingvideo/list/",
    method: "get",
    baseURL: "Course",
    videoId: null,
    get api() {
      return this.url + this.videoId;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  updateVideos: {
    api: "academy/trendingvideo/update",
    method: "put",
    baseURL: "Course",
  },
  addBlogs: {
    api: "academy/blog/add",
    method: "post",
    baseURL: "Course",
  },
  getBlogDetail: {
    url: "academy/blog/list/",
    method: "get",
    baseURL: "Course",
    blogId: null,
    get api() {
      return this.url + this.blogId;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  updateBlog: {
    api: "academy/blog/update",
    method: "put",
    baseURL: "Course",
  },
  getCourseListForDropdown: {
    api: "academy/course/list/dropdown",
    method: "get",
    baseURL: "Course",
  },
  getCourseList: {
    url: "academy/course/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getTrendingVideoList: {
    url: "academy/trendingvideo/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getBlogList: {
    url: "academy/blog/list",
    method: "get",
    baseURL: "Course",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  fileUpload: {
    api: "documents/file/upload",
    method: "post",
    baseURL: "file",
  },
  deleteCourseById: {
    url: "academy/course/delete/",
    method: "delete",
    baseURL: "Course",
    courseId: null,
    get api() {
      return this.url + this.courseId;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  deleteVideosById: {
    url: "academy/trendingvideo/delete/",
    method: "delete",
    baseURL: "Course",
    videoId: null,
    get api() {
      return this.url + this.videoId;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  deleteblogById: {
    url: "academy/blog/delete/",
    method: "delete",
    baseURL: "Course",
    blogId: null,
    get api() {
      return this.url + this.blogId;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
};

export const fileDownloadFromMDB = {
  getFile: {
     url: 'v1/storage/mdb/file',
     method: 'get',
     baseURL: 'file',
     query: {
        fileId:'',
        entity:'',
        clientId: '',
     },
     get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteFile: {
     url: 'v1/storage/mdb/deleteFile',
     method: 'delete',
     baseURL: 'file',
     query: {
        fileId:'',
        entity:'',
     },
     get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const contentMaganementApi = {
  addNews: {
    api: "content/news/news/add",
    method: "post",
    baseURL: "Content",
  },
  updateNews: {
    api: "content/news/news/update",
    method: "put",
    baseURL: "Content",
  },
  getAllNews: {
    url: "content/news/news/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getNewsById: {
    url: "content/news/news",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },
  deleteNewsById: {
    url: "content/news/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },

  addStories: {
    api: "content/stories/stories/add",
    method: "post",
    baseURL: "Content",
  },
  updateStories: {
    api: "content/stories/stories/update",
    method: "put",
    baseURL: "Content",
  },
  getAllStories: {
    url: "content/stories/stories/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getStoriesById: {
    url: "content/stories/stories/getById",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },
  deleteStoriesById: {
    url: "content/stories/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },

  addTestimonials: {
    api: "content/testimonial/add/testimonial",
    method: "post",
    baseURL: "Content",
  },
  updateTestimonials: {
    api: "content/testimonial/update/testimonial",
    method: "put",
    baseURL: "Content",
  },
  getAllTestimonials: {
    url: "content/testimonial/get/testimonial",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getTestimonialsById: {
    url: "content/testimonial/get/testimonial",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },
  deleteTestimonialsById: {
    url: "content/testimonial/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },

  addEvents: {
    api: "content/event/add/events",
    method: "post",
    baseURL: "Content",
  },
  updateEvents: {
    api: "content/event/update/event",
    method: "put",
    baseURL: "Content",
  },
  getAllEvents: {
    url: "content/event/get/events",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getEventsById: {
    url: "content/event/get/event",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },
  deleteEventsById: {
    url: "content/event/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({key, payload}) {
      this[key] = payload;
    },
  },
};
export const staffApi = {
  getStaffId: {
    url: "staff/getById",
    method: "get",
    baseURL: "staff",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  
  getAllUser: {
    url: "staff/getAll",
    method: "get",
    baseURL: "staff",
    query: {
       agencyId: null,
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
  getTimeOff: {
    url: "timeOff/getAllTimeOff",
    method: "get",
    baseURL: "agency",
    query: {
      sort: "",
      // startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
      // endDate: moment(new Date()).add(1, "day").format("YYYY-MM-DD"),
      page: 1,
      size: 10,
      staffId: localStorage.getItem("userData") !== null ? JSON.parse(localStorage.getItem("userData")).id : null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  updateProfile: {
    api: "staff/update",
    method: "put",
    baseURL: "staff",
  },
  updateCredentials: {
    api: "staff/updateCredentials",
    method: "put",
    baseURL: "staff",
  },
  updatePreferences: {
    api: "staff/updatePreferences",
    method: "put",
    baseURL: "staff",
  },
  updatePermissions: {
    api: "staff/updatePermission",
    method: "put",
    baseURL: "staff",
  },
  addTimeOff: {
    api: "timeOff/timeOffRequest",
    method: "post",
    baseURL: "agency",
  },
  updateTimeOff: {
    api: "timeOff/update",
    method: "put",
    baseURL: "agency",
  },
  cancelTimeOff: {
    url: "timeOff/remove",
    method: "delete",
    baseURL: "agency",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getAvailableLeaves: {
    url: "timeOff/getAvailable",
    method: "get",
    baseURL: "agency",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getAvailableHours: {
    api: "timeOff/getAvailedHours",
    method: "get",
    baseURL: "agency",
  },
  getAvailableCodes: {
    url: "timeOffCode/getAll",
    method: "get",
    baseURL: "agency",
    query: {
      agencyId:
        localStorage.getItem("userData") !== null ? JSON.parse(localStorage.getItem("userData")).agencyId : null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getJobFunctions: {
    url: "agency/getAgencyJobs",
    method: "get",
    baseURL: "agency",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getTeamNames: {
    url: "team/getTeamByName",
    method: "get",
    baseURL: "agency",
    query: {
      agencyId:
        localStorage.getItem("userData") !== null ? JSON.parse(localStorage.getItem("userData")).agencyId : null,
      name: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
};
// export const notification = {
// 	get: {
// 		url: "notification/get",
// 		method: "get",
// 		baseURL: "notification",
// 		query: {},
// 		get api() {
// 			return this.url + generateQuery(this.query);
// 		},
// 		set addQuery({ key, payload }) {
// 			this.query[key] = payload;
// 		},
// 	},
// };
// export const documentUpload = {
// 	create: {
// 		api: "v1/storage/uploadFile",
// 		method: "post",
// 		baseURL: "file",
// 	},
// };
export const reportApi = {
  getReportHeader: {
    url: "staffReport/header",
    method: "get",
    baseURL: "Report",
    get api() {
      return this.url;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getReportClients: {
    url: "staffReport/getClientServed",
    method: "get",
    baseURL: "Report",
    get api() {
      return this.url;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getWRTClients: {
    url: "staffReport/workHours",
    method: "get",
    baseURL: "Report",
    query: {
      startDate: null,
      endDate: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getReportUpcomingShifts: {
    url: "staffReport/upcomingShifts",
    method: "get",
    baseURL: "Report",
    query: {
      startDate: "",
      endDate: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getReportTasks: {
    url: "staffReport/tasks",
    method: "get",
    baseURL: "Report",
    query: {
      type: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  downloadTotalCSV: {
    url: "staffReport/totalWorksCSV",
    method: "get",
    baseURL: "Report",
    get api() {
      return this.url;
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  downloadWRTCSV: {
    url: "staffReport/workHoursCSV",
    method: "get",
    baseURL: "Report",
    query: {
      startDate: null,
      endDate: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
};
export const timesheetApi = {
  getTimesheet: {
    url: "timeSheet/getById",
    method: "get",
    baseURL: "staff",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getAllTimeSheet: {
    api: "v1/timesheet/getAllTimeSheet",
    method: "post",
    baseURL: "wstatus",
  },
  addTimesheet: {
    api: "timeSheet/add",
    method: "post",
    baseURL: "staff",
  },
  editTimesheet: {
    api: "v1/timesheet/editTimeSheetCheckIn",
    method: "put",
    baseURL: "wstatus",
  },
  editTimesheetBreak: {
    api: "v1/timesheet/editTimeSheetBreak",
    method: "put",
    baseURL: "wstatus",
  },
  submitTimesheet: {
    api: "v1/timesheet/submitTimeSheetForApproval",
    method: "put",
    baseURL: "wstatus",
  },
  approveTimesheet: {
    api: "v1/timesheet/approveTimeSheet",
    method: "put",
    baseURL: "wstatus",
  },
  rejectTimesheet: {
    api: "v1/timesheet/rejectTimeSheet",
    method: "put",
    baseURL: "wstatus",
  },
  updateTimesheet: {
    api: "timeSheet/update",
    method: "put",
    baseURL: "staff",
  },
  getWorkRequestById: {
    url: "client/getWorkRequestById",
    method: "get",
    baseURL: "staff",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
  getTimeoffById: {
    url: "timeOff/getById",
    method: "get",
    baseURL: "agency",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({key, payload}) {
      this.query[key] = payload;
    },
  },
};
export const settingsApi = {
  getSettingsById: {
     url: "agency/getSingleAgency",
     method: "get",
     baseURL: "agency",
     query: {
        id: null,
     },
     get api() {
        return this.url + generateQuery(this.query);
     },
     set addQuery({ key, payload }) {
        this.query[key] = payload;
     },
  }
};
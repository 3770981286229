import React from "react";
import clockIcon from "assets/svg/mdi_access_time.svg";
import moment from "moment";
import "./style.scss";
//import { msToTime } from "service/utilities";

export default function BreakHours({ staffAvailable, work, data }) {
  let currentIndex = -1;
  let count = 0;
  return (
    <React.Fragment>
      {staffAvailable &&
        data.pauseResumeData &&
        data.pauseResumeData.length > 0 &&
        data.pauseResumeData.map(
          (pause, index) => {
            let resumeIndex = 0;
            let pauseIndex = 0;
            if (index % 2 === 1) {
              currentIndex += 2;
              resumeIndex = currentIndex;
              pauseIndex = resumeIndex - 1;
              count++;

            }
            return (
              <div
                className={`${
                  index % 2 === 1
                    ? "row container-fluid mb-3 breaks-mobile-view"
                    : "row container-fluid mobile-view"
                }`}
                key={index}
                // style={{paddingLeft:"0px"}}
              >
                {index % 2 === 1 && (
                  <div className="col-md-4 d-flex align-items-center">
                    <img src={clockIcon} width="13" height="13" alt="clockIcon" />
                    <span className="s2-r ml-3">Break {count} </span>
                  </div>
                )}
                {index % 2 === 1 && (
                  <div className="col-md-6 pl-0 d-flex align-items-center">
                    <span className="s1-m ml-3 ml-md-0 time-mobile-view">
                      {moment(
                        staffAvailable &&
                          data
                            .pauseResumeData[pauseIndex].time
                      ).format("hh:mm:ss a")}{" "}
                      -{" "}
                      {moment(
                        staffAvailable &&
                          data
                            .pauseResumeData[resumeIndex].time
                      ).format("hh:mm:ss a")}
                    </span>
                  </div>
                )}
                {/* {index % 2 == 1 && (
                  <div className="col-md-3">
                    <span className="sub-text fs-14">
                      {msToTime(
                        new Date(
                          work.workStaffAssignment.staffWorkData.length > 0 &&
                            work.workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            work.workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].pauseResumeData[resumeIndex].time,
                        ).getTime() -
                          new Date(
                            work.workStaffAssignment.staffWorkData.length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              )[0].pauseResumeData[pauseIndex].time,
                          ).getTime(),
                      ).hours >= 0 ? (
                        msToTime(
                          new Date(
                            work.workStaffAssignment.staffWorkData.length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              )[0].pauseResumeData[resumeIndex].time,
                          ).getTime() -
                            new Date(
                              work.workStaffAssignment.staffWorkData.length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                ).length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].pauseResumeData[pauseIndex].time,
                            ).getTime(),
                        ).hours +
                        (msToTime(
                          new Date(
                            work.workStaffAssignment.staffWorkData.length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              )[0].pauseResumeData[resumeIndex].time,
                          ).getTime() -
                            new Date(
                              work.workStaffAssignment.staffWorkData.length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                ).length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].pauseResumeData[pauseIndex].time,
                            ).getTime(),
                        ).hours > 1
                          ? " hrs "
                          : " hr ")
                      ) : (
                        ""
                      )}
                      {msToTime(
                        new Date(
                          work.workStaffAssignment.staffWorkData.length > 0 &&
                            work.workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            ).length > 0 &&
                            work.workStaffAssignment.staffWorkData.filter(
                              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                            )[0].pauseResumeData[resumeIndex].time,
                        ).getTime() -
                          new Date(
                            work.workStaffAssignment.staffWorkData.length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              )[0].pauseResumeData[pauseIndex].time,
                          ).getTime(),
                      ).minutes >= 0 ? (
                        msToTime(
                          new Date(
                            work.workStaffAssignment.staffWorkData.length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              )[0].pauseResumeData[resumeIndex].time,
                          ).getTime() -
                            new Date(
                              work.workStaffAssignment.staffWorkData.length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                ).length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].pauseResumeData[pauseIndex].time,
                            ).getTime(),
                        ).minutes +
                        (msToTime(
                          new Date(
                            work.workStaffAssignment.staffWorkData.length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              ).length > 0 &&
                              work.workStaffAssignment.staffWorkData.filter(
                                (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                              )[0].pauseResumeData[resumeIndex].time,
                          ).getTime() -
                            new Date(
                              work.workStaffAssignment.staffWorkData.length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                ).length > 0 &&
                                work.workStaffAssignment.staffWorkData.filter(
                                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                                )[0].pauseResumeData[pauseIndex].time,
                            ).getTime(),
                        ).minutes > 1
                          ? " mins"
                          : " min")
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                )} */}
              </div>
              
            );
          }
        )}
    </React.Fragment>
  );
}

import React, {useEffect, useState} from 'react';
//import {Title} from 'component/common';
import './style.scss';
import {CommonPageLoader} from 'component/common';

import {getNotifications} from '../../../action/notification';
import {bindActionCreators} from 'redux';
import {connect, useSelector} from 'react-redux';
import WorkAssignment from 'component/workShedule/workAssignmentNew';
//import {Link} from 'react-router-dom';
import moment from "moment";
import refreshIcon from "../../../assets/svg/mdi_refresh_white.svg";
import closeIcon from "../../../assets/svg/close.svg";
import NotificationMessages from './NotificationMessages';

function NotificationSettings(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [workAssign, setWorkAssign] = useState({value: false, id: null});
  // const [refresh, setRefresh] = useState(false)

  // useEffect(() => {
  //   fetchAll();
  // }, []);
  const fetchAll = async () => {
    if(props.sideBarClickStatus)
        setLoading(true);

    await props
      .getNotifications()
      .then((data) => {
        if(data.length){
          data.sort((a,b)=>{return moment(b.date) - moment(a.date);})
          setData(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)
  useEffect(() => {
    if(props.sideBarClickStatus)
    fetchNotifications()
  }, [props.sideBarClickStatus]);

  useEffect(() => {
    if(props.sideBarClickStatus && notificationCount > 0)
    fetchNotifications()
  }, [ notificationCount]);

const fetchNotifications = async() =>{
  setLoading(true);
    await props.getNotifications().then((d) => {
      setLoading(false);
      if(d.length)
      d.sort((a,b)=>{return moment(b.date) - moment(a.date);})
      if(d[0]?.notifications.length !== data[0]?.notifications.length){
            setData(d);
        }
    })
    .catch((e)=>{
       setLoading(false);
    })
}

  return (
    <React.Fragment>
      {/* <Title label="Notification" /> */}
      
      <div className='d-flex modal-headerNotification'>
                <div className='col-10 '>
                    <h5 className="position-fixed mt-2 h1-sb">Notification</h5>
                </div>
                <div className='col-2 d-flex justify-content-end'>
                    <img className='refreshIconInModel ml-2 mt-2' src={refreshIcon} onClick={fetchAll} alt="refreshIcon"/>
                    <img className='refreshIconInModel ml-2 mt-2'  src={closeIcon} onClick={props.handleSideBar} alt="closeIcon"/>
                </div>
            </div>
            
      {!loading ? (
        <React.Fragment>
          <NotificationMessages data={data} setWorkAssign={setWorkAssign}/>
          {/* <div className="down">
          {data.length ? (
            data.sort((a,b)=>{return moment(b.date) - moment(a.date);}).map(({date, notifications}, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="ml-3 mr-3" >
                    <h6 className="font-weight-bold notification-date">{moment(date).format(`${localStorage.getItem('dateFormat')}`)}</h6>
                    {notifications.map((item) => (
                      <div
                        key={item.id}
                        className="container notification-bar cursor-pointer"
                        onClick={() => {
                          if(item.path.split('/')[1]==="workSchedule"){
                            setWorkAssign({value: true, id: item.path.split('/')[2]});
                          }
                          // else{
                          //   alert("NOT IMPLEMENTED YET.")
                          // }
                        }}>
                        <div className="row align-items-start p-3 align-items-center">
                          <div className="col-10 pl-0 d-flex justify-content-start align-items-center">
                            <div className="checkBox col-1 " />
                            <div className="col-11">
                              <p className=" pl-3 fs-12 font-weight-bold mb-1 text-grey-42">{item.status}</p>
                              <p className="fs-10 pl-3 wordwrap fixed-height mb-0 text-grey-6c">{item.message}</p>
                            </div>
                          </div>
                          <div className="col-2 text-right pr-40">
                              <p className="text-grey-6c mb-0 fs-13">View</p>
                          </div> 
                        </div>
                      </div>
                    ))}
                  </div>
                 
                </React.Fragment>
              );
            })
          ) : (
            <h6 className="text-center">No results Found</h6>
          )}
           </div> */}
        </React.Fragment>
      ) : (
        <div colSpan="6" className="border-0 loader-header">
          <CommonPageLoader />
        </div>
      )}
     {workAssign.value === true &&
      <WorkAssignment
        workAssign={workAssign}
        setWorkAssign={setWorkAssign}
        handleReload={() => {
        }}
      /> }
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotifications,
    },
    dispatch,
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(NotificationSettings);

import React from "react";
import "./style.scss";

export const NormalCheckbox = ({
	className = "custom-checkbox",
	id = "",
	label = "",
	value = "",
	name = "",
	onChange,
	checked,
	disable = false,
}) => {
	return (
		<label className={className}>
			<input
				type="checkbox"
				id={id}
				name={name}
				value={value}
				checked={checked}
				disabled={disable}
				onChange={({ target: { name, checked: Checked } }) => {
					onChange?.({ target: { name, value: Checked } });
				}}
			/>
			<span className="checkbox-tick border-radius-circle">
				<i className="icon-tick-transparent border-radius-circle" />
			</span>
			{label ? <span className={disable ? "label-txt fs-13 disable pl-2 ml-1" : "label-txt fs-13 pl-2 ml-1"}>{label}</span> : ""}
		</label>
	);
};

import React from "react";
import "./NormalInput.scss";
import infoIcon from "assets/svg/info.svg";

export const NormalInput = ({
	className = "form-control",
	placeholder = "",
	maxlength = "",
	minlength = "",
	label = "",
	onChange,
	value = "",
	name,
	disabled = false,
	type = "text",
	isLink = false,
	info = false,
	labelClass = "",
}) => {
	return (
		<div className="normal-input">
			{label !== "" ? (
				<div className={`fs-16 text-black ${labelClass} font-Gilroy pb-2 mb-1 line-height-20`}>
					{label} {info ? <img className="ml-4" src={infoIcon} alt="infoIcon"/> : ""}
				</div>
			) : (
				""
			)}
			<input
				className={`${isLink ? `${className} islink` : className}`}
				name={name}
				type={type}
				disabled={disabled}
				value={value}
				min={0}
				placeholder={placeholder}
				maxLength={maxlength?maxlength : ''}
				minLength={minlength?minlength : ''}
				onChange={(e) => {
					let body = {};

					body = {
						target: {
							name: e.target.name,
							value: e.target.value,
						},
					};

					onChange(body);
				}}
			/>
			{isLink ? (
				<span className="link-area">
					<i className="fs-24 icon-link" />
				</span>
			) : (
				""
			)}
		</div>
	);
};

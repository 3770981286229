const showError = (error) => {
    switch(error.code) {
        case error.PERMISSION_DENIED:
            console.log("Error: User denied the request for Geolocation sharing.");
            break;
        case error.POSITION_UNAVAILABLE:
            console.log("Error : Location information is unavailable.");
            break;
        case error.TIMEOUT:
            console.log("Error : The request to get user location timed out.");
            break;
        case error.UNKNOWN_ERROR:
            console.log("Error : An unknown error occurred.");
            break;
        default:
            console.log("Error : An unknown error occurred.");
            break;
    }
}

const getCurrentPos = () =>
    new Promise((resolve,reject) => navigator.geolocation.getCurrentPosition(resolve, reject));


export const getCurrentLocation = async() => {
    let latitude = '';
    let longitude = '';
    let status = false;

    if (navigator.geolocation) {
        try {
            const currentPosition = await getCurrentPos();
            latitude = currentPosition.coords.latitude;
            longitude = currentPosition.coords.longitude;
            status = true;
        } catch (error) {
            showError(error);
        }
    }
    else {
        console.log('Error : Geolocation not supported!!');
    }
    
    return { longitude, latitude, status }
}

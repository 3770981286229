import React, {Component} from 'react';
import './select.scss';
import dropArrow from "../../../assets/images/icon/drop-arrow.svg"
export class NormalSelect extends Component {
  render() {
    let {
      className = 'form-control select-form-control w-100',
      options = [],
      keyName = 'label',
      valueName = 'value',
      handleChange,
      labelClass = '',
      value = '',
      name = '',
      placeholder = 'Select',
      disabled = false,
      arrow = false,
      arrowDistance = false,
      isReadOnly = false,
      label = '',
    } = this.props;
    return (
      <React.Fragment>
        {!isReadOnly ? (
          <div className={`${arrow || arrowDistance ? 'select-section' : 'Select'}`}>
            {label !== '' ? (
              <div className={`fs-12 ${labelClass} text-black font-Gilroy pb-2 mb-1 line-height-20`}>{label}</div>
            ) : (
              ''
            )}
            <select
              className={className}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                let body = {
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                };
                handleChange(body);
              }}
              name={name}>
              <option value="" disabled defaultValue>
                {placeholder}
              </option>
              {options.length !== 0 ? (
                options.map((option, index) => (
                  <>
                  <option value={option[valueName]} key={index}>
                    {option[keyName]}
                  </option>
                  </>
                ))
              ) : (
                <option value="" disabled>
                  No Options
                </option>
              )}
            </select>
            {arrow && <span className="icon-down down-icon"> <img src={dropArrow}></img> </span>}
            {arrowDistance && <span className="icon-down-distance down-icon-distance"> <img src={dropArrow}></img> </span>}
          </div>
        ) : (
          <p>{value}</p>
        )}
      </React.Fragment>
    );
  }
}

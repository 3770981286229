export const workScheduleListSteps = [
    {
        target:'.tour-switch',
        content:'Product tour to understand the work flow..',
        placement:'bottom',
        disableBeacon: true,

    },
    {
        target:'h4',
        content:'Work Schedule Module',
        placement:'auto',
        disableBeacon: true,
        disableScrolling:false,
        spotlightPadding: 2,
    },
    {
        target:'#searchbox',
        content:'Enter to Search the Work Request By Id',
        placement:'auto',
        spotlightPadding: 1,
    },
    {
        target:'#viewunassigned',
        content:'View New Unassigned Shifts',
        placement:'auto'
    },
    // {
    //     target:'.setuparrow',
    //     content:'Toggle legend icon for better viewing experience',
    //     placement:'auto'
    // },
    {
        target:'#Allfilters',
        content:'Use filter according to your need and preference',
        placement:'auto'
    },
    {
        target:'#listview',
        content:'Work Requests List view',
        placement:'left',
        spotlightPadding: 2
    },
    {
        target:'#calendarview',
        content:'Work Requests Calendar view',
        placement:'left',
    },
    {
        target:'#refersh-icon',
        content:'Refresh To view the latest Changes',
        placement:'left',
    },
    {
        target:'#work-requests-data',
        content:'Can track Work Requests here..',
        placement:'auto',
    },
    {
        target:'.actionbtn1',
        content:'Can view the Work Request',
        placement:'left',
        spotlightPadding: 2,
    },
     {
        target:'.actionbtn2',
        content:'Can Pick the Work Request',
        placement:'left',
        spotlightPadding: 2,
    },
     {
        target:'.actionbtn3',
        content:'Can Accept the Work Request',
        placement:'left',
        spotlightPadding: 2,

    },

]

export const workScheduleCalendarSteps = [
    {
        target:'.e-work-cells:nth-child(4)',
        content:'Work records will appear inside these calendar cells. You can click on a particular record to view the details',
        disableBeacon: true,
        disableScrolling: true,
    },
    {
        target:'.time-period',
        content:'Click here to view work request details',
        placement:'left',
        spotlightPadding: 2,
    }
]

import React, {useState, useEffect} from "react";
import "./style.scss";
import clockInImg from 'assets/svg/map_cursors/clockIn.svg';
import clockOut from 'assets/svg/map_cursors/clockOut.svg';
import pauseImg from 'assets/svg/map_cursors/pause.svg';
import resumeImg from 'assets/svg/map_cursors/resume.svg';
import disableMap from 'assets/svg/Vector.svg';
import moment from "moment";
import {NormalButton} from "component/common";
import ResumeModel from "../resumeModel";
import {pauseWorkByStaff, staffClockOut, completeWorkByStaff, resumeWorkByStaff} from "action/workRequest";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
//import StaffDetail from "../staffDetails";
import ManualTimeSheet from "component/timeSheet/manualTimeSheet";
import {SHIFT_IN_PROGRESS, PENDING_STAFF_COMPLETE} from "service/constants";
import { getWorkStatus } from "service/utilities";
// import GoogleMapsModel from "../googlemapsModel";
import { getCurrentLocation } from "service/utilities/getCurrentLocation";
import BreakHours from "component/common/breakHours";
import TotalHours from "component/common/totalHours";
import OverHours from "component/common/overtimeHours";
import clockIcon from "assets/svg/mdi_access_time.svg";
import CustomLoader from "component/common/CustomLoader";
import { ConfirmTimeSheetModal } from "component/common/ConfirmTimeSheetModal";
import startLocationTracking, { stopLocationTracking } from "service/utilities/startLocationTracking";

function TimeSheetMark({
  data,
  pauseWorkByStaff,
  staffClockOut,
  completeWorkByStaff,
  setWorkAssign,
  resumeWorkByStaff,
  handleReload,
  setOpen,
  setActivePointsFromStaffDetails,
}) {
  let {workStaffAssignment = {}, status} = data;
  let {staffWorkData = []} = workStaffAssignment;
  const [openModal, setOpenModal] = useState(false);
  // const [openGoogleMaps, setOpenGoogleMaps] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleSheet, setVisibleSheet] = useState(false);
  const [showPause, setShowPause] = useState(true);
  const [hazardPay, setHazardPay] = useState({claimHazardPay:false,hazardPayReason:""});
  const [staffNotes, setstaffNotes] = useState("");
  const [clockOutLoading, setClockOutLoading] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false)
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)

  let workAvailable =
    workStaffAssignment !== undefined &&
    workStaffAssignment.staffWorkData &&
    workStaffAssignment.staffWorkData.length > 0;
  let staffAvailable = workAvailable && workStaffAssignment.staffWorkData.length > 0;

  useEffect(() => {
    if (
      staffWorkData &&
      workStaffAssignment.staffWorkData.length > 0 &&
      workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      ).length > 0 &&
      workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      )[0].pauseResumeData &&
      workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      )[0].pauseResumeData.length > 0 &&
      workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      )[0].pauseResumeData[
        workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        )[0].pauseResumeData.length - 1
      ].key === "pause"
    ) {
      setShowPause(false);
    }
  }, []);

  const hanldePause = async (time) => {
    setPauseLoading(true)
    let currrentLocation = await getCurrentLocation();

    let pauseResumeLongLat;
    if (currrentLocation.status) { 
      pauseResumeLongLat = [currrentLocation.longitude,currrentLocation.latitude]
    } else {
      // let staffId = JSON.parse(localStorage.getItem("userData"))?.id;
      // let staff_data = data?.workStaffAssignment?.staffWorkData?.find(el => el?.staffId?.toString() === staffId?.toString());
      pauseResumeLongLat = (data?.longLat?.length) ? [...data.longLat] : [];
    }

    let body = {
      workId: data._id,
      clientId:data.clientId,
      pauseResumeTime: time,
      pauseResumeLongLat,
      clientSiteTimeZone: data.clientSiteTimeZone,
      geoHistogram:{
        longLat:pauseResumeLongLat,
        timeStamp:time,
        type:2
    },
    };

    try {
      
      await pauseWorkByStaff(body)
        .then(() => {
          stopLocationTracking()
          setOpenModal(!openModal);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error).finally(() => {
        setPauseLoading(false)
      });
    }

    // let body = {
    //   workId: data._id,
    //   pauseResumeTime: time,
    //   longLat: [coordinates().LONGITUDE, coordinates().LATITUDE],
    // };
    // await pauseWorkByStaff(body)
    //   .then(() => {
    //     setOpenModal(!openModal);
    //   })
    //   .catch((error) => console.log(error));
  };

  const hanldeResume = async (time) => {
    setResumeLoading(true);
    let currrentLocation = await getCurrentLocation();

    let pauseResumeLongLat;
    if (currrentLocation.status) { 
      pauseResumeLongLat = [currrentLocation.longitude,currrentLocation.latitude]
    } else {
      // let staffId = JSON.parse(localStorage.getItem("userData"))?.id;
      // let staff_data = data?.workStaffAssignment?.staffWorkData?.find(el => el?.staffId?.toString() === staffId?.toString());
      pauseResumeLongLat = (data?.longLat?.length) ? [...data.longLat] : [];
    }

    let body = {
      workId: data._id,
      clientId: data.clientId,
      pauseResumeTime: time,
      pauseResumeLongLat,
      clientSiteTimeZone: data.clientSiteTimeZone,
      geoHistogram:{
        longLat:pauseResumeLongLat,
        timeStamp:time,
        type:3
    },
    };

    try{

    await resumeWorkByStaff(body)
      .then((res) => {
        console.log(res)
        if(res){
          console.log({res})
          let user = JSON.parse(localStorage.getItem('userData'))
          console.log({user})
          let geoUpdateInterval = user?.geoUpdateInterval
          let enableGeoTracking = user?.enableGeoTracking
          console.log({enableGeoTracking})
          if(enableGeoTracking){
            startLocationTracking({
              id:res,
              staffId:user?.id,
              type:5,
              agencyId:data?.agencyId,
              timeZone:data?.clientSiteTimeZone
            },
            Number(geoUpdateInterval) > 0 ? geoUpdateInterval * 1000 : 60000
            )
          }
        }
        setWorkAssign({ value: true, id: data._id });
        setShowPause(true);
      })
      .catch((error) => console.log(error));
    } catch(error) {
      console.log(error).finally(()=> {
        setResumeLoading(false);
      })
    }

    // let body = {
    //   workId: data._id,
    //   pauseResumeTime: time,
    //   longLat: [coordinates().LONGITUDE, coordinates().LATITUDE],
    // };
    // await resumeWorkByStaff(body)
    //   .then(() => {
    //     console.log(data);
    //     setWorkAssign({value: true, id: data._id});
    //     setShowPause(true);
    //   })
    //   .catch((error) => console.log(error));
  };

  const handleCheckOut = async (time) => {
    setClockOutLoading(true)

    let currrentLocation = await getCurrentLocation();

    let coClockOut;
    if (currrentLocation.status) { 
      coClockOut = [currrentLocation.longitude,currrentLocation.latitude]
    } else {
      // let staffId = JSON.parse(localStorage.getItem("userData"))?.id;
      // let staff_data = data?.workStaffAssignment?.staffWorkData?.find(el => el?.staffId?.toString() === staffId?.toString());
      coClockOut = (data?.longLat?.length) ? [...data.longLat] : [];
    }

    let body = {
      workId: data._id,
      clientId: data.clientId,
      clockOutTime: time,
      clockOutLongLat:coClockOut,
      clientSiteTimeZone: data.clientSiteTimeZone,
      geoHistogram:{
        longLat:coClockOut,
        timeStamp:time,
        type:4
    },
    };
    
    await staffClockOut(body)
    .then(() => {
      stopLocationTracking()
      setWorkAssign({value: true, id: data._id});
    })
    .catch((error) => console.log(error)).finally(() => {
      setClockOutLoading(false)
    });

    // let body = {
    //   workId: data._id,
    //   clockOutTime: time,
    //   longLat: [coordinates().LONGITUDE, coordinates().LATITUDE],
    // };
    // await staffClockOut(body)
    //   .then((datas) => {
    //     // setWorkAssign({value: false});
    //     // handleReload();
    //     setWorkAssign({value: true, id: data._id});
    //   })
    //   .catch((error) => console.log(error));
  };
   
  const handleHazardPay = () =>{
    setHazardPay({...hazardPay, claimHazardPay:!hazardPay.claimHazardPay})
  }
  const handleComplete = async () => {
    setSubmitLoading(true)
    let body = {
      workId: data._id,
      clientId:data.clientId,
     ...hazardPay,
      staffNotes:staffNotes,
    };
    await completeWorkByStaff(body)
      .then(() => {
        setWorkAssign({value: false});
        handleReload();
        // setWorkAssign({value: true, id: data._id});
      })
      .catch((error) => console.log(error)).finally(() => {
        setSubmitLoading(false)
      });
  };

  const handleCancelAction = (action) => {
    if(action){
        setIsConfirm(true)
        handleComplete()
        setOpenConfirmAlert(false)
    }
    else{
        setOpenConfirmAlert(false)
    }
  }

  let statuss = getWorkStatus(
    data.status,
    data?.workStaffAssignment?.staffWorkData?.length > 0
      ? data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        ).length > 0 &&
        data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        )[0].status
      : -1,
    data?.workStaffAssignment?.staffWorkData?.length > 0
      ? data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        ).length > 0 &&
        data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        )[0].clockIn
      : undefined,
    data?.workStaffAssignment?.staffWorkData?.length > 0
      ? data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        ).length > 0 &&
        data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        )[0].clockOut
      : undefined,
      data?.workStaffAssignment?.interestedStaff?.length > 0 &&
      data.workStaffAssignment.interestedStaff.includes(
        localStorage.getItem("userData") !== null ? JSON.parse(localStorage.getItem("userData")).id : null,
      ),
    data?.workStaffAssignment?.staffWorkData?.length > 0
      ? data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        ).length > 0 &&
        data.workStaffAssignment.staffWorkData.filter(
          (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
        )[0].holdWork
      : false,
      data?.workStaffAssignment?.rejectedStaff?.length > 0 &&
      data.workStaffAssignment.rejectedStaff.filter(
        (rejected) => rejected.staffId === JSON.parse(localStorage.getItem("userData")).id,
      ).length > 0,
      data?.workStaffAssignment?.rejectedStaff?.length > 0 &&
      data.workStaffAssignment.rejectedStaff.filter(
        (rejected) => rejected.staffId === JSON.parse(localStorage.getItem("userData")).id,
      ).length > 0 &&
      data.workStaffAssignment.rejectedStaff.filter(
        (rejected) => rejected.staffId === JSON.parse(localStorage.getItem("userData")).id,
      )[0].userType,
  );
  return (
    <React.Fragment>
      {(status >= 4 && data?.workStaffAssignment?.staffWorkData?.length > 0 ) || (status === 0 && data.workStaffAssignment?.staffWorkData[0]?.clockIn)? (
        <React.Fragment>
          <div className="shift-entry-details">
            <h6 className="h2-sb">My Shift Entry Details</h6>
          </div>
          <ul className="steps-data shift-entry-details-box">
            {
            data?.workStaffAssignment?.staffWorkData?.length > 0 &&
            data.workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            ).length > 0 &&
            data.workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            )[0].clockIn ? (
              <React.Fragment>
                {
                  data?.workStaffAssignment?.staffWorkData?.length > 0 &&
                  data.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  data.workStaffAssignment.staffWorkData
                    .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)
                    .map((item, index) => (
                      <li className="step completed green" key={index}>
                        <div className="v-stepper">
                          <div className="circle" />
                          <div className="line" />
                        </div>
                        <div className="content-stepper">
                          <div className="row">
                            <div className="col-5">
                              {/* <span className="fs-16 ml-0">{moment(item.clockIn).format("MM/DD/YYYY hh:mm a")}</span> */}
                              {item.clockIn?<div className="s2-r">{moment(item.clockIn).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)}</div>:<div className="s1-r">Pending</div>}
                            </div>
                            <div className="col-2">
                              <span className="s1-r">Clock-in</span>
                            </div>
                            {item.clockIn?(
                            <div
                              className="d-flex location-icons-2 col-1 cursor-pointer justify-content-start"
                              onClick={() => {
                                setOpen(prev => !prev);
                                setActivePointsFromStaffDetails(0); 
                                // setOpenGoogleMaps(!openGoogleMaps);
                              }}>
                              <img src={clockInImg} alt="clockIn"/>
                              </div>) : (<div className="col-1"> <img src={disableMap} style={{cursor:'not-allowed'}} alt="disableMap"/></div>)
                              }
                              {moment(item.clockIn)<moment(data.startDate) && !!item.clockIn ? (
                                <small className="text-success ml-25 s2-m">Clocked-In Early</small>
                              ) : (
                                ""
                              )}
                              {moment(item.clockIn)>moment(data.startDate) && !!item.clockIn ? (
                                <small className="text-danger ml-25 s2-m">Clocked-In Late (Tardy)</small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                      </li>
                    ))}
              </React.Fragment>
            ) : (
              ""
            )}
            {staffWorkData.length > 0 &&
            staffWorkData.filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id).length >
              0 &&
            staffWorkData.filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .pauseResumeData !== undefined ? (
              <React.Fragment>
                {
                  workStaffAssignment?.staffWorkData?.length > 0 &&
                  workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].pauseResumeData &&
                  workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].pauseResumeData.length > 0 &&
                  workStaffAssignment.staffWorkData
                    .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
                    .pauseResumeData.map((item, index) => (
                      <li
                        className={`step ${index % 2 === 1 ? "completed":"active"}`}
                        key={index}>
                        <div className="v-stepper">
                          <div className="circle" />
                          <div className="line" />
                        </div>
                        <div className="content-stepper">
                          <div className="row">
                            <div className="col-5 s2-r">{moment(item.time).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)}</div>
                            <div className="col-2">
                              <span className="s1-r">{index % 2 === 1 ? "Resume" : "Pause"}</span>
                            </div>
                            <div
                              className="col-5 cursor-pointer location-icons"
                              onClick={() => {
                                setOpen(prev => !prev);
                                setActivePointsFromStaffDetails(index+1); 
                                // setOpenGoogleMaps(!openGoogleMaps);
                              }}>
                              <img src={index % 2 === 1 ? resumeImg:pauseImg} alt="resume-pause"/>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
              </React.Fragment>
            ) : (
              ""
            )}
            {
            data?.workStaffAssignment?.staffWorkData?.length > 0 &&
            data.workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            ).length > 0 &&
            data.workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            )[0].clockIn ? (
              <React.Fragment>
                {
                  data?.workStaffAssignment?.staffWorkData?.length > 0 &&
                  data.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  data.workStaffAssignment.staffWorkData
                    .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)
                    .map((item, index) => (<>
                      <li className="step completed red" key={index}>
                        <div className="v-stepper">
                          <div className="circle" />
                        </div>
                        <div className="content-stepper">
                          <div className="row">
                            <div className="col-5">
                              {/* <span className="fs-16 ml-0">{moment(item.clockOut).format("MM/DD/YYYY hh:mm a")}</span> */}
                              {item.clockOut?<div className="s2-r">{moment(item.clockOut).format(`${localStorage.getItem('dateFormat')} hh:mm:ss a`)}</div>:<div className="s1-r">{item.clockIn?"In Progress":"Pending"}</div>}
                            </div>
                            <div className="col-2">
                              <span className="s1-r">Clock-out</span>
                            </div>
                            {item.clockOut? (
                            <div
                              className="d-flex col-1 cursor-pointer justify-content-start"
                              onClick={() => {
                                setOpen(prev => !prev);
                                setActivePointsFromStaffDetails(workStaffAssignment?.staffWorkData[0]?.pauseResumeData?.length + 1);
                                // setOpenGoogleMaps(!openGoogleMaps);
                              }}>
                                <img src={clockOut} alt="clockOut"/>
                                </div>)
                                : <div className="col-1">
                                <img src={disableMap} style={{cursor:'not-allowed'}} alt="disableMap"/> 
                                </div>  
                            }
                              {item.clockOut && <>
                                {moment(data.endDate) < moment(item.clockOut) && !!item.clockOut ? (
                                  <small className="text-danger ml-25 s2-m">Clocked-Out Late</small>
                                ) : (
                                  ""
                                )}
                                {moment(data.endDate) > moment(item.clockOut) && !!item.clockOut ? (
                                  <small className="text-danger ml-25 s2-m">Clocked-Out Early</small>
                                ) : (
                                  ""
                                )}
                              </>}
                            </div>
                        </div>
                      </li>
                      <div style={{position: "relative", bottom: "40px"}} className=" d-flex flex-column justify-content-between w-100">
                        <TotalHours staffAvailable={staffAvailable} work={data} data={item}/>
                        <BreakHours staffAvailable={staffAvailable} work={data} data={item} />
                        <div className="row container-fluid mb-3">
                          <div className="col-md-4 d-flex break-cont-one">
                            <img style={{width:"13px" , height:"13px"}} src={clockIcon} alt="clockIcon"/>
                            <span className="s2-r">Break Hours</span>
                          </div>
                          <div className="col-md-5 hide-middle-div">
                            <span className="fs-13">
                              {/* {!!clockIn ? Moment(clockIn).format("hh:mm a") : "Pending"} -{" "}
                              {!!clockOut ? Moment(clockOut).format("hh:mm a") : "Pending"} */}
                              
                            </span>
                          </div>
                          <div className="col-md-3 break-cont-two">
                            <span className="s2-m">
                              {/* {!!clockOut ? calculateHours(clockIn, clockOut) : "Pending"}{" "} */}
                              {/* {!!clockOut ? [staffWorkedHrs] : "Pending"} hours{" "} */}
                              {staffAvailable &&
                              item.staffWorkedHrs !== undefined
                                ? item.pausedHours.toFixed( 4 ): "0.0000"}{" "}
                              hours
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{position: "relative", bottom: "40px"}}  className="w-100">
                        <OverHours staffAvailable={staffAvailable} work={data} data={item} />
                      </div>

                      <div style={{position: "relative", bottom: "40px"}}>
                      {/* {item.agencyEditTSNotes ? 
                      <div className="container d-flex" >
                            <div className=" text-left" style={{width:'100%'}}>
                                 <p className="sub-text fs-13  text-black px-1 rounded " style={{width:'max-content', background:'rgb(240, 18, 125,0.2)'}}>Edit Timesheet Reason</p> 
                            </div>
                            
                            <div className="d-flex justify-content-center" style={{width:'100%'}}>
                            <p className="text-black  fs-13">{item.agencyEditTSNotes}</p> 
                            </div>
                        </div>
                        : null} */}
                        {item.agencyEditTSNotes ?
                          <div className="frame-2403">
                            <div className="frame-2399">
                              <div className="hazard-reason">Edit Timesheet Reason</div>
                            </div>

                            <div className="frame-2400">
                              <div className="at-emergency-ward-got-hurt-due-to-human-error">
                                {item.agencyEditTSNotes}
                              </div>
                            </div>
                          </div>
                        : ""}

                      {/* {item.claimHazardPay && item.hazardPayReason !== "" ? 
                      <div className="container d-flex justify-content-between mt-5" >
                            <div className=" text-left" style={{width:'100%'}}>
                                 <p className="sub-text fs-13  text-black px-1 rounded " style={{width:'max-content', background:'rgb(240, 18, 125,0.2)'}}>Hazard Pay Reason</p> 
                            </div>
                            
                            <div className="d-flex justify-content-center" style={{width:'100%'}}>
                            <p className="text-black  fs-13">{item.hazardPayReason}</p> 
                            </div>
                        </div>
                        : null} */}

                      {item.claimHazardPay && item.hazardPayReason !== "" ?
                      <div className="frame-2403">
                        <div className="frame-2399">
                          <div className="hazard-reason s1-m">Hazard Reason</div>
                        </div>

                        <div className="frame-2400">
                          <div className="at-emergency-ward-got-hurt-due-to-human-error">
                            {item.hazardPayReason}
                          </div>
                        </div>
                      </div>
                      : ""}
{/* 
                      {item.staffNotes ? 
                      <div className="container d-flex" >
                            <div className=" text-left" style={{width:'100%'}}>
                                 <p className="sub-text fs-13  text-black px-1 rounded " style={{width:'max-content', background:'rgb(240, 18, 125,0.2)'}}>Staff Notes</p> 
                            </div>
                            
                            <div className="d-flex justify-content-center" style={{width:'100%'}}>
                            <p className="text-black  fs-13">{item.staffNotes}</p> 
                            </div>
                        </div>
                        : null} */}
                        {item.staffNotes ? 
                          <div className="frame-2405">
                            <div className="frame-2399">
                              <div className="staff-notes s1-m">Staff Notes</div>
                            </div>

                            <div className="frame-2400">
                              <div className="clocked-out-earlier-due-to-personal-emergency">
                                {item.staffNotes}
                              </div>
                            </div>
                          </div>
                        : null}
                      </div>
                    </>))}

                    

                  
              </React.Fragment>
            ) : (
              ""
            )}
            {statuss === SHIFT_IN_PROGRESS && statuss !== PENDING_STAFF_COMPLETE ? (
              <div className="d-flex align-items-center justify-content-end w-100 mt-5">
                <div className="d-flex">
                  {/* Old */}
                  {/* {showPause && (
                    <NormalButton
                      onClick={() => {
                        hanldePause(moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"));
                      }}
                      label="Pause"
		      id="pauseClock"
                      outline={false}
                      className=" btn fs-14 pause-btn px-3"
                      disabled={openModal}
                    />
                  )} */}
                  {/* {!showPause && (
                    <NormalButton
                      onClick={() => {
                        hanldeResume(moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"));
                      }}
                      label="Resume"
		      id="resumeClock"
                      outline={false}
                      className=" btn fs-13 text-white bg-c20 px-3"
                      disabled={openModal}
                    />
                  )} */}
                </div>
                {/* <div className="d-flex">
                  <NormalButton
                    onClick={() => {
                      handleCheckOut(moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"));
                    }}
                    label="Clock-Out"
		    id="checkOut"
                    outline={false}
                    className=" btn fs-14 text-white clock-out-btn px-3"
                  />
                </div> */}
              </div>
            ) : (
              ""
            )}
          </ul>
          {/* New */}
          {statuss === SHIFT_IN_PROGRESS && statuss !== PENDING_STAFF_COMPLETE &&
            <div className="sticky-footer pause-clock-in-out-buttons">
            {statuss === SHIFT_IN_PROGRESS && statuss !== PENDING_STAFF_COMPLETE ? 
              <div className="d-flex flex-row pause-clockout-cont">
                  {showPause && (
                      <NormalButton
                        onClick={() => {
                          if(!pauseLoading) {
                            hanldePause(moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"));
                          }
                        }}
                        label="Pause"
            id="pauseClock"
                        outline={false}
                        className=" btn cta-2-r  px-3"
                        disabled={openModal || pauseLoading}
                        loader={pauseLoading}
                        loaderColor="#F15C5C"
                      />
                    )}
                    {!showPause && (
                      <NormalButton
                        onClick={() => {
                          if(!resumeLoading){
                          hanldeResume(moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"));
                          }
                        }}
                        label="Resume"
            id="resumeClock"
                        outline={false}
                        className=" btn cta-2-r px-3"
                        disabled={openModal || resumeLoading}
                        loader={resumeLoading}
                        loaderColor="#F15C5C"
                      />
                    )}
                    <div className="d-flex">
                    <NormalButton
                      onClick={() => {
                        handleCheckOut(moment().format("YYYY-MM-DD[T]HH:mm:ss.SSSS"));
                      }}
                      label="Clock-Out"
          id="checkOut"
                      outline={false}
                      className={`${clockOutLoading && 'not-allowed'} btn cta-2-m text-white clock-out-btn px-3`}
                      loader={clockOutLoading}
                      loaderColor="white"
                      disabled={clockOutLoading}
                    />
                  </div>
              </div>
            : ""}
            </div>
          }
        </React.Fragment>
      ) : (
        <h6 className="font-weight-bold "></h6>
      )}

      {/* {data.workStaffAssignment &&
      data.workStaffAssignment.staffWorkData &&
      data.workStaffAssignment.staffWorkData.length > 0 &&
      data.workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      ).length > 0 &&
      data.workStaffAssignment.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      )[0].status >= 5 || (data.status === 0 && data.workStaffAssignment?.staffWorkData[0]?.clockIn
        ) && <StaffDetail staffWorkData={staffWorkData} work={data} />} */}
      {statuss === PENDING_STAFF_COMPLETE ? (
        <React.Fragment>
           {/* <div className="text-center">
          <p className="text-gray">
            <i>*Can't edit the timesheet after staff completes work</i>
          </p>
        </div> */}
        <div className="d-flex align-items-center justify-content-between pl-5 w-100 hazard-pay-cont">
        
        <div className="d-flex flex-row align-items-center " style={{gap:'10px'}}>
        <div className=" pb-3 ">
       <input className="hazardpay_switch" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleHazardPay}/>
       <label className="hazardpay_label" for="flexSwitchCheckDefault"></label>
       </div>
       <span className="sub-text s1-m " for="flexSwitchCheckDefault">Claim Hazard Pay ?</span>
       </div>
       
       
      {hazardPay.claimHazardPay ? (
        <input
        style={{height:'40px', width:'350px'}}
        className="rounded pl-1 text-black s3-m hazardpay-text-input"
          type="text"
          value={hazardPay.hazardPayReason}
          onChange={(e)=> setHazardPay({...hazardPay, hazardPayReason:e.target.value})}
          placeholder="Hazard Reason (Max 50 Characters)"
          maxLength={50}
        />
      ): null}
        </div>
            {/* <div className="d-flex">
              <NormalButton
                label="EDIT SHEET"
                outline={false}
                className="mb-2 btn fs-14 btn-dark-primary-border btn-block "
                disabled={openModal}
                onClick={() => {
                  setVisible(!visible);
                }}
              />
            </div> */}
{/*             
            <div className="">
                        <input
                            style={{height:'40px', width:'86%'}}
                            className="pl-1 text-black fs-13 special-notes-input"
                            placeholder="Enter special notes (if any)..."
                            type="text"
                            rows={1}
                            onChange={(e)=> setstaffNotes(e.target.value)}
                            maxLength={50}
                        />
                        validator.current.message("Note", notes, "required")
                    </div> */} 

<div>
                        <div class="frame-2405" style={{padding:"0 0 0 141px", gap:"24px"}} >
  <div class="frame-2399">
    <div class="staff-notes s1-m">Staff Notes</div>
  </div>
  <div class="">
    <input
                            style={{height:'40px', width:'350px'}}
                            className="pl-1 text-black s3-m special-notes-input"
                            placeholder="Enter special notes (if any)..."
                            type="text"
                            rows={1}
                            onChange={(e)=> setstaffNotes(e.target.value)}
                           />

    
  </div>
</div> 
</div>
                    <div className="line-74"></div>
                {/* <NormalButton
                  onClick={() => {
                    handleComplete();
                  }}
                  label="SUBMIT"
            id="submitTime"
                  outline={false}
                  className="mb-2 btn fs-10 btn-block btn-dark-primary "
                /> */}
                  {/* Old */}
                  {/* <div class="frame-2286-3 cursor-pointer" onClick={() => {
                      handleComplete();
                    }}>
                    <div class="submit-staff">Submit</div>
                  </div> */}
                  {/* New */}
                  <div className="sticky-footer d-flex justify-content-end">
                    <div style={{opacity: submitLoading ? 0.5 : 1, cursor: submitLoading ? "not-allowed" : "pointer"}} className="frame-2286-3 cursor-pointer" onClick={() => {
                        if(!submitLoading) {
                          setOpenConfirmAlert(true)
                          // handleComplete();
                        }
                      }}>
                      <div className="submit-staff d-flex align-items-center cta-1-m">Submit {submitLoading ? <CustomLoader color="white"/> : null}</div>
                    </div>
                  </div>
        </React.Fragment>
      ) : (
        ""
      )}
      {openModal && (
        <ResumeModel
          toggle={() => {
            setOpenModal(!openModal);
            // handleReload();
            setWorkAssign({value: true, id: data._id});
          }}
          isOpen={openModal}
          setActivePointsFromStaffDetails={setActivePointsFromStaffDetails}
          data={data}
        />
      )}
      {/* <GoogleMapsModel data={data} isOpen={openGoogleMaps} toggle={() => setOpenGoogleMaps(!openGoogleMaps)} /> */}
      {visible && (
        <ManualTimeSheet
          view={visible}
          timesheetData={data}
          setVisible={() => setVisible(!visible)}
          setVisibleEdit={() => setVisibleSheet(!visibleSheet)}
        />
      )}
      {                
        openConfirmAlert && <ConfirmTimeSheetModal toggle={() => setOpenConfirmAlert(!openConfirmAlert)}
        isOpen={openConfirmAlert}
        handleAction={(e) => handleCancelAction(e)}
        customText={"permanently"}
        />
      }
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      pauseWorkByStaff,
      staffClockOut,
      completeWorkByStaff,
      resumeWorkByStaff,
    },
    dispatch,
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(withRouter(TimeSheetMark));

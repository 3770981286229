import React from 'react';
import "./style.scss";

export const TourSwitch = ({
    className = "custom-checkbox",
    label = "",
    value = "",
    name = "",
    onChange,
    checked,
    disable = false,
}) => {

    return (
        <div className="d-flex align-items-center">
            <label className="tour-switch">
                <input type="checkbox"
                name={name}
                value={value}
                checked={checked}
                disabled={disable}
                onChange={({ target: { name, checked: Checked } }) => {
                    onChange?.({ target: { name, value: Checked } })
                }}
                />
                <span className="tour-slider tour-round"></span>
                
            </label>
            {label ? <span className="label-txt fs-13 pl-2 ml-1">{label}</span> : ""}
        </div>
    )

}
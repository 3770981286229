import React,{ useReducer, useEffect, useState,useMemo } from 'react';
import { cloneDeep } from "lodash"
const customHookActionType = {
    updateStateWithKey:'updateStateWithKey',
    bulkUpdate:'bulkUpdate',
    reset:'reset',
}


function reducer(state, action) {
    switch (action.type) {
      case customHookActionType.updateStateWithKey:
        return {
            ...state,
             [action.payload.name]:action.payload.value
        };
      case customHookActionType.bulkUpdate:
        return {
            ...state,
            ...action.payload
        }
      case customHookActionType.reset:
        return action.payload
      default:
        throw new Error('No Action Type');
    }
  }

export const  useCustomReducer = (initialState) => {

    const [ originalState,updateOriginalState ] = useState(null)

    useEffect(()=>{
        updateOriginalState(cloneDeep(initialState))
    },[])
    
    const [state, dispatch] = useReducer(reducer, initialState);

    const customDispatch = (data) =>{
        if(data.type === customHookActionType.reset ){
            dispatch({ ...data.type,payload:cloneDeep(originalState) })
        }else{
            dispatch(data)
        }
        
    }

    return [ state,customDispatch,customHookActionType]
    

}


export const useComponentWillMount = (func) => {
  useMemo(func, [])
}
import React, {useEffect, useState} from 'react';
import './navbar.scss';
//import {pageNavigationByName} from 'service/helpers';
import {getNotificationsCout} from '../../../action/notification';
import {bindActionCreators} from 'redux';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getSettingsById, getStaffById} from 'action/SettingsAct';
import {logout} from 'service/utilities';
import nav_bell_grey from '../../../assets/svg/notificationGrey.svg';
import nav_bell from '../../../assets/svg/mdi_notifications.svg';
import nav_user from '../../../assets/svg/user.svg';
import logoutt from '../../../assets/svg/logout.svg';
import menu from '../../../assets/svg/menuPink.svg';
import { startTourGuide } from 'reducer/tourGuide';
import { TourSwitch } from '../TourGuide/TourSwitch';
import DisplayStaffImage from '../../../pages/Admin/Settings/DisplayStaffImage/index';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DisplayImage from '../DisplayImage';
import { useMediaQuery } from '@mui/material';
import { history } from 'service/helpers';
//import logo from '../../../assets/svg/Staff IQ.svg';

// let notificationCount = localStorage.getItem('notifications') !== null ? localStorage.getItem('notifications') : null;
function Navbar(props) {

  const dispatch = useDispatch();
  const [count, setCount] = useState(null);
  const [, setLoading] = useState(false);
  const [userInfo, setuserInfo] = useState({});
  const [userLogo, setUserLogo] = useState({});
  const [userName,setUserName] = useState('userData' in localStorage ? JSON.parse(localStorage.getItem('userData')).firstname : '');
  const [userMiddleName,setUserMiddleName] = useState('userData' in localStorage ? JSON.parse(localStorage.getItem('userData')).middleName : '');
  const [userLastName,setUserLastName] = useState('userData' in localStorage ? JSON.parse(localStorage.getItem('userData')).lastName : '');
  // const [notificationCallCounter, setNotificationCallCounter] = useState(0);
  const isMediumScreen = useMediaQuery('(min-width:830px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:960px) and (max-width: 1140)');
  const isXLargeScreen = useMediaQuery('(min-width: 1141px)');
  const charLimit = isMediumScreen ? 12 : isLargeScreen ? 25 : isXLargeScreen ? 40 : 20;
  window.addEventListener(['userName','userMiddleName','userLastName'], () => {
    setUserName(JSON.parse(localStorage.getItem('userData')).firstname);
    setUserMiddleName(JSON.parse(localStorage.getItem('userData')).middleName);
    setUserLastName(JSON.parse(localStorage.getItem('userData')).lastName);
}, {passive: true})
let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)

  useEffect(()=>{
    setCount(notificationCount)
  },[notificationCount]);

  useEffect(()=>{
    getNotifyCount()
  },[]);

  useEffect(() => {

    if ('userData' in localStorage) {
      let userData = JSON.parse(localStorage.getItem('userData'));
      let id = userData.agencyId ? userData.agencyId : userData.id;
      // console.log("ID :"+id);
      let query = {
        id,
      };
      setLoading(true);
      dispatch(getSettingsById(query))
        .then((res) => {
          if (res) {
            setuserInfo(res);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
        if(history.location.state?.from === 'login'){
          setUserLogo(userData)
          return;
       }
          history.replace({},undefined)
       
        setLoading(true);
           if(id !== userData.id){
             id = userData.id ? userData.id : userData.userId;
             let query = {
               id
             }
              dispatch(getStaffById(query))
              .then((res) => {
                if (res) {
                  setUserLogo(res);
                  setLoading(false);
                }
              })
              .catch(() => {
                setLoading(false);
              });
            }
    }else logout();
  }, []);

  const getNotifyCount = async () => {
    setLoading(true);
    await props
      .getNotificationsCout({})
      .then((data) => {
        setCount(data.count);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  
  let tourGuideData = useSelector((state) => state.tourGuideReducer.tourGuideData);
   const handleTour = ({target:{value}}) => {
    dispatch(startTourGuide({run:value}))
    if(props.sideBarClickStatus) {
      props.handleSideBar()
      }
  }
  let isConnected = useSelector((state) => state.webSocketReducer.isConnected);

  return (
    <React.Fragment>
      <div className="navbar fixed-top p-2">
        <div className="d-flex justify-content-end w-100 align-items-center" >
        <div className="mr-4 h-100 d-flex align-items-center pr-3 nav-logo hover-agency"  >
            <img src={menu} onClick={props.handleNav} className=" h-auto mb-3 mr-2 cursor-pointer menu-icon" alt="menu" width={21} height={15} /> 
            {userInfo.companyLogo ? <DisplayImage navbar={true} hideNA={true} imageId={userInfo.companyLogo}/> : null }
            <span className="extra-hover mx-2">{userInfo ? userInfo.agencyId ? userInfo.firstname : userInfo.agencyName : "User Name"}</span>
            {/* <img src={userInfo.companyLogo ? userInfo.companyLogo : ""} className="mb-3 logo-pic" alt=""  /> */}
            {/* <p className="ml-3 text-violet">powered by Staff IQ</p> */}
          </div>
          <p className="position-absolute w-100 text-center h2-sb text-uppercase align-items-center h2-sb nav-responsive" style={{zIndex:-1}}  >{userInfo ? userInfo.agencyId ? userInfo.firstname : userInfo.agencyName?.substring(0, charLimit) + (userInfo.agencyName?.length > charLimit ? '...' : '') : "User Name"}</p>

          <div className='mr-5 mb-3 cta-2-m globalSwitch' >
          <TourSwitch
           checked={tourGuideData.run}
           onChange={handleTour}
          />
        </div> 

          <div className="mr-3 pr-3 position-relative">
            <img
            alt="navBell"
              src={`${props.sideBarClickStatus?nav_bell:nav_bell_grey}`}
              height={20}
              width={20}
              className="mb-3 cursor-pointer"
              onClick={() => {
                setCount(0);
                // localStorage.setItem('notifications', 0);
                props.handleSideBar();
  }}
              //   const newTab = window.open('notification', '_blank');
              //   if (newTab != null) {
              //     newTab.focus();
              //   }
              // }}
            />
            <span className="badge my-badge">{count ? count : ''}</span>
          </div>
        
          <div className="d-flex align-items-center pr-3 position-relative hover-icon">
         
          <OverlayTrigger placement="left" overlay={<Tooltip >{isConnected ? 'You are now connected to get updates instantly': 'You are now disconnected to get updates instantly, Please refresh'}</Tooltip>}>
          <div className='d-flex align-items-center  position-absolute' style={{bottom:'20px', left:'-7px'}}>
             <div className={`${isConnected ? 'connected': 'bg-danger'} cursor-pointer`} style={{borderRadius:'50%', minWidth:'15px', minHeight:'15px', border:'2px solid white'}}></div>
             </div>
             </OverlayTrigger>
          
            {/* <img
              alt="userLogo"
              src={
                userLogo ? userLogo.profileLink === 'http://localhost' ||
                userLogo.profileLink === '' || !userLogo.profileLink ||
                userLogo.profileLink === null ? (
                  nav_user
                ) : (
                  userLogo.profileLink.split("|").length>1 && userLogo.profileLink.split("|")[1]
                ) : (
                  nav_user
                )
              }
              className="profile-nav rounded-circle mb-3"
            /> */}
            {userLogo?.profileLink ? userLogo?.profileLink.includes('data:image/') ? <img
              alt="userLogo"
              src={
                userLogo ? userLogo?.profileLink === 'http://localhost' ||
                userLogo?.profileLink === '' || !userLogo?.profileLink ||
                userLogo?.profileLink === null ? (
                  nav_user
                ) : (
                  userLogo?.profileLink.split("|").length>1 && userLogo?.profileLink.split("|")[1]
                ) : (
                  nav_user
                )
              }
              className="profile-nav rounded-circle mb-3"
            />  : <DisplayStaffImage navbar={true} imageId={userLogo?.profileLink}/> : 
              <img
                alt="userLogo"
                src={nav_user}
                className="profile-nav rounded-circle mb-3"
            />
            }
           <span className="extra-hover mx-2">{userName !== '' || userMiddleName !== '' || userLastName !=='' ? userName + " "+userMiddleName+" "+ userLastName :'User Name'}</span>
          </div>
          <div className="d-flex align-items-start mr-3 cursor-pointer" onClick={() => logout(true)}>
            <div className="vert-line mr-3" />
            <img className="" fetchPriority="high" src={logoutt} alt="user" width={17} height={17} />
            <p className="ml-2 mb-3  cta-2-m text-light-violet logout-text">Logout</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotificationsCout,
    },
    dispatch,
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(Navbar);

import React, { useState, useEffect } from 'react'
import classes from './interactive_map.module.scss'
import TimelineMap from './timelineMap'
import Map from './map'
import { entryType } from './constants'


export default function InteractiveMap({ open, setOpen, activePointsFromStaffDetails, data = [],sitePicture,clientLocLatLong }) {
    const [staffLatLong, setStaffLatLong] = useState({
        lat: '',
        lng: ''
    });
    const [clientLatLong, setClientLatLong] = useState({
        lat: '',
        lng: ''
    });
    const [timeData, setTimeData] = useState([]);

    const [activePoint, setActivePoint] = useState('');
    const [showLines, setShowLines] = useState(true);
    const [warningModal, setWarningModal] = useState({
        show: false,
        msg:'! No Location Found'
    });

    useEffect(() => {
        if(activePointsFromStaffDetails===-1){
          setActivePoint(timeData.length);
          setShowLines(false);
        }else if(activePointsFromStaffDetails || activePointsFromStaffDetails===0){
          setActivePoint(activePointsFromStaffDetails);
          setShowLines(false);
        }
      }, [activePointsFromStaffDetails])

    const formatData = (details=[]) => {
        let temp = [];

        if(clientLocLatLong){
            setClientLatLong({
            lat: clientLocLatLong?.[1],
            lng: clientLocLatLong?.[0],
            });
        }
        // clock-in
        details?.clockIn && temp.push(
            {
                label: entryType.CLOCK_IN,
                date: new Date(details?.clockIn),
                lat: details?.clockInLongLat?.[1],
                lng: details?.clockInLongLat?.[0],
            }
        );

        // pause resume 
        details?.pauseResumeData?.map(el => {
            if (el?.key === 'pause') {
                temp.push({
                    label: entryType.PAUSED,
                    date: new Date(el?.time),
                    lat: el?.longLat?.[1],
                    lng: el?.longLat?.[0]
                });
            } else if (el?.key === 'resume') {
                temp.push({
                    label: entryType.RESUMED,
                    date: new Date(el?.time),
                    lat: el?.longLat?.[1],
                    lng: el?.longLat?.[0]
                });
            }
        })

        // clock-out
       details?.clockOut && temp.push({
            label: entryType.CLOCK_OUT,
            date: new Date(details?.clockOut),
            lat: details?.clockOutLongLat?.[1],
            lng: details?.clockOutLongLat?.[0],
        });

        setStaffLatLong({
            lat: details?.longLat?.[1],
            lng: details?.longLat?.[0]
        });
        setTimeData([...temp]);
    }

    useEffect(() => {
        if (data?.length) {
            let staffId = JSON.parse(localStorage.getItem("userData"))?.id;
    
            let staff_data = data?.find(el => el?.staffId?.toString() === staffId?.toString());
            if (staff_data) formatData(staff_data);
        }
    },[data])
    return open && (
        <div className={classes.interactive_map}>
            <div className={classes.container}>
                <div className={classes.close_button}>
                    <h2>Map View</h2>
                    <button onClick={ ()=>setOpen(prev=>!prev)}>×</button>
                </div>
                <div className={classes.map_action_wrapper}>
                    <div className={classes.map_wrapper}>
                        <Map
                            mapData={timeData}
                            staffLatLong={staffLatLong}
                            clientLatLong={clientLatLong}
                            sitePicture={sitePicture}
                            activePoint={activePoint}
                            showLines={showLines}
                            setActivePoint={setActivePoint}
                            warningModal={warningModal}
                            setWarningModal={setWarningModal}
                        />
                    </div>
                    <div className={classes.action_wrapper}>
                        <div> 
                            <p className={classes.shift_title}>My Shift Entry Details</p>
                            <label className={classes.show_lines}>
                                <input
                                    type="checkbox"
                                    checked={showLines}
                                    onChange={(e)=>setShowLines(e.target.checked)}
                                />
                                Show Lines
                            </label>
                            <hr/>
                            <div>
                                <TimelineMap
                                    timelineArray={timeData}
                                    activePoint={activePoint}
                                    setActivePoint={setActivePoint}
                                    mapPointHandler={(lat, lng) => {
                                        if (!lng || !lat) setWarningModal(prev => ({ ...prev, show: true }));
                                        // else console.log(`latitude : ${lat} , longitude : ${lng}`);
                                    }}
                                />
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


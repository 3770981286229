import React from 'react';

import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export const NormalEditor = ({label = '', onChange, data = ''}) => {
  return (
    <div className="mt-4 pt-2">
      {label !== '' ? <div className="fs-17 text-black font-Gilroy pb-2 mb-1 line-height-20">{label}</div> : ''}

      <CKEditor
        editor={DecoupledEditor}
        data={data}
        onInit={(editor) => {
          editor.setData(data);

          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
        }}
        onChange={(event, editor) => {
          onChange(editor);
        }}
        config={{
          toolbar: [
            'heading',
            '|',
            'Bold',
            'Italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'Link',
            '|',
            'alignment',
            '|',
            'Indent',
            'outdent',
            '|',
            'undo',
            'redo',
          ],
        }}
      />
    </div>
  );
};

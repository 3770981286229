export const reportsPageSteps = [
    {
        target:'h4',
        content:'Reports and Earning related matrix..',
        placement:'bottom',
        disableBeacon: true,
        disableScrolling:false,
    },
    {
        target:'#coAdmin',
        content:'Export CSV file here..',
        placement:'bottom',
        disableScrolling:false,
    },
    {
        target:'#report-workinghours',
        content:'You Can view and download the report for Total working hours..',
        placement:'top-end',
        disableScrolling:false,
    },
    {
        target:'#report-tasks',
        content:'You can view & download the Graph report related to completed and assigned task',
        placement:'auto',
        disableScrolling:false,
    },
    {
        target:'#report-shifts',
        content:'Here you can view the details of upcoming shifts.',
        placement:'auto',
        disableScrolling:false,
    },
    {
        target:'#report-served',
        content:'Reports tells the details of clients you have served.',
        placement:'auto',
        disableScrolling:false,
    },

]
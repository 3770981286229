import React, {useEffect, useState} from 'react';
import {NormalButton, NormalModal} from 'component/common';
import './style.scss';
import timer from '../../../assets/svg/timer.png';
import moment from 'moment';
import {resumeWorkByStaff} from 'action/workRequest';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { msToTime} from 'service/utilities';
import { getCurrentLocation } from 'service/utilities/getCurrentLocation';
import startLocationTracking from 'service/utilities/startLocationTracking';

function ResumeModel({isOpen, toggle, resumeWorkByStaff, data}) {
  let [initialTime] = useState(new Date().getTime());
  let [currentTime, setCurrentTime] = useState(new Date().getTime());

  const [hiddenTime, setHiddenTime] = useState(null);

  useEffect(() => {
    let timerInterval;
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setHiddenTime(Date.now()); 
        clearInterval(timerInterval);
      } else {
        if (hiddenTime !== null) {
          const elapsedTime = Date.now() - hiddenTime; 
          setCurrentTime(prevTime => prevTime + elapsedTime)
          setHiddenTime(null);
        }
      }
    };

    timerInterval = setInterval(() => {
      setCurrentTime(prevTime => prevTime + 1000);
    }, 1000);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(timerInterval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hiddenTime]);


  const handleResume = async (time) => {
    let currrentLocation = await getCurrentLocation();

    let prLongLat;
    if (currrentLocation.status) { 
      prLongLat = [currrentLocation.longitude,currrentLocation.latitude]
    } else {
      //let staffId = JSON.parse(localStorage.getItem("userData"))?.id;
      // let staff_data = data?.workStaffAssignment?.staffWorkData?.find(el => el?.staffId?.toString() === staffId?.toString());
      prLongLat = (data?.longLat?.length) ? [...data.longLat] : [];
    }

    let body = {
      workId: data._id,
      clientId: data.clientId,
      pauseResumeTime: time,
      pauseResumeLongLat:prLongLat,
      clientSiteTimeZone: data.clientSiteTimeZone,
      geoHistogram:{
        longLat:prLongLat,
        timeStamp:time,
        type:3
    },
    };
    await resumeWorkByStaff(body)
      .then((res) => {
        if(res){
          let user = JSON.parse(localStorage.getItem('userData'))
          let geoUpdateInterval = user?.geoUpdateInterval
          let enableGeoTracking = user?.enableGeoTracking
          if(enableGeoTracking){
            startLocationTracking({
              id:res,
              staffId:user?.id,
              type:5,
              agencyId:data?.agencyId,
              timeZone:data?.clientSiteTimeZone
            },
            Number(geoUpdateInterval) > 0 ? geoUpdateInterval * 1000 : 60000
            )
          }
        }
        toggle(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <NormalModal
      className={'modal-fill shadow'}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      close="no"
      size="md"
      bodyClass="add-staff-box"
      headerClass="border-0 pause-alert"
      centered={true}>
      <React.Fragment>
        <div className=" text-center mb-5 px-5">
          <p>
            <img src={timer} alt="timer" />
          </p>
          <h2 className="text-center text-white pb-3">
            {msToTime(currentTime - initialTime).hours +
              ' : ' +
              msToTime(currentTime - initialTime).minutes +
              ' : ' +
              msToTime(currentTime - initialTime).seconds}
          </h2>
          <div className="d-flex justify-content-center">
            <NormalButton
              label="Resume"
              outline={false}
              className=" btn fs-14 text-black bg-white px-3"
              onClick={() => {
                handleResume(moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSS'));
              }}
            />
          </div>
        </div>
      </React.Fragment>
    </NormalModal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resumeWorkByStaff,
    },
    dispatch,
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(withRouter(ResumeModel));

import axios from "axios";
import { getUserLoginCount } from "action/AuthAct";
//import {history} from "../service/helpers";

import {
  NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM,
  SHIFT_CONFIRMED_CLOCK_IN,
  PENDING_AGENCY_APPROVAL,
  PENDING_CLIENT_APPROVAL,
  SHIFT_IN_PROGRESS,
  PENDING_TIMESHEET_REVIEW,
  CLIENT_REVIEW_COMPLETED,
  PENDING_STAFF_COMPLETE,
  AGENCY_REVIEW_COMPLETED,
  TIMESHEET_APPROVED_FOR_PAYROLL,
  DRAFT,
  PENDING,
  REJECTED,
  APPROVED,
  SHOWN_INTERESTED,
  NEW_SHIFT_AVAILABLE,
  HOLD_WORK,
  REJECTED_BY_STAFF,
  REJECTED_BY_CLIENT,
  REJECTED_BY_AGENCY,
  CANCELLED_WORK,
  NOT_FILLED,
} from "./constants";
import { logOut } from "action/AuthAct";
import { store } from "./helpers";

//var feURL = 'http://staffiqdev.qutrix.io';
//var feURL = 'http://staffatom.com';
var feURL = process.env.REACT_APP_FEURL? process.env.REACT_APP_FEURL : 'http://staffiqdev.qutrix.io';
var hostMode = process.env.REACT_APP_HOSTMODE? process.env.REACT_APP_HOSTMODE : 'LOCAL';

export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
});

export const logout = async(value) => {
  if(value){
    let auditId = localStorage.getItem('auditId')
    await store.dispatch(logOut({auditId:auditId ? auditId : null}))
    .then(()=>{
      localStorage.clear()
      let redirectURL = '';
      if ( hostMode == 'LIVE' ) {
        redirectURL = (feURL) + '/auth/login';
      } else {
        redirectURL = (feURL) + ':2238/auth/login';
      }
      window.location.assign(redirectURL);
    })
    .catch((err)=>{
      logout()
    })
    return;
  }
  localStorage.clear()
  let redirectURL = '';
  if ( hostMode == 'LIVE' ) {
    redirectURL = (feURL) + '/auth/login';
  } else {
    redirectURL = (feURL) + ':2238/auth/login';
  }
  window.location.assign(redirectURL);
}
// get work status
export const getWorkStatus = (
  workStatus = -1,
  staffStatus = -1,
  clockIn = undefined,
  clockOut = undefined,
  interested = false,
  holdWork = false,
  rejectWork = false,
  rejectType = 0,
) => {
  let status = "";
  // console.log("work", workStatus, "interested", interested, "staffstatus", staffStatus);
  if (rejectWork && rejectType === 6) {
    status = REJECTED_BY_STAFF;
  } else if (rejectWork && rejectType === 7 && staffStatus === 3) {
    status = REJECTED_BY_CLIENT;
  } else if ((rejectWork && rejectType === 3) || rejectType === 4 || rejectType === 5) {
    status = REJECTED_BY_AGENCY;
  } else if (workStatus === 0) {
    status = CANCELLED_WORK;
  } else if (workStatus === 2 && !interested && staffStatus === -1) {
    status = NEW_SHIFT_AVAILABLE;
  } else if (workStatus === 2 && interested && staffStatus === -1) {
    status = SHOWN_INTERESTED;
  } else if (staffStatus === 1 && holdWork) {
    status = HOLD_WORK;
  } else if (staffStatus === 1 && !holdWork) {
    status = NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM;
  } else if (staffStatus === 2) {
    status = PENDING_AGENCY_APPROVAL;
  } else if (staffStatus === 3) {
    status = PENDING_CLIENT_APPROVAL;
  } else if (staffStatus === 4 && typeof clockIn === "undefined" || staffStatus === 4 && rejectWork && clockIn === "undefined" && rejectType ===7) {
    status = SHIFT_CONFIRMED_CLOCK_IN;
  } else if (
    staffStatus === 4 &&
    workStatus >= 4 &&
    typeof clockIn !== "undefined" &&
    typeof clockOut !== "undefined"
  ) {
    status = PENDING_STAFF_COMPLETE;
  } else if (staffStatus === 4 && typeof clockIn !== "undefined") {
    status = SHIFT_IN_PROGRESS;
  } else if (
    staffStatus === 5 &&
    (workStatus === 4 || workStatus === 3) &&
    typeof clockIn !== "undefined" &&
    typeof clockOut !== "undefined"
  ) {
    status = PENDING_TIMESHEET_REVIEW;
  } else if (workStatus === 5 && staffStatus === 5 && typeof clockOut !== "undefined") {
    status = PENDING_TIMESHEET_REVIEW;
  } else if (workStatus === 6 && staffStatus >= 5) {
    status = CLIENT_REVIEW_COMPLETED;
  } else if (workStatus === 7 && staffStatus >= 5) {
    status = AGENCY_REVIEW_COMPLETED;
  } else if (workStatus === 8 || workStatus === 9 && staffStatus >= 0) {
    status = TIMESHEET_APPROVED_FOR_PAYROLL;
  }
  else if (workStatus === 10) {
    status = NOT_FILLED;
  }
  // else{
  //   status =SHIFT_IN_PROGRESS
  // }
  return status;
};


export const getClassNameForBackground = (
  status
)=>{
  var tempBgOfSelectedStatus = "";
  if(status===SHIFT_IN_PROGRESS) tempBgOfSelectedStatus = "wr-clock-in";
  else if(status===HOLD_WORK) tempBgOfSelectedStatus = "wr-hold-work";
  else if(status===AGENCY_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-completed";
  else if(status===PENDING_STAFF_COMPLETE) tempBgOfSelectedStatus = "wr-pending-staff-complete";
  else if(status===PENDING_TIMESHEET_REVIEW) tempBgOfSelectedStatus = "wr-pending-client-complete";
  else if(status===CLIENT_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-pending-agency-complete";
  else if(status===PENDING_CLIENT_APPROVAL) tempBgOfSelectedStatus = "wr-pending-client-approval";
  else if(status===SHIFT_CONFIRMED_CLOCK_IN) tempBgOfSelectedStatus = "wr-pending-clock-in";
  else if(status===NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM) tempBgOfSelectedStatus = "wr-yet-to-start";
  else if(status===TIMESHEET_APPROVED_FOR_PAYROLL) tempBgOfSelectedStatus = "wr-invoice-generated";
  else if(status===PENDING_AGENCY_APPROVAL) tempBgOfSelectedStatus = "wr-pending-agency-approval";
  else if(status===NEW_SHIFT_AVAILABLE) tempBgOfSelectedStatus = "wr-new-shift";
  else if(status===SHOWN_INTERESTED) tempBgOfSelectedStatus = "wr-shown-interest";
  else if(status===CANCELLED_WORK) tempBgOfSelectedStatus = "wr-cancelled";
  else if(status===REJECTED_BY_STAFF) tempBgOfSelectedStatus = "wr-rejected-by-staff";
  else if(status===REJECTED_BY_CLIENT) tempBgOfSelectedStatus = "wr-rejected-by-client";
  else if(status===REJECTED_BY_AGENCY) tempBgOfSelectedStatus = "wr-rejected-by-agency";
  return tempBgOfSelectedStatus;
}
export const getClassNameForText = (
  status
)=>{
  var tempBgOfSelectedStatus = "";
  if(status===SHIFT_IN_PROGRESS) tempBgOfSelectedStatus = "wr-clock-in-text";
  else if(status===HOLD_WORK) tempBgOfSelectedStatus = "wr-hold-work-text";
  else if(status===AGENCY_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-completed-text";
  else if(status===PENDING_STAFF_COMPLETE) tempBgOfSelectedStatus = "wr-pending-staff-complete-text";
  else if(status===PENDING_TIMESHEET_REVIEW) tempBgOfSelectedStatus = "wr-pending-client-complete-text";
  else if(status===CLIENT_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-pending-agency-complete-text";
  else if(status===PENDING_CLIENT_APPROVAL) tempBgOfSelectedStatus = "wr-pending-client-approval-text";
  else if(status===SHIFT_CONFIRMED_CLOCK_IN) tempBgOfSelectedStatus = "wr-pending-clock-in-text";
  else if(status===NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM) tempBgOfSelectedStatus = "wr-yet-to-start-text";
  else if(status===TIMESHEET_APPROVED_FOR_PAYROLL) tempBgOfSelectedStatus = "wr-invoice-generated-text";
  else if(status===PENDING_AGENCY_APPROVAL) tempBgOfSelectedStatus = "wr-pending-agency-approval-text";
  else if(status===NEW_SHIFT_AVAILABLE) tempBgOfSelectedStatus = "wr-new-shift-text";
  else if(status===SHOWN_INTERESTED) tempBgOfSelectedStatus = "wr-shown-interest-text";
  else if(status===CANCELLED_WORK) tempBgOfSelectedStatus = "wr-cancelled-text";
  else if(status===REJECTED_BY_STAFF) tempBgOfSelectedStatus = "wr-rejected-by-staff-text";
  else if(status===REJECTED_BY_CLIENT) tempBgOfSelectedStatus = "wr-rejected-by-client-text";
  else if(status===REJECTED_BY_AGENCY) tempBgOfSelectedStatus = "wr-rejected-by-agency-text";
  return tempBgOfSelectedStatus;
}

export const getClassNameForLeftBorder = (
  status
)=>{
  var tempBgOfSelectedStatus = "";
  if(status===SHIFT_IN_PROGRESS) tempBgOfSelectedStatus = "wr-clock-in-left-border";
  else if(status===HOLD_WORK) tempBgOfSelectedStatus = "wr-hold-work-left-border";
  else if(status===AGENCY_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-completed-left-border";
  else if(status===PENDING_STAFF_COMPLETE) tempBgOfSelectedStatus = "wr-pending-staff-complete-left-border";
  else if(status===PENDING_TIMESHEET_REVIEW) tempBgOfSelectedStatus = "wr-pending-client-complete-left-border";
  else if(status===CLIENT_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-pending-agency-complete-left-border";
  else if(status===PENDING_CLIENT_APPROVAL) tempBgOfSelectedStatus = "wr-pending-client-approval-left-border";
  else if(status===SHIFT_CONFIRMED_CLOCK_IN) tempBgOfSelectedStatus = "wr-pending-clock-in-left-border";
  else if(status===NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM) tempBgOfSelectedStatus = "wr-yet-to-start-left-border";
  else if(status===TIMESHEET_APPROVED_FOR_PAYROLL) tempBgOfSelectedStatus = "wr-invoice-generated-left-border";
  else if(status===PENDING_AGENCY_APPROVAL) tempBgOfSelectedStatus = "wr-pending-agency-approval-left-border";
  else if(status===NEW_SHIFT_AVAILABLE) tempBgOfSelectedStatus = "wr-new-shift-left-border";
  else if(status===SHOWN_INTERESTED) tempBgOfSelectedStatus = "wr-shown-interest-left-border";
  else if(status===CANCELLED_WORK) tempBgOfSelectedStatus = "wr-cancelled-left-border";
  else if(status===REJECTED_BY_STAFF) tempBgOfSelectedStatus = "wr-rejected-by-staff-left-border";
  else if(status===REJECTED_BY_CLIENT) tempBgOfSelectedStatus = "wr-rejected-by-client-left-border";
  else if(status===REJECTED_BY_AGENCY) tempBgOfSelectedStatus = "wr-rejected-by-agency-left-border";
  return tempBgOfSelectedStatus;
}

export const getClassNameForstatusicon = (
  status
)=>{
  var tempBgOfSelectedStatus = "";
  if(status===SHIFT_IN_PROGRESS) tempBgOfSelectedStatus = "wr-clock-in-status-icon";
  else if(status===HOLD_WORK) tempBgOfSelectedStatus = "wr-hold-work-status-icon";
  else if(status===AGENCY_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-completed-status-icon";
  else if(status===PENDING_STAFF_COMPLETE) tempBgOfSelectedStatus = "wr-pending-staff-complete-status-icon";
  else if(status===PENDING_TIMESHEET_REVIEW) tempBgOfSelectedStatus = "wr-pending-client-complete-status-icon";
  else if(status===CLIENT_REVIEW_COMPLETED) tempBgOfSelectedStatus = "wr-pending-agency-complete-status-icon";
  else if(status===PENDING_CLIENT_APPROVAL) tempBgOfSelectedStatus = "wr-pending-client-approval-status-icon";
  else if(status===SHIFT_CONFIRMED_CLOCK_IN) tempBgOfSelectedStatus = "wr-pending-clock-in-status-icon";
  else if(status===NEW_SHIFT_ASSIGNED_ACCEPT_TO_CONFIRM) tempBgOfSelectedStatus = "wr-yet-to-start-status-icon";
  else if(status===TIMESHEET_APPROVED_FOR_PAYROLL) tempBgOfSelectedStatus = "wr-invoice-generated-status-icon";
  else if(status===PENDING_AGENCY_APPROVAL) tempBgOfSelectedStatus = "wr-pending-agency-approval-status-icon";
  else if(status===NEW_SHIFT_AVAILABLE) tempBgOfSelectedStatus = "wr-new-shift-status-icon";
  else if(status===SHOWN_INTERESTED ) tempBgOfSelectedStatus = "wr-shown-interest-status-icon";
  else if(status===CANCELLED_WORK) tempBgOfSelectedStatus = "wr-cancelled-status-icon";
  else if(status===REJECTED_BY_STAFF) tempBgOfSelectedStatus = "wr-rejected-by-staff-status-icon";
  else if(status===REJECTED_BY_CLIENT) tempBgOfSelectedStatus = "wr-rejected-by-client-status-icon";
  else if(status===REJECTED_BY_AGENCY) tempBgOfSelectedStatus = "wr-rejected-by-agency-status-icon";
  return tempBgOfSelectedStatus;
}

export const msToTime = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor(duration / (24 * 60 * 60 * 1000));

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return {hours, minutes, seconds, days};
};

// get work status
export const getTimeoffStatus = (timeoffStatus) => {
  let status = "";
  if (timeoffStatus === 0) {
    status = DRAFT;
  } else if (timeoffStatus === 1) {
    status = PENDING;
  } else if (timeoffStatus === 2) {
    status = REJECTED;
  } else if (timeoffStatus === 3) {
    status = APPROVED;
  }
  return status;
};

export const coordinates = () => {
  const LONGITUDE = 13.11212;
  const LATITUDE = 80.121212;
  return {LATITUDE, LONGITUDE};
};

export const filterByValue = (array, string, field) => {
  let temp = [...array];
  return temp.filter(
    (item) =>
      item.workStaffAssignmen.staffWorkData.filter(
        (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
      ).length > 0 &&
      item.workStaffAssignment.staffWorkData
        .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
        [field].toLowerCase()
        .indexOf(string.toLowerCase()) !== -1,
  );
};

export function staffWorkCommon(data, userId) {
  let result =
    data?.workStaffAssignment?.staffWorkData?.length > 0 &&
    data.workStaffAssignment.staffWorkData.filter((staff) => staff.staffId === userId).length > 0 &&
    data.workStaffAssignment.staffWorkData.filter((staff) => staff.staffId === userId)[0];
  return result;
}

// export function rejectWorkCommon(data,userId){
//   let result= data.workStaffAssignment && data.workStaffAssignment.staffWorkData && data.workStaffAssignment.staffWorkData.length === 0 && data.workStaffAssignment.staffWorkData.filter(staff=>staff.staffId===userId).length>0 && data.workStaffAssignment?.staffWorkData.filter(staff=>staff.staffId===userId)[0];
//   return result;
// }


export const checkUserLoginCount = async() => {
  let userMail = encodeURIComponent(JSON.parse(localStorage.getItem('userData')).email)
  if(userMail)
  await store.dispatch(getUserLoginCount({userMail}))
  .then((data)=>{})
  .catch(err =>{})
}




export function getStatusItems(item) {
  const userData = JSON.parse(localStorage.getItem("userData"));

  function hasStaffWorkData() {
    return (
      item?.workStaffAssignment?.staffWorkData?.length > 0
    );
  }

  function getStaffWorkData() {
    return item.workStaffAssignment.staffWorkData.filter(
      (staff) => staff.staffId === userData.id
    );
  }

  function isInterestedStaff() {
    return (
      item?.workStaffAssignment?.interestedStaff?.length > 0 &&
      item.workStaffAssignment.interestedStaff.includes(userData.id)
    );
  }

  function isRejectedStaff() {
    return (
      item?.workStaffAssignment?.rejectedStaff?.length > 0 &&
      item.workStaffAssignment.rejectedStaff.filter(
        (rejected) => rejected.staffId === userData.id
      ).length > 0
    );
  }

  function getRejectedStaffUserType() {
    let rejectedStaff = []
     rejectedStaff =
    item?.workStaffAssignment?.rejectedStaff?.length > 0 &&
     item.workStaffAssignment.rejectedStaff.filter((rejected) => rejected.staffId === userData.id);
    return rejectedStaff && rejectedStaff.length > 0 ? rejectedStaff[rejectedStaff.length-1].userType : undefined;
  }

  const workStatus = item.status;
  const staffWorkDataExists = hasStaffWorkData();
  const staffWorkStatus =
    staffWorkDataExists && getStaffWorkData().length > 0
      ? getStaffWorkData()[0].status
      : -1;
  const clockIn =
    staffWorkDataExists && getStaffWorkData().length > 0
      ? getStaffWorkData()[0].clockIn
      : undefined;
  const clockOut =
    staffWorkDataExists && getStaffWorkData().length > 0
      ? getStaffWorkData()[0].clockOut
      : undefined;
  const interestedStaff = isInterestedStaff();
  const holdWork =
    staffWorkDataExists && getStaffWorkData().length > 0
      ? getStaffWorkData()[0].holdWork
      : false;
  const rejectedStaff = isRejectedStaff();
  const rejectedStaffUserType = getRejectedStaffUserType();

  return {
    workStatus,
    staffWorkStatus,
    clockIn,
    clockOut,
    interestedStaff,
    holdWork,
    rejectedStaff,
    rejectedStaffUserType,
  };
}

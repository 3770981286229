import {timesheetApi} from '../service/apiVariables';
import {addQuery} from '../service/helperFunctions';

// get staff timesheet
export const getTimesheet = (query) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    addQuery(query, timesheetApi.getTimesheet);
    api({...timesheetApi.getTimesheet})
      .then(({data}) => {
        resolve(data);
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};

// add staff timesheet
export const getAllTimeSheet = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.getAllTimeSheet, body})
      .then(({message, data}) => {
        resolve(data);
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};
// add staff timesheet
export const addTimesheet = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.addTimesheet, body})
      .then(({message, data}) => {
        resolve(data);
        Toast({type: 'success', message, time: 5000});
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};
// add staff timesheet
export const editTimesheet = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.editTimesheet, body})
      .then(({message, data}) => {
        resolve(data);
        Toast({type: 'success', message, time: 5000});
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};
// add staff timesheet
export const editTimesheetBreak = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.editTimesheetBreak, body})
      .then(({message, data}) => {
        resolve(data);
        Toast({type: 'success', message, time: 5000});
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};

export const submitTimesheet = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.submitTimesheet, body})
      .then(({message, data}) => {
        resolve(data);
        Toast({type: 'success', message, time: 5000});
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};

export const approveTimesheet = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.approveTimesheet, body})
      .then(({message, data}) => {
        resolve(data);
        Toast({type: 'success', message, time: 5000});
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};

export const rejectTimesheet = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.rejectTimesheet, body})
      .then(({message, data}) => {
        resolve(data);
        Toast({type: 'success', message, time: 5000});
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};

// update staff timesheet
export const updateTimesheet = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...timesheetApi.updateTimesheet, body})
      .then(({message, data}) => {
        resolve(data);
        Toast({type: 'success', message, time: 5000});
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};

export const updateTaskData = (body) => (dispatch, getState, {api}) => {
  dispatch({type: 'UPDATE_TASK_DATA', payload: body});
};

export const updateTasksData = (body) => (dispatch, getState, {api}) => {
  dispatch({type: 'UPDATE_TASKS_DATA', payload: body});
};
export const addTasksData = (body) => (dispatch, getState, {api}) => {
  dispatch({type: 'ADD_TASKS_DATA', payload: body});
};

export const updateWorksData = (body) => (dispatch, getState, {api}) => {
  dispatch({type: 'UPDATE_WORKS_DATA', payload: body});
};
export const addWorksData = (body) => (dispatch, getState, {api}) => {
  dispatch({type: 'ADD_WORKS_DATA', payload: body});
};

export const getTaskData = () => (dispatch, getState, {api}) => {
  dispatch({type: 'GET_TASK_DATA'});
};
// get staff work by id
export const getWorkById = (query) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    addQuery(query, timesheetApi.getWorkRequestById);
    api(timesheetApi.getWorkRequestById)
      .then(({data}) => {
        resolve(data);
      })
      .catch(({message}) => {
        reject(Toast({type: 'error', message}));
      });
  });
};

export const getTimeoffById = (query) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    addQuery(query, timesheetApi.getTimeoffById);
    api(timesheetApi.getTimeoffById)
      .then(({data}) => {
        resolve(data);
      })
      .catch(({message}) => {
        // reject(Toast({type: 'error', message}));
        reject({type: 'error', message});
      });
  });
};

export const staffProfileSteps = [
    {
        target:'#Edit-profile',
        content:'Edit your Staff profile details..',
        placement:'auto', 
        disableBeacon: true,
        disableScrolling:false,
    },
    {
        target:'#controlled-tab-example-tab-home',
        content:'Check Your General details',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-preferences',
        content:'Check Your preferences',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-credentials',
        content:'Check for your credentials here..',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-timeoff',
        content:'Staff Timeoff details',
        placement:'auto',
        
    }
]

export const editStaffProfileSteps = [
    {
        target:'#controlled-tab-example-tab-home',
        content:'You can edit the General Details. Fields like Employee ID, Email, Hire Date, Job Function and Employement Type cannot be changed',
        placement:'auto',
        disableBeacon: true,
        disableScrolling:false,
    },
    {
        target:'#controlled-tab-example-tab-preferences',
        content:'Select Your Preferences, so your Agency can allocate the work based on the Preferences',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-credentials',
        content:'Edit your Documents or Upload your new Document ',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-timeoff',
        content:'You can Add New Timeoff or View Timeoff details',
        placement:'auto',
        
    }
]
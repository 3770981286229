export const staffProfiletimeOffSteps = [
    {
        target:'#cancelProfile',
        content:'Add details to avail time off',
        placement:'left-start',
        disableBeacon: true,
        disableScrolling:false,
    },
    {
        target:'#controlled-tab-example-tab-home',
        content:'Check Your General details',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-preferences',
        content:'Check Your preferences',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-credentials',
        content:'Check for your credentials here..',
        placement:'auto',
        
    },
    {
        target:'#controlled-tab-example-tab-timeoff',
        content:'Staff Timeoff details',
        placement:'auto',
        
    }
]
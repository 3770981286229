// New Code
import React, { useEffect, useState } from 'react';
import Navbar from 'component/common/navbar';
import Sidebar from 'component/common/Sidebar';
import NewSidebarMobile from 'component/common/newSidebarMobile';
import 'assets/scss/layouts/AdminLayout.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// import SidebarMobile from 'component/common/SidebarMobile';
import NavbarMobile from 'component/common/navbarMobile';
import NotificationSettings from 'pages/Admin/Notification'
import TourGuide from "component/common/TourGuide";
import connectToWebSocket from 'service/webSocket';

export const AdminLayout = ({ children }) => {
  const [, setProfileMenu] = useState(false);
  const [menuOpenClass, setMenuOpenClass] = useState(false);
  const [isSideBarClicked, setIsSideBarClicked] = useState(false);
  const [mobileRender, setMobileRender] = useState(window.innerWidth < 576);
  const [isClosed,setIsClosed] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setMobileRender(window.innerWidth < 576);
    };
    window.addEventListener('resize', handleResize, {passive: true});
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    
    const apiUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.src = apiUrl;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(()=>{
    connectToWebSocket()
    window.addEventListener('online', ()=>{
      connectToWebSocket()
       }, {passive: true})
  },[])
  const handleProfileMenu = (event, active) => {
    event.stopPropagation();
    setProfileMenu(active);
  };

  const handleSidenav = () => {
    setMenuOpenClass(prevState => !prevState);
  };

  const handleSideBar = () => {
    setIsSideBarClicked(prevState => !prevState); 
  }


  useEffect(()=>{
    !isSideBarClicked ? setTimeout(()=>setIsClosed(!isSideBarClicked),500) : setIsClosed(!isSideBarClicked)
  },[isSideBarClicked]) 


  return (
    <>
      <div id="main-content" onClick={(e) => handleProfileMenu(e, false)}>
      {!mobileRender &&
        <div className="d-none d-sm-block">
          <div className="content-wrapper">
            {/* Desktop */}
            <Sidebar menuOpen={menuOpenClass} handleSidenav={handleSidenav} />
            <Navbar handleNav={handleSidenav} handleSideBar={handleSideBar} sideBarClickStatus={isSideBarClicked}/>

            <div className="content bg-white">{children}</div>
          </div>
        </div>}
        {/* <div className="d-block d-sm-none">
          <div className="content-wrapper">
            <div className="d-none d-lg-none d-md-none d-xl-none d-sm-block">
              <SidebarMobile menuOpen={menuOpenClass} handleSidenav={handleSidenav} />
              <NavbarMobile handleNav={handleSidenav} />
            </div>
            <div className="content bg-light">{children}</div>
          </div>
        </div> */}
        {mobileRender &&
        <div className="d-block d-sm-none">
          <div className="content-wrapper">
            {/* <div className="d-block d-sm-none"> */}
              <NewSidebarMobile menuOpen={menuOpenClass} handleSidenav={handleSidenav} />
              <NavbarMobile handleNav={handleSidenav} handleSideBar={handleSideBar} sideBarClickStatus={isSideBarClicked}/>
            {/* </div> */}
            <div className="content bg-white">{children}</div>
          </div>
        </div> }
      </div>
            <div className={`sidebarNavigation open ${isSideBarClicked ? 'in' : 'out'} ${(isClosed) && 'hide'}`}>
               <NotificationSettings handleSideBar={handleSideBar} sideBarClickStatus={isSideBarClicked} />
            </div>
            <TourGuide/>
      </>
  );
};

// Old code
// import React, {Component} from 'react';
// import Navbar from 'component/common/navbar';
// import Sidebar from 'component/common/Sidebar';
// import NewSidebarMobile from 'component/common/newSidebarMobile';
// import 'assets/scss/layouts/AdminLayout.scss';
// import 'react-perfect-scrollbar/dist/css/styles.css';
// import 'react-datepicker/dist/react-datepicker.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
// import SidebarMobile from 'component/common/SidebarMobile';
// import NavbarMobile from 'component/common/navbarMobile';
// import NotificationSettings from 'pages/Admin/Notification'

// export class AdminLayout extends Component {
//   state = {
//     profilemenu: false,
//     menuOpenClass: false,
//     authToken: '',
//     isSideBarClicked: false
//   };

//   handleProfileMenu = (event, active) => {
//     event.stopPropagation();
//     this.setState({
//       profilemenu: active,
//     });
//   };

//   handleSidenav = () => {
//     let {menuOpenClass} = this.state;

//     this.setState(
//       {
//         menuOpenClass: !menuOpenClass,
//       },
//       () => {
//         console.log(this.state.menuOpenClass, 'MENUCLASS');
//       },
//     );
//   };
//   handleSideBar = () => {
//     let { isSideBarClicked } = this.state;

//     this.setState({
//        isSideBarClicked: !isSideBarClicked
//     });
//  };

//   render() {
//     let {children} = this.props;

//     let {menuOpenClass} = this.state;

//     return (
//       <>
//       <div id="main-content" onClick={(e) => this.handleProfileMenu(e, false)}>
//         <div className="d-none d-sm-block ">
//           <div className="content-wrapper">
//             {/* Desktop */}
//             <Sidebar menuOpen={menuOpenClass} handleSidenav={this.handleSidenav} />
//             <Navbar
//              handleNav={this.handleSidenav}
//              handleSideBar= {this.handleSideBar}
//              sideBarClickStatus={this.state.isSideBarClicked} />
             
//             <div className="content bg-light">{children}</div>
//           </div>
//         </div>
//         {/* <div className="d-block d-sm-none">
//           <div className="content-wrapper">
//             <div className="d-none d-lg-none d-md-none d-xl-none d-sm-block">
//               <SidebarMobile menuOpen={menuOpenClass} handleSidenav={this.handleSidenav} />
//               <NavbarMobile handleNav={this.handleSidenav} />
//             </div>
//             <div className="content bg-light">{children}</div>
//           </div>
//         </div> */}
//         <div className="d-block d-sm-none">
//           <div className="content-wrapper">
//             {/* <div className="d-block d-sm-none"> */}
//               <NewSidebarMobile menuOpen={menuOpenClass} handleSidenav={this.handleSidenav} />
//               <NavbarMobile handleNav={this.handleSidenav} />
//             {/* </div> */}
//             <div className="content bg-light">{children}</div>
//           </div>
//         </div>
//       </div>
//       <div className={`sidebarNavigation ${this.state.isSideBarClicked ? 'open' : ''}`}>
//                <NotificationSettings handleSideBar={this.handleSideBar} sideBarClickStatus={this.state.isSideBarClicked} />
//             </div>
//       </>
//     );
//   }
// }

import React from 'react';
import search from 'assets/svg/search.svg';
import './style.scss';
import {NormalDropdown} from '../NormalDropdown';

export const TableFilter = ({
  filterData,
  setFilter,
  fsWrap = true,
  placeholder,
  filterRegion,
  regionOption,
  setFilterRegion,
  filter,
  isSort = false,
  adminAudit = false,
  isRegion,
  isSortByTwo,
  isRegionTwo,
}) => {
  return (
    <div className=" align-items-start mt-4 d-flex justify-content-between">
      <div className="input-group col-sm-4 col-md-3 search-input-group mb-3">
        <input
          type="text"
          id="searchValue"
          name="searchValue"
          value={filter ? filter.search : ''}
          onChange={(e) => {
            setFilter({...filter, search: e.target.value});
          }}
          className="form-control border-right-0 searchInput"
          placeholder={placeholder ? placeholder : 'Search'}
          aria-label="Search"
          aria-describedby="basic-addon2"
        />
        <div className="input-group-append ">
          <span className="input-group-text bg-white border-left-0" id="basic-addon2">
            <img className="mailIcon" src={search} alt="Logo" />
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        {isSort ? (
          <div className="d-flex align-items-center">
            <p className="mb-0 mr-2">{isRegion ? 'Region' : 'Sort by'} </p>
            <NormalDropdown
              onClick={(e) => {
                setFilterRegion({...filterRegion, view: e.target.value});
              }}
              label={filterRegion.view}
              optionsList={regionOption}
              className="custom-dropdown border rounded"
            />
          </div>
        ) : (
          ''
        )}

        {adminAudit ? (
          <div className="d-flex align-items-center ml-3">
            <p className="mb-0 mr-2">
              <span>Sort by</span>
            </p>
            <NormalDropdown
              onClick={(e) => {
                setFilter({...filter, view: e.target.value, label: e.target.data.label});
              }}
              label={filter.label ? filter.label : 'All'}
              optionsList={filterData}
              className={`custom-dropdown border rounded ${fsWrap ? 'fs-9' : ''}`}
            />
          </div>
        ) : (
          <div className="d-flex align-items-center ml-3">
            <p className="mb-0 mr-2">{!isRegionTwo ? <span>{isSortByTwo ? 'Sort by ' : 'View'}</span> : 'Region'}</p>
            <NormalDropdown
              onClick={(e) => {
                if (['active', 'inactive', 'deactive', 'all', 'archived', 'de-activated'].includes(e.target.value)) {
                  setFilter({...filter, view: e.target.value});
                }
              }}
              label={filter ? filter.view : ''}
              optionsList={filterData}
              className="custom-dropdown border rounded"
            />
          </div>
        )}
      </div>
    </div>
  );
};

import { staffApi } from "../service/apiVariables";
import { addQuery } from "../service/helperFunctions";

export const getStaffById = (query) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, staffApi.getStaffId);
		api({ ...staffApi.getStaffId })
			.then(({ data }) => {
				resolve(data);
				dispatch({type:'USER_DATA', payload:data})
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};
export const getStaffTimeOff = (query) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, staffApi.getTimeOff);
		api({ ...staffApi.getTimeOff })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};
// update profile
export const updateProfile = (body) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		api({ ...staffApi.updateProfile, body })
			.then(({ message, data }) => {
				resolve(data);
				Toast({ type: "success", message, time: 5000 });
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};

// update credentials
export const updateCredentials = (body) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		api({ ...staffApi.updateCredentials, body })
			.then(({ message, data }) => {
				resolve(data);
				Toast({ type: "success", message, time: 5000 });
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};
// update preferences
export const updatePreferences = (body) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		api({ ...staffApi.updatePreferences, body })
			.then(({ message, data }) => {
				resolve(data);
				Toast({ type: "success", message, time: 5000 });
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};

// update permission
export const updatePermissions = (body) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		api({ ...staffApi.updatePermissions, body })
			.then(({ message, data }) => {
				resolve(data);
				Toast({ type: "success", message, time: 5000 });
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};

// add timeoff
export const addTimeOff = (body) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		api({ ...staffApi.addTimeOff, body })
			.then(({ message, data }) => {
				resolve(data);
				Toast({ type: "success", message, time: 5000 });
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};

// update timeoff
export const updateTimeOff = (body) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		api({ ...staffApi.updateTimeOff, body })
			.then(({ message, data }) => {
				resolve(data);
				Toast({ type: "success", message, time: 5000 });
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};

// Cancel timeoff
export const cancelTimeOff = (body) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		addQuery(body, staffApi.cancelTimeOff);
		api({ ...staffApi.cancelTimeOff, body })
			.then(({ message, data }) => {
				resolve(data);
				Toast({ type: "success", message, time: 5000 });
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};

// get available leaves
export const getAvailableLeaves = (query) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, staffApi.getAvailableLeaves);
		api({ ...staffApi.getAvailableLeaves })
			.then(({ message, data }) => {
				resolve(data);
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};
export const getAvailableHours = () => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		api({ ...staffApi.getAvailableHours })
			.then(({ message, data }) => {
				resolve(data);
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};
export const getAvailableCodes = (query) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, staffApi.getAvailableCodes);
		api({ ...staffApi.getAvailableCodes })
			.then(({ message, data }) => {
				resolve(data);
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};
// get job functions
export const getJobFunctions = (query) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, staffApi.getJobFunctions);
		api({ ...staffApi.getJobFunctions })
			.then(({ message, data }) => {
				resolve(data);
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};
// get  team names
export const getTeamNames = (query) => (dispatch, getState, { api, Toast }) => {
	return new Promise((resolve, reject) => {
		addQuery(query, staffApi.getTeamNames);
		api({ ...staffApi.getTeamNames })
			.then(({ message, data }) => {
				resolve(data);
			})
			.catch(({ message }) => {
				reject(Toast({ type: "error", message }));
			});
	});
};

// get all users 
export const getAllUser = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, staffApi.getAllUser);
        api({ ...staffApi.getAllUser })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

import React from "react";
import clockIconBlue from "assets/svg/clock-icon-blue.svg";
import moment from "moment";
//import { msToTime } from "service/utilities";
import flag from 'assets/svg/flag.svg';
import "../../../assets/scss/index.scss"

export default function TotalHours({ staffAvailable, work, data }) {
  return (
    <div className="row container-fluid mb-3 adjust">
      <div className="col-md-4 d-flex align-items-center work-hours">
        <img className="mdi-clock-outline-blue" src={clockIconBlue} height="14px" width="14px" alt="clockIcon"/>
        <span className="s2-r">Work Hours</span>
      </div>
      <div className="col-md-5 pl-0 d-flex align-items-center time workinghours">
        <span className="s1-m ml-0 ml-sm-3 ml-md-0">
          {staffAvailable && !!data.clockIn
            ? moment(
                staffAvailable &&
                  data.clockIn
              ).format("hh:mm:ss a")
            : "Pending"}{" "}
          -{" "}
          {staffAvailable && !!data.clockOut
            ? moment(
                staffAvailable &&
                  data.clockOut
              ).format("hh:mm:ss a")
            : "Pending"}
        </span>
      </div>
      <div className="col-md-3 row no-padding workinghours-2">
        <span style={{color: "#3199c8"}} className="s2-m col-md-11 no-padding">
          {/* TODO: Need to enable once the backend api is working fine. */}
          {staffAvailable &&
          data.staffWorkedHrs !== undefined
            ? data.staffWorkedHrs.toFixed(
                4
              )
            : "Pending"}{" "}
          hours
          <div className="flag-icon row col-md-3 no-padding">
            {(parseFloat(data.staffWorkedHrs).toFixed(4)) > ((moment(work.endDate) - moment(work.startDate)) / (60*1000*60)) ?
            <>  <span className='flagicon-msg mb-1 fs-13'> Staff has worked more than expected time </span>
              <img className="ml-2 cursor-pointer mt-1  " src={flag} alt="flag" />
              </>
              : ""}
          </div>
          {/* {staffAvailable &&
          work.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          )[0].staffWorkedHrs != undefined ? work.workStaffAssignment.staffWorkData
            .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
            .staffWorkedHrs.split(".")[0] >= 0 ? (
            work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[0] +
            (work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[0] > 1
              ? " hrs "
              : " hr")
          ) : "" +
            +work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[1] >=
          0 ? (
            work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[1] +
            (work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .staffWorkedHrs.split(".")[1] > 1
              ? " mins"
              : "min")
          ) : (
            ""
          ) : (
            ""
          )} */}
          {/* {msToTime(
            new Date(
              work.workStaffAssignment.staffWorkData.length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                ).length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].clockOut,
            ).getTime() -
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockIn,
              ).getTime(),
          ).hours >= 0 ? (
            msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).hours +
            (msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).hours > 1
              ? " hrs"
              : " hr")
          ) : (
            ""
          )}{" "}
          {msToTime(
            new Date(
              work.workStaffAssignment.staffWorkData.length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                ).length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].clockOut,
            ).getTime() -
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockIn,
              ).getTime(),
          ).minutes >= 0 ? (
            msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).minutes +
            (msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() -
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockIn,
                ).getTime(),
            ).minutes > 1
              ? " mins"
              : " min")
          ) : (
            ""
          )} */}
        </span>
      </div>
    </div>
  );
}

export const authType = {
  getUserLoginDetails: 'GET_USER_LOGIN_DETAILS',
};
export const USER_DATA = 'USER_DATA';
export const AGENCY_DATA = 'AGENCY_DATA';
export const GET_TASK_DATA = 'GET_TASK_DATA';
export const UPDATE_TASK_DATA = 'UPDATE_TASK_DATA';
export const UPDATE_TASKS_DATA = 'UPDATE_TASKS_DATA';
export const UPDATE_WORKS_DATA = 'UPDATE_WORKS_DATA';
export const ADD_TASKS_DATA = 'ADD_TASKS_DATA';
export const ADD_WORKS_DATA = 'ADD_WORKS_DATA';
export const START_TOUR_GUIDE = 'START_TOUR_GUIDE';
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT';
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS';
export const UPDATE_WORK_REQUEST = 'UPDATE_WORK_REQUEST';
const routers = [
  {
    path: "/",
    exact: true,
    redirect: "/admin",
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        componentPath: "pages/Auth/Login",
        path: "/login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgetPassword",
        componentPath: "pages/Auth/ForgetPassword",
        path: "/forgetPassword",
        name: "ForgetPassword",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    path: '/admin/WorkSchedule',
    exact: false,
    redirect: '/admin/WorkSchedule/list',
  },
  {
    component: "PlainLayout",
    path: "/plain",
    auth: false,
    name: "Plain",
    exact: false,
    redirect: "/plain/token/:token",
    childrens: [
      {
        component: "UserVerify",
        componentPath: "pages/Auth/UserVerify",
        path: "/token/:token/:id",
        name: "Token",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AdminLayout",
    path: "/admin",
    auth: true,
    name: "AdminLayout",
    redirect: "/admin/workSchedule/list",
    exact: false,
    childrens: [
      {
        component: "Report",
        path: "/report",
        componentPath: "pages/Admin/Report",
        name: "reportManagement",
        auth: false,
        exact: true,
      },
      {
        component: "StaffManagement",
        path: "/staffManagement",
        componentPath: "pages/Admin/StaffManagement",
        name: "StaffManagementPanel",
        auth: false,
        exact: true,
      },
      {
        component: "AddTeamForm",
        path: "/addTeam",
        componentPath: "pages/Admin/StaffManagement/AddTeam",
        name: "addTeamForm",
        auth: false,
        exact: true,
      },
      {
        component: "TeamDetails",
        path: "/teamDetails",
        componentPath: "pages/Admin/StaffManagement/TeamDetails",
        name: "teamDetail",
        auth: false,
        exact: true,
      },
      {
        component: "StaffProfile",
        path: "/staffProfile",
        componentPath: "pages/Admin/StaffManagement/StaffProfile",
        name: "staffProfile",
        auth: false,
        exact: true,
      },
      {
        component: "StaffProfile",
        path: "/staffProfile/:view",
        componentPath: "pages/Admin/StaffManagement/StaffProfile",
        name: "staffProfile",
        auth: false,
        exact: true,
      },
      {
        component: "EditStaffProfile",
        path: "/editProfile",
        componentPath: "pages/Admin/StaffManagement/EditProfile",
        name: "editStaffProfile",
        auth: false,
        exact: true,
      },
      {
        component: "AddStaffForm",
        path: "/addStaff",
        componentPath: "pages/Admin/StaffManagement/AddStaff",
        name: "addStaffForm",
        auth: false,
        exact: true,
      },
      {
        component: "Agencies",
        path: "/agencies",
        componentPath: "pages/Admin/Agencies",
        name: "agenciesPanel",
        auth: false,
        exact: true,
      },
      {
        component: "AddAgencyForm",
        path: "/addAgency",
        componentPath: "pages/Admin/Agencies/AddAgency",
        name: "addAgencyForm",
        auth: false,
        exact: true,
      },
      {
        component: "ViewAgency",
        path: "/viewAgency",
        componentPath: "pages/Admin/Agencies/ViewAgency",
        name: "viewAgencyForm",
        auth: false,
        exact: true,
      },
      {
        component: "EditAgencyForm",
        path: "/editAgency",
        componentPath: "pages/Admin/Agencies/EditAgency",
        name: "editAgencyForm",
        auth: false,
        exact: true,
      },
      {
        component: "Invoice",
        path: "/invoice",
        componentPath: "pages/Admin/Invoice",
        name: "invoiceManagement",
        auth: false,
        exact: true,
      },
      {
        component: "CreateInvoice",
        path: "/createInvoice",
        componentPath: "pages/Admin/Invoice/CreateInvoice",
        name: "createInvoice",
        auth: false,
        exact: true,
      },
      {
        component: "ViewInvoice",
        path: "/viewInvoice",
        componentPath: "pages/Admin/Invoice/ViewInvoice",
        name: "viewInvoice",
        auth: false,
        exact: true,
      },
      {
        component: "AddPlan",
        path: "/addPlan",
        componentPath: "pages/Admin/Subscription/AddPlan",
        name: "addPlanDetail",
        auth: false,
        exact: true,
      },
      {
        component: "PlanDetail",
        path: "/planDetail",
        componentPath: "pages/Admin/Subscription/PlanDetail",
        name: "planDetailPage",
        auth: false,
        exact: true,
      },
      {
        component: "AddDiscount",
        path: "/addDiscount",
        componentPath: "pages/Admin/Subscription/AddDiscount",
        name: "addDiscountForm",
        auth: false,
        exact: true,
      },
      {
        component: "Settings",
        path: "/settings",
        componentPath: "pages/Admin/Settings",
        name: "settings",
        auth: false,
        exact: true,
      },
      {
        component: "EditSettings",
        path: "/settings/editSettings",
        componentPath: "pages/Admin/Settings/EditSettings",
        name: "editSettings",
        auth: false,
        exact: true,
      },
      {
        component: "WorkSchedule",
        path: "/workSchedule/:view",
        componentPath: "pages/Admin/workSchedule",
        name: "WorkSchedulePanel",
        auth: false,
        exact: true,
      },
      {
        component: "WorkScheduleList",
        path: "/workSchedule/workScheduleList",
        componentPath: "pages/Admin/workSchedule/workScheduleList",
        name: "workScheduleListPanel",
        auth: false,
        exact: true,
      },
      {
        component: "StaffAssignList",
        path: "/staffAssignList",
        componentPath: "pages/Admin/workSchedule/staffAssignList",
        name: "staffAssignList",
        auth: false,
        exact: true,
      },
      {
        component: "AssignWorkSchedule",
        path: "/assignWorkSchedule",
        componentPath: "pages/Admin/workSchedule/assignWorkSchedule",
        name: "assignWorkSchedulePanel",
        auth: false,
        exact: true,
      },
      {
        component: "TimeSheet",
        path: "/timeSheet",
        componentPath: "pages/Admin/timeSheet",
        name: "timeSheetPanel",
        auth: false,
        exact: true,
      },
      {
        component: "TimeSheetList",
        path: "/timeSheetList",
        componentPath: "pages/Admin/timeSheet/timeSheetList",
        name: "timeSheetListPanel",
        auth: false,
        exact: true,
      },
      {
        component: "TimeOffManagement",
        path: "/timeOff",
        componentPath: "pages/Admin/StaffManagement/StaffProfile",
        // componentPath: 'pages/Admin/TimeOff',
        name: "timeOffManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AuditLogs",
        path: "/auditLogs",
        componentPath: "pages/Admin/AuditLogs",
        name: "auditLogs",
        auth: false,
        exact: true,
      },
      // {
      //   component: "Notification",
      //   path: "/notification",
      //   componentPath: "pages/Admin/Notification",
      //   name: "notification",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "ClientOnboarding",
        path: "/clientOnboarding",
        componentPath: "pages/Admin/ClientOnboarding",
        name: "clientOnboarding",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;

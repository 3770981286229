import React, {Component} from "react"
import {NavLink} from 'react-router-dom';
import calendarSolid from 'assets/svg/calendar solid.svg';
import calendarTransparent from 'assets/svg/calendar transparent.svg';
import flightSolid from 'assets/svg/flight solid.svg';
import flightTransparent from 'assets/svg/flight transparent.svg';
import statSolid from 'assets/svg/stat solid.svg';
import statTransparent from 'assets/svg/stat transparent.svg';
import settingsSolid from 'assets/svg/settings solid.svg';
import settingsTransparent from 'assets/svg/settings transparent.svg';
import {history} from '../../../service/helpers';
import "./newsidebar.scss"



export default class NewSidebarMobile extends Component {
    constructor(props) {
      super(props);
      this.activeTab = this.activeTab.bind(this);
      this.state = {
        navLinks: [
          {
            to: '/admin/workSchedule/list',
            label: 'Schedule',
            iconName: calendarSolid,
            iconTransparent: calendarTransparent,
            classNames:"WSSizePlus1",
            id:"WSSizePlus2"
          },
          {
            to: '/admin/staffProfile',
            label: 'Profile',
            iconName: flightSolid,
            iconTransparent: flightTransparent,
            classNames:"sideBarIcon1",
            id:"sideBarIcon11"
          },
          {
            to: '/admin/report',
            label: 'Reports',
            iconName: statSolid,
            iconTransparent: statTransparent,
            classNames:"sideBarIcon2",
            id:"sideBarIcon22"
          },
          {
            to: '/admin/settings',
            label: 'Settings',
            iconName: settingsSolid,
            iconTransparent: settingsTransparent,
            classNames:"sideBarIcon3",
            id:"sideBarIcon33"
          },
        ],
        prev: '',
        next: '',
        active: '',
      };
    }
  
    activeTab = (index) => {
      this.setState(
        {
          next: index + 1,
          prev: index - 1,
          active: index,
        },
        () => {
          // callback function logic goes here
        },
      );
    };

    UNSAFE_componentWillMount() {
        let {location} = history;
        let {navLinks} = this.state;
        navLinks.forEach((item, index) => {
          if (item.to === location.pathname) {
            this.setState({
              next: index + 1,
              prev: index - 1,
              active: index,
            });
          }
        });
      }
      

    render() {
        
        // let {navLinks, prev, next, active} = this.state;
        let {navLinks, active} = this.state;
        let {menuOpen, handleSidenav} = this.props;
        let {location} = history;
        const tab = (to,index) =>{
            if(to !== "/something"){
                this.activeTab(index)
            }
        }
        const closeMenu = () => {
            handleSidenav(false)
          }
    
        return (
            <>
               {/* <button>{menuOpen ? "false" : "true"}</button> */}
            <div className="left=menu">
               <ul className={`menuNav ${menuOpen ? " showMenu" : "hidemenu"}`}>
               {navLinks.map(({to, label, iconName, iconTransparent,classNames,id}, index) => (
                <li key={index}>
                    <NavLink
                       onClick={()=>{tab(to,index); closeMenu()}}
                       tabIndex={to === "/something" ? "-1" :""}
                       to={to}
                       className="nav-link"
                       exact
                    >
                    <div className="sidebar-menu">
                     {(active === index && location.pathname.indexOf(to)>-1) || 
                        (active!==undefined && location.pathname.indexOf(to)>-1) ? (
                          <span className={classNames}>{label}</span>
                        ) : (
                          <span id={id}>{label}</span>
                        )}
                     
                    </div>
                    </NavLink>
                
                </li>
               ))}
               </ul>
            </div>
            </>
        )
    }
}
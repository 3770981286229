import React from "react";
import clockIconRed from "assets/svg/clock-icon-red.svg";
//import moment from "moment";
//import { msToTime } from "service/utilities";

export default function OverHours({ work }) {

  return (
    <React.Fragment>
      <div className="row container-fluid mb-3">
        <div className="col-md-6 d-flex overtime-cont">
          <img className="mdi-clock-outline-red" style={{width: "14px"}} src={clockIconRed} alt="clockIcon" />
          <span className="s2-r">Over Time Work Hours</span>
        </div>
        <div className="col-md-3 overtime-cont-one">
          <span className="fs-13">
            -
            {/* {moment(work.endDate).format("hh:mm a")} -
            {moment(
              work.workStaffAssignment.staffWorkData.length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                ).length > 0 &&
                work.workStaffAssignment.staffWorkData.filter(
                  (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                )[0].clockOut,
            ).format("hh:mm a")} */}
          </span>
        </div>
        <div className="col-md-3 overtime-cont-two">
          <span style={{color: "#ff0000"}} className="s2-m">
            {work.workStaffAssignment.staffWorkData[0].overTimeHours !==
            undefined
              ? work.workStaffAssignment.staffWorkData[0].overTimeHours.toFixed(
                  4
                )
              : "0.0000"}{" "}
            hours
          </span>
        </div>
      </div>
      {/* <div className="col-md-4">
        <span className="fs-16">
          {work.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          )[0].overtimeHrs != undefined ? (
            work.workStaffAssignment.staffWorkData.filter(
              (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
            )[0].overtimeHrs
          ) : (
            "0"
          )}{" "}
          hours */}
      {/* TODO: Need to enable once backend api fixed. */}
      {/* {staffAvailable &&
          work.workStaffAssignment.staffWorkData.filter(
            (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
          )[0].overtimeHrs != undefined ? work.workStaffAssignment.staffWorkData
            .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
            .overtimeHrs.split(".")[0] >= 0 ? (
            work.workStaffAssignment.staffWorkData
          
            .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .overtimeHrs.split(".")[0] +
            (work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .overtimeHrs.split(".")[0] > 1
              ? " hrs "
              : " hr")
          ) : "" +
            +work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .overtimeHrs.split(".")[1] >=
          0 ? (
            work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .overtimeHrs.split(".")[1] +
            (work.workStaffAssignment.staffWorkData
              .filter((staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id)[0]
              .overtimeHrs.split(".")[1] > 1
              ? " mins"
              : "min")
          ) : (
            ""
          ) : (
            ""
          )} */}
      {/* {msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() - new Date(work.endDate).getTime(),
            ).hours >= 0 ? (
              msToTime(
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockOut,
                ).getTime() - new Date(work.endDate).getTime(),
              ).hours +
              (msToTime(
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockOut,
                ).getTime() - new Date(work.endDate).getTime(),
              ).hours > 1
                ? " hrs"
                : " hr")
            ) : (
              ""
            )}{" "}
            {msToTime(
              new Date(
                work.workStaffAssignment.staffWorkData.length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  ).length > 0 &&
                  work.workStaffAssignment.staffWorkData.filter(
                    (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                  )[0].clockOut,
              ).getTime() - new Date(work.endDate).getTime(),
            ).minutes >= 0 ? (
              msToTime(
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockOut,
                ).getTime() - new Date(work.endDate).getTime(),
              ).minutes +
              (msToTime(
                new Date(
                  work.workStaffAssignment.staffWorkData.length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    ).length > 0 &&
                    work.workStaffAssignment.staffWorkData.filter(
                      (staff) => staff.staffId === JSON.parse(localStorage.getItem("userData")).id,
                    )[0].clockOut,
                ).getTime() - new Date(work.endDate).getTime(),
              ).minutes > 1
                ? " mins"
                : " min")
            ) : (
              ""
            )} */}
      {/* </span>
      </div> */}
    </React.Fragment>
  );
}

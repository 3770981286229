export const settingsPageSteps = [
    {
        target:'#general-details',
        content:'Staff general details here..',
        placement:'bottom',
        disableBeacon: true,
    },
    {
        target:'#change-password',
        content:'Click to change your password',
        placement:'auto',
    },

]


import {  fileDownloadFromMDB } from '../service/apiVariables';
import {  addQuery } from '../service/helperFunctions';

export const getFileFromMDB = (query,path) => (dispatch, getState, { api, Toast }) => {
    
    fileDownloadFromMDB.getFile.url = path ? `v1/storage/mdb/file/${path}` : 'v1/storage/mdb/file'
    return new Promise((resolve, reject) => {
      addQuery(query, fileDownloadFromMDB.getFile);
      api({ ...fileDownloadFromMDB.getFile })
        .then(({data}) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
};

export const deleteFileFromMDB = (query) => (dispatch, getState, { api, Toast }) => {
    
    return new Promise((resolve, reject) => {
      addQuery(query, fileDownloadFromMDB.deleteFile);
      api({ ...fileDownloadFromMDB.deleteFile })
        .then(({data, message}) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
};
import {workRequest} from "../service/apiVariables";
import {addQuery} from "../service/helperFunctions";

export const getAllClient = () => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.getClient})
      .then(({data, message}) => {
        resolve(data);
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};

export const getAllWorkByClient = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.getAllWork, body})
      .then(({data,page, message}) => {
        resolve({data, page});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const getAllWorkLocations = () => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.getAllWorkLocations})
      .then(({data, message}) => {
        resolve(data);
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const getWorkById = (query) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    addQuery(query, workRequest.getWorkById);
    api({...workRequest.getWorkById})
      .then(({data, message}) => {
        resolve(data);
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const acceptWorkByStaff = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.acceptWorkStaff, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const staffClockIn = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.staffClockIn, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const rejectWorkByStaff = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.rejectWorkStaff, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const workInterestedStaff = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.interestedWorkStaff, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const holdWorkStaff = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    addQuery(body, workRequest.holdWorkStaff);
    api({...workRequest.holdWorkStaff, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const resumeWorkByStaff = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.resumeWorkStaff, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const pauseWorkByStaff = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.pauseWorkByStaff, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const staffClockOut = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.staffClockOut, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
export const completeWorkByStaff = (body) => (dispatch, getState, {api, Toast}) => {
  return new Promise((resolve, reject) => {
    api({...workRequest.completeWorkByStaff, body})
      .then(({data, message}) => {
        resolve(data);
        Toast({type: "success", message});
      })
      .catch(({message}) => {
        reject(Toast({type: "error", message}));
      });
  });
};
